import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Form,
  Button,
  Col,
  OverlayTrigger,
  Overlay,
  Popover,
} from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import moment from "moment";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import darkNoteIcon from "../../../../Assets/Images/CRM/darkNotes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import SingleDayGroupModal from "../../SingleDayGroupModal";
import { useParams } from "react-router-dom";
import editIcon from "../../../../Assets/Images/Action Icons/editaction.svg";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toastMessage";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import EventViewModal from "../../../CommonComponent/EventViewModal";
import ToolsSummaryModal from "../../ToolsSummaryModal";
import ReservationActivityModal from "./ReservationActivityModal";

const StrategyByReservation = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const userDetail = commonService.getLoginUserData();
  const dummyData = [
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      OOO: 0,
      RoomAvailable: 0,
      LeftToSell: 0,
      OnTheBook: 0,
      TotalOCCPercentage: 0,
      ADR: 0,
      REV: 0,
      RevPAR: 0,
      OTB: 0,
      Block: 0,
      RMS: 0,
      ADR: 0,
    },
  ];
  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0,
    groupOTB = 0,
    groupBlock = 0,
    pickupRooms = 0,
    pickupAdr = 0,
    dayName = false,
    barBasedOTB = 0,
    baeBasedRollingAvg = 0,
    pickupAdrCount = 0,
    sevenPickupRooms = 0,
    sevenPickupAdr = 0,
    sevenPickupAdrCount = 0;
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectAsOfDate, setSelectAsOfDate] = useState();
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [selectSevenPickupDate, setSelectSevenPickupDate] = useState();
  const [pickupFromData, setPickupFromData] = useState(null);
  const [sevenPickupFromData, setSevenPickupFromData] = useState(null);
  const [rateShopListData, setRateShopListData] = useState(null);
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const [showSingleDate, setShowSingleDate] = useState(false);
  const maxDateRef = useRef();
  const sevenMaxDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const selectedTdIndexRef = useRef();
  const dateForNoteRef = useRef();
  const userNoteRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [noteUpdate, setNoteUpdate] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    let sevenDate = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
    sevenMaxDateRef.current = sevenDate.setDate(sevenDate.getDate() - 7);
  }
  const startDateArrayRef = useRef([]);
  const [forecastData, setForecastData] = useState(null);
  const [forecastLoading, setForecastLoading] = useState(null);
  const [selectedForecast, setSelectedForecast] = useState("EMA");
  const [barBasedData, setBarBasedData] = useState(null);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );

  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const [addNoteModal, setAddNoteModal] = useState(false);
  const closeNoteModal = () => {
    setAddNoteModal(false);
  };
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const clickedDateForNote = useRef();
  const [noteDetail, setNoteDetail] = useState(null);
  const selectedEventDateRef = useRef();
  const [showToolsSummaryModal, setToolsSummaryModal] = useState(false);
  const [ClickedDate, setClickedDate] = useState(new Date());
  const [reservationModal, setReservationModal] = useState(false);
  const reservationModalPayload = useRef();

  const closeReservationModal = () => {
    setReservationModal(false);
  };

  const closeToolsSummaryModal = () => {
    setToolsSummaryModal(false);
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // let datesArr = commonService.getDateBetweenTwoDates(event[0], event[1]);
      // startDateArrayRef.current = datesArr;
      // callBothApi();
    }
  };

  const htmlToText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const plainText = tempElement.innerText;
    return plainText;
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setLoading(true);

    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.OTB_RESERVATION,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOrgData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setOrgData(dummyData);
      setLoading(false);
    }
  };

  const getPickupFromData = async () => {
    setLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    singleDatePickupDateRef.current = pickupDate;
    let pickupFromDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.STRATEGY_BY_RESERVATION_PICKUP,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          PickUpDate: selectPickupDate
            ? moment(selectPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
          propertyCode: para?.propertyCode,
          pickupType: "oneDay",
        },
      },
      false
    );

    let response = apiResponse(false, pickupFromDataResponse);

    if (response?.isValidate) {
      let pickupFromData = response?.data?.data?.map((item, index) => {
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });

      setPickupFromData(pickupFromData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setPickupFromData(null);
    }
  };

  const getSevenPickupFromData = async () => {
    setLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 7);
    let pickupFromDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.STRATEGY_BY_RESERVATION_PICKUP,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          PickUpDate: selectSevenPickupDate
            ? moment(selectSevenPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
          propertyCode: para?.propertyCode,
          pickupType: "sevenDay",
        },
      },
      false
    );

    let response = apiResponse(false, pickupFromDataResponse);

    if (response?.isValidate) {
      let pickupFromData = response?.data?.data?.map((item, index) => {
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });

      setSevenPickupFromData(pickupFromData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setSevenPickupFromData(null);
    }
  };

  const rateShopListAsOfDate = async () => {
    setLoading(true);

    let byPropertyIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.REVENUE_RATE_SHOP_CONFIG.GET_BY_PROPERTY_ID,
        body: {
          id: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let propertyResponse = apiResponse(false, byPropertyIdResponse);

    let rateShopListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.RATESHOP_LIST,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          propertyCode: para?.propertyCode,
          Channel: propertyResponse?.data?.data?.channel_term
            ? propertyResponse?.data?.data?.channel_term
            : "",
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, rateShopListResponse);
    if (response?.isValidate) {
      let rateShopData = response?.data?.data?.map((item, index) => {
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });
      setRateShopListData(rateShopData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setRateShopListData(null);
      setLoading(false);
    }
  };

  const getForecastDetail = async () => {
    setForecastLoading(true);
    let forecastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.PRICING_FORECAST,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          ForcastType: selectedForecast,
        },
      },
      false
    );

    let response = apiResponse(false, forecastResponse);

    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setForecastLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData([]);
      setForecastLoading(false);
    }
  };

  const getBarBasedDetail = async () => {
    let barBasedResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.BAR_BASED_STATS,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, barBasedResponse);

    if (response?.isValidate) {
      setBarBasedData(response?.data?.data);
    }
    if (!response?.isValidate) {
      setBarBasedData(null);
    }
  };

  const getNoteByDataList = async () => {
    let noteByDataListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET_NOTE.GET_BY_DATA_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          // widgetid: para?.widgetId,
          widgetid: 0,
          startdate: startRangeDateRef.current
            ? commonService.getDateInDBFormat(startRangeDateRef.current)
            : commonService.getDateInDBFormat(startDate),
          enddate: endRangeDateRef.current
            ? commonService.getDateInDBFormat(endRangeDateRef.current)
            : commonService.getDateInDBFormat(endDate),
        },
      },
      false
    );

    let response = apiResponse(false, noteByDataListResponse);

    if (response?.isValidate) {
      setNoteDetail(response?.data?.data);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
    }
  };

  const callBothApi = () => {
    getORGDetail();
    getPickupFromData();
    getSevenPickupFromData();
    rateShopListAsOfDate();
    getForecastDetail();
    getBarBasedDetail();
    getNoteByDataList();
  };

  useEffect(() => {
    if (api === 0) {
      let datesArr = commonService.getDateBetweenTwoDates(
        new Date(startDate),
        new Date(endDate)
      );
      startDateArrayRef.current = datesArr;
      setOrgData(dummyData);
    } else {
      if (para?.asOfDate) {
        let datesArr = commonService.getDateBetweenTwoDates(
          commonService.convertDateInTimezone(
            startRangeDateRef.current ? startRangeDateRef.current : startDate
          ),
          commonService.convertDateInTimezone(
            endRangeDateRef.current ? endRangeDateRef.current : endDate
          )
        );
        startDateArrayRef.current = datesArr;
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        callBothApi();
      }
    }
    if (!selectAsOfDate) {
      setSelectAsOfDate(para?.asOfDate);
    }
  }, [para?.asOfDate, selectAsOfDate, selectedForecast]);

  useEffect(() => {
    setSelectAsOfDate(asOfDateFromRTK);
  }, [asOfDateFromRTK]);

  useEffect(() => {
    if (selectPickupDate) {
      getPickupFromData();
    }
  }, [selectPickupDate]);
  useEffect(() => {
    if (selectSevenPickupDate) {
      getSevenPickupFromData();
    }
  }, [selectSevenPickupDate]);
  const [tblids, settblids] = useState({
    OrgStrategyTable: commonService.GenerateGUID(),
  });
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-otb`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-adr`
  );
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-room`
  );
  commonService.setBackColorPerByTDValue(
    "#FFC140",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-GroupOTB`
  );

  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const orgStrategyTableRef = useRef(null);
  const [groupModal, setGroupModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  useEffect(() => {
    if (orgStrategyTableRef.current) {
      orgStrategyTableRef.current.addEventListener(
        "click",
        handleOrgStrategyTableClick
      );
    }

    return () => {
      if (orgStrategyTableRef.current) {
        orgStrategyTableRef.current.removeEventListener(
          "click",
          handleOrgStrategyTableClick
        );
      }
    };
  }, []);

  const handleOrgStrategyTableClick = (event) => {};

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const handleGroupBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setGroupModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeGroupDate = () => {
    selectedTdIndexRef.current = -1;
    setGroupModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const handleExport = async (type) => {
    let tableId = document.querySelector(".dailySummaryTbl");

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    let groupingJson = {
      data: [],
      filename: `${para?.propertyCode}-DailySummary-${moment(new Date()).format(
        "MMDDYYYY"
      )}`,
    };
    let groupingTable = [
      {
        widgetname: "ORG",
        rowdata: [],
      },
    ];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [],
      },
    ];

    groupingTable[0].rowdata.push({
      data: [para?.rowTitle ? para?.rowTitle : ""],
    });
    groupingTable[0].rowdata.push({
      data: [],
    });

    const getTable = document.querySelector(".dailySummaryTbl");

    const findTblHeader = getTable?.querySelectorAll("thead tr");
    const findTblTbody = getTable?.querySelectorAll("tbody tr");

    findTblHeader.forEach((tblHeaderItem, tblHeaderIndex) => {
      let allTh = tblHeaderItem.querySelectorAll("th");
      let allThValue = [];
      allTh.forEach((thItem) => {
        let thSpan = thItem?.querySelector("div>span");
        let thText = thItem?.innerText;
        allThValue.push(
          thSpan
            ? thSpan.innerText === "EMA"
              ? "SYSTEM"
              : thSpan.innerText
            : thText
        );
      });

      groupingTable[0].rowdata.push({ data: allThValue });
      if (tblHeaderIndex === 0) {
        groupingTable[0].rowdata.push({ data: [] });
      }
    });

    findTblTbody.forEach((tblBodyItem, tblBodyIndex) => {
      let allTd = tblBodyItem.querySelectorAll("td");
      let allTdValue = [],
        tdTotalValue = [];

      allTd.forEach((tdItem, tdIndex) => {
        let tdText = tdItem?.innerText;
        if (tdIndex === 5 || tdIndex === 16 || tdIndex === 17) {
          if (findTblTbody?.length - 1 !== tblBodyIndex) {
            tdText = tdText?.replace("$", "");
          }
        }
        if (allTd[0].innerText === "Total") {
          tdTotalValue.push(tdText ? tdText : "");
        } else {
          allTdValue.push(tdText ? tdText : "");
        }
      });
      if (allTdValue?.length > 0) {
        groupingTable[0].rowdata.push({ data: allTdValue });
      }
      if (tdTotalValue?.length > 0) {
        totalJson[0].rowdata.push({ data: tdTotalValue });
      }
    });
    if (groupingTable?.[0].rowdata?.length > 0) {
      groupingJson.data = [...groupingJson.data, ...groupingTable];
    }
    if (totalJson?.[0].rowdata?.length > 0) {
      groupingJson.data = [...groupingJson.data, ...totalJson];
    }
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  FixColumn(tblids.OrgStrategyTable, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.org,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                if (
                  window.location.href.includes("RevPAK%20Summary") ||
                  window.location.href.includes("Daily%20Summary%20(RevPAK)")
                ) {
                  handleExport("EMAIL");
                } else {
                  commonService.fnSendWidgetMail(
                    tblids.OrgStrategyTable,
                    "Strategy",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="download"
              import={tblids.OrgStrategyTable}
              onClick={() => {
                if (
                  window.location.href.includes("RevPAK%20Summary") ||
                  window.location.href.includes("Daily%20Summary%20(RevPAK)")
                ) {
                  handleExport("DOWNLOAD");
                } else {
                  commonService.fnExportInCSV(
                    tblids.OrgStrategyTable,
                    `${para?.propertyCode}-StrategyByReservation-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  );
                }
              }}
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable mainTableClass">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table
              id={tblids.OrgStrategyTable}
              className="table-with-spacing positionStaticTable dailySummaryTbl"
              ref={orgStrategyTableRef}
            >
              <thead className="tbl-header-sticky">
                <tr className="diff-bg-tr table-main-header-tr">
                  <th className="width150">
                    <div className="d-flex flex-column align-items-start">
                      <span>AsOfDate</span>
                      <DatePicker
                        className=" "
                        id="asOfDate"
                        maxDate={new Date(para?.defaultAsOfDate)}
                        selected={
                          selectAsOfDate
                            ? commonService.convertDateInTimezone(
                                selectAsOfDate
                              )
                            : ""
                        }
                        onChange={(date) => {
                          let month =
                            date.getMonth() + 1 > 9
                              ? date.getMonth() + 1
                              : `0${date.getMonth() + 1}`;
                          let dateFormat =
                            date?.getDate() > 9
                              ? date.getDate()
                              : `0${date.getDate()}`;
                          let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                          setSelectAsOfDate(selectedDate);
                        }}
                      />
                      <span style={{ display: "none" }}>
                        {selectAsOfDate
                          ? commonService.getDateInFormat(
                              new Date(selectAsOfDate)
                            )
                          : ""}
                      </span>
                    </div>
                  </th>
                  <th className="border-right text-center" colSpan={5}>
                    TRANSIENT CURRENT YEAR
                  </th>
                  <th className="border-right text-center" colSpan={2}>
                    Revenue
                  </th>
                  <th className="border-right text-center">Group</th>
                  <th className="border-right text-center" colSpan={2}>
                    Bar Based Stats
                  </th>
                  <th className="border-right width100 text-center" colSpan={2}>
                    <div className="">
                      <span>1 Day Pickup</span>
                      <DatePicker
                        className=""
                        id="pickupDate"
                        maxDate={new Date(para?.asOfDate)}
                        selected={
                          selectPickupDate
                            ? commonService.convertDateInTimezone(
                                selectPickupDate
                              )
                            : commonService.convertDateInTimezone(
                                maxDateRef.current
                              )
                        }
                        onChange={(date) => {
                          let month =
                            date.getMonth() + 1 > 9
                              ? date.getMonth() + 1
                              : `0${date.getMonth() + 1}`;
                          let dateFormat =
                            date?.getDate() > 9
                              ? date.getDate()
                              : `0${date.getDate()}`;
                          let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                          singleDatePickupDateRef.current = selectedDate;
                          setSelectPickupDate(selectedDate);
                        }}
                      />
                      <span style={{ display: "none" }}>
                        {selectPickupDate
                          ? commonService.getDateInFormat(
                              new Date(selectPickupDate)
                            )
                          : commonService.getDateInFormat(
                              new Date(maxDateRef.current)
                            )}
                      </span>
                    </div>
                  </th>
                  <th className="border-right width100 text-center" colSpan={2}>
                    <div className="">
                      <span>7 Day Pickup</span>
                      <DatePicker
                        className=""
                        id="pickupDate"
                        maxDate={new Date(para?.asOfDate)}
                        selected={
                          selectSevenPickupDate
                            ? commonService.convertDateInTimezone(
                                selectSevenPickupDate
                              )
                            : commonService.convertDateInTimezone(
                                sevenMaxDateRef.current
                              )
                        }
                        onChange={(date) => {
                          let month =
                            date.getMonth() + 1 > 9
                              ? date.getMonth() + 1
                              : `0${date.getMonth() + 1}`;
                          let dateFormat =
                            date?.getDate() > 9
                              ? date.getDate()
                              : `0${date.getDate()}`;
                          let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                          setSelectSevenPickupDate(selectedDate);
                        }}
                      />
                      <span style={{ display: "none" }}>
                        {selectSevenPickupDate
                          ? commonService.getDateInFormat(
                              new Date(selectSevenPickupDate)
                            )
                          : commonService.getDateInFormat(
                              new Date(sevenMaxDateRef.current)
                            )}
                      </span>
                    </div>
                  </th>
                  <th className="text-center border-right" colSpan={3}>
                    Pricing & Forecast
                  </th>
                  {rateShopListData && (
                    <th
                      colSpan={
                        rateShopListData?.filter(
                          (x) =>
                            x.CheckInDate ===
                            moment(rateShopListData?.[0]?.CheckInDate).format(
                              "YYYY-MM-DD"
                            )
                        )?.length + 1
                      }
                      className="border-right text-center"
                    >
                      RateShop
                    </th>
                  )}
                  <th className="border-right"></th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width175"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  let datesArr =
                                    commonService.getDateBetweenTwoDates(
                                      commonService.convertDateInTimezone(
                                        startRangeDateRef.current
                                      ),
                                      commonService.convertDateInTimezone(
                                        endRangeDateRef.current
                                      )
                                    );
                                  startDateArrayRef.current = datesArr;
                                  callBothApi();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate && (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    )}
                  </th>
                  <th className="width100 border-right">OOO\Rooms Available</th>
                  <th className="width75 text-center ">Left To sell</th>
                  <th className="width75 text-center">On The Books</th>
                  <th>Total OCC%</th>
                  <th className="text-end">ADR</th>
                  <th className="text-center border-right">Rev</th>
                  <th className="text-end">RevPAR</th>
                  <th className=" border-right">OTB\Block</th>
                  <th className="width50 text-center border-right">OTB</th>
                  <th className="width100 text-center">8 Week Rolling Avg</th>
                  <th className="border-right text-center">Rooms</th>
                  <th className="text-end">ADR P/U</th>
                  <th className="border-right text-center">Rooms</th>
                  <th className="text-end">ADR P/U</th>
                  <th className="text-center border-right width110">
                    <div>
                      <Form.Select
                        value={selectedForecast}
                        onChange={(e) => {
                          setSelectedForecast(e.target.value);
                        }}
                      >
                        <option value="EMA">System Forecast</option>
                        <option value="RMS">RMS Forecast</option>
                        <option value="User">USER Forecast</option>
                        <option value="NOVA">Nova Forecast</option>
                      </Form.Select>
                      <span style={{ display: "none" }}>
                        {selectedForecast}
                      </span>
                    </div>
                  </th>
                  <th>R28 Avg</th>
                  <th className="text-end">Optimal Bar</th>

                  {rateShopListData &&
                    rateShopListData?.map((x) => {
                      if (
                        x.CheckInDate ===
                          moment(rateShopListData?.[0]?.CheckInDate).format(
                            "YYYY-MM-DD"
                          ) &&
                        x.IsSelf === true
                      ) {
                        return (
                          <th
                            className="text-end border-right"
                            title={x?.competiterpropertyname}
                          >
                            {x?.competiterpropertyname?.length > 10
                              ? `${x?.competiterpropertyname?.slice(0, 10)}...`
                              : x?.competiterpropertyname}
                          </th>
                        );
                      }
                    })}
                  {rateShopListData && (
                    <th className="text-end">Avg Competitor</th>
                  )}
                  {rateShopListData &&
                    rateShopListData?.map((x) => {
                      if (
                        x.CheckInDate ===
                          moment(rateShopListData?.[0]?.CheckInDate).format(
                            "YYYY-MM-DD"
                          ) &&
                        x.IsSelf === false
                      ) {
                        return (
                          <th
                            className="text-end"
                            title={x?.competiterpropertyname}
                          >
                            {x?.competiterpropertyname?.length > 10
                              ? `${x?.competiterpropertyname?.slice(0, 10)}...`
                              : x?.competiterpropertyname}
                          </th>
                        );
                      }
                    })}
                  <th className="border-right text-center">Notes</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  startDateArrayRef.current?.map((item, index) => {
                    dayName = false;
                    if (
                      moment(new Date(item), "l").format("ddd") === "Fri" ||
                      moment(new Date(item), "l").format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let singlePickupData = pickupFromData?.filter(
                      (x) =>
                        moment(x.StayDate).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    let sevenPickupData = sevenPickupFromData?.filter(
                      (x) =>
                        moment(x.StayDate).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    let singleOTBData = orgData?.filter(
                      (x) =>
                        moment(x.Date).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    ooo =
                      ooo +
                      (singleOTBData?.[0]?.OOO ? singleOTBData?.[0]?.OOO : 0);
                    RmsAvailable =
                      RmsAvailable +
                      (singleOTBData?.[0]?.RoomAvailable
                        ? singleOTBData?.[0]?.RoomAvailable
                        : 0);
                    leftToSell =
                      leftToSell +
                      (singleOTBData?.[0]?.LeftToSell
                        ? singleOTBData?.[0]?.LeftToSell
                        : 0);
                    otb =
                      otb +
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0);
                    occPercentage =
                      occPercentage +
                      (singleOTBData?.[0]?.TotalOCCPercentage
                        ? singleOTBData?.[0]?.TotalOCCPercentage
                        : 0);
                    adr =
                      adr +
                      (singleOTBData?.[0]?.ADR ? singleOTBData?.[0]?.ADR : 0);
                    rev =
                      rev +
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0);
                    revPar =
                      revPar +
                      (singleOTBData?.[0]?.RevPAR
                        ? singleOTBData?.[0]?.RevPAR
                        : 0);
                    groupOTB =
                      groupOTB +
                      (singleOTBData?.[0]?.OTB ? singleOTBData?.[0]?.OTB : 0);
                    groupBlock =
                      groupBlock +
                      (singleOTBData?.[0]?.Block
                        ? singleOTBData?.[0]?.Block
                        : 0);
                    pickupRooms =
                      pickupRooms +
                      (singlePickupData?.[0]?.RMS
                        ? parseInt(singlePickupData?.[0]?.RMS)
                        : 0);
                    pickupAdr =
                      pickupAdr +
                      (singlePickupData?.[0]?.ADR
                        ? parseInt(singlePickupData?.[0]?.ADR)
                        : 0);
                    if (singlePickupData?.[0]?.ADR) {
                      let parsedData = parseInt(singlePickupData?.[0]?.ADR);
                      if (parsedData !== 0) {
                        pickupAdrCount = pickupAdrCount + 1;
                      }
                    }

                    sevenPickupRooms =
                      sevenPickupRooms +
                      (sevenPickupData?.[0]?.RMS
                        ? parseInt(sevenPickupData?.[0]?.RMS)
                        : 0);
                    sevenPickupAdr =
                      sevenPickupAdr +
                      (sevenPickupData?.[0]?.ADR
                        ? parseInt(sevenPickupData?.[0]?.ADR)
                        : 0);
                    if (sevenPickupData?.[0]?.ADR) {
                      let sevenParsedData = parseInt(sevenPickupData?.[0]?.ADR);
                      if (sevenParsedData !== 0) {
                        sevenPickupAdrCount = sevenPickupAdrCount + 1;
                      }
                    }

                    let singleForecastData = forecastData?.filter(
                      (x) =>
                        moment(x.Dates).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    let singleBarBasedData = barBasedData?.filter(
                      (x) =>
                        moment(x.StayDate).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    barBasedOTB =
                      barBasedOTB +
                      (singleBarBasedData?.[0]?.OTB
                        ? parseInt(singleBarBasedData?.[0]?.OTB)
                        : 0);

                    baeBasedRollingAvg =
                      baeBasedRollingAvg +
                      (singleBarBasedData?.[0]?.["8 Week Rolling AVG"]
                        ? parseInt(
                            singleBarBasedData?.[0]?.["8 Week Rolling AVG"]
                          )
                        : 0);

                    let isSelfData = rateShopListData?.filter((x) => {
                      if (
                        x.CheckInDate === moment(item).format("YYYY-MM-DD") &&
                        x.IsSelf === true
                      ) {
                        return x;
                      }
                    });
                    let rateShoptotal = 0;
                    rateShopListData?.map((x) => {
                      if (
                        x.CheckInDate === moment(item).format("YYYY-MM-DD") &&
                        x.IsSelf === false
                      ) {
                        rateShoptotal = rateShoptotal + parseFloat(x?.Rate);
                      }
                    });
                    let findCompetitorData = rateShopListData?.filter(
                      (x) =>
                        x.CheckInDate === moment(item).format("YYYY-MM-DD") &&
                        x.IsSelf === false
                    );

                    let findDataForAvgCount = rateShopListData?.filter(
                      (x) =>
                        x.CheckInDate === moment(item).format("YYYY-MM-DD") &&
                        x.IsSelf === false &&
                        parseFloat(x.Rate) > 0
                    );

                    let avgCompetitor =
                      rateShoptotal > 0 && findCompetitorData?.length > 0
                        ? Math.round(
                            rateShoptotal / findDataForAvgCount?.length
                          )
                        : 0;

                    let dataExistsOrNot = rateShopListData?.filter((x) => {
                      if (
                        x?.CheckInDate === moment(item).format("YYYY-MM-DD") &&
                        x?.IsSelf === false
                      ) {
                        return x;
                      }
                    });

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    let foreCastSpiderTextColor = "";
                    let forecastSpiderBorder = "";
                    if (
                      singleForecastData?.[0]?.RMS <
                      singleForecastData?.[0]?.R28AVG
                    ) {
                      foreCastSpiderTextColor = "redText";
                      let R28AvgPercentage =
                        (singleForecastData?.[0]?.R28AVG * 10) / 100;
                      let finalValue =
                        singleForecastData?.[0]?.R28AVG - R28AvgPercentage;
                      if (singleForecastData?.[0]?.RMS < finalValue) {
                        forecastSpiderBorder = "redBorderTd";
                      }
                    } else {
                      let R28AvgPercentage =
                        (singleForecastData?.[0]?.R28AVG * 10) / 100;
                      let finalValue =
                        singleForecastData?.[0]?.R28AVG + R28AvgPercentage;
                      if (singleForecastData?.[0]?.RMS > finalValue) {
                        forecastSpiderBorder = "blackBorderTd";
                      }
                    }

                    let checkNoteExists = noteDetail?.notedata?.filter(
                      (x) =>
                        moment(x?.datadate).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );
                    return (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className={`${
                            dayName ? "backgroundTr" : ""
                          } shadowOnHover`}
                        >
                          <td className="">
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(item)}{" "}
                              {moment(new Date(item), "l").format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    style={{ height: "11px", width: "11px" }}
                                    className="iconcolor"
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current = item;
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="width100 border-right">
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.OOO
                                )
                              : 0}{" "}
                            \{" "}
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.RoomAvailable
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(
                                  singleOTBData?.[0]?.LeftToSell
                                )
                              : 0}
                          </td>
                          <td
                            className="text-primary text-center perbackground perbackground-otb"
                            value={
                              singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(
                                    singleOTBData?.[0]?.OnTheBook
                                  )
                                : 0
                            }
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                singleDateSelectedDateRef.current = item;
                                handleFilterBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? singleOTBData?.[0]?.OnTheBook
                                : 0}
                            </span>
                          </td>

                          <td>
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? singleOTBData?.[0]?.TotalOCCPercentage
                              : 0}
                            %
                          </td>
                          <td
                            className="text-end perbackground perbackground-adr"
                            value={singleOTBData?.[0]?.ADR}
                          >
                            {singleOTBData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border-right">
                            {singleOTBData?.[0]?.REV
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {singleOTBData?.[0]?.RevPAR
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.RevPAR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className="perbackground perbackground-GroupOTB border-right text-center text-primary"
                            value={singleOTBData?.[0]?.OTB}
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                singleDateSelectedDateRef.current = item;
                                handleGroupBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OTB
                                ? commonService.formateRoom(
                                    singleOTBData?.[0]?.OTB
                                  )
                                : 0}
                              \
                              {singleOTBData?.[0]?.Block
                                ? commonService.formateRoom(
                                    singleOTBData?.[0]?.Block
                                  )
                                : 0}
                            </span>
                          </td>
                          <td className="border-right text-center">
                            {singleBarBasedData?.[0]?.OTB
                              ? commonService.formateRoom(
                                  singleBarBasedData?.[0]?.OTB
                                )
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleBarBasedData?.[0]?.["8 Week Rolling AVG"]
                              ? commonService.formateRoom(
                                  singleBarBasedData?.[0]?.[
                                    "8 Week Rolling AVG"
                                  ]
                                )
                              : 0}
                          </td>
                          <td
                            className="perbackground perbackground-room text-primary cursorPointer text-center border-right"
                            value={singlePickupData?.[0]?.RMS}
                            onClick={() => {
                              reservationModalPayload.current = {
                                AsOfDate: para?.asOfDate,
                                PickUpDate: selectPickupDate
                                  ? commonService.convertDateInTimezone(
                                      selectPickupDate
                                    )
                                  : commonService.convertDateInTimezone(
                                      maxDateRef.current
                                    ),
                                propertyCode: para?.propertyCode,
                                SelectedDate: item,
                                pickupType: "oneDay",
                              };
                              setReservationModal(true);
                            }}
                          >
                            {singlePickupData?.[0]?.RMS
                              ? commonService.formateRoom(
                                  singlePickupData?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td className="text-end">
                            {singlePickupData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  singlePickupData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          <td
                            className="perbackground perbackground-room text-primary cursorPointer text-center border-right"
                            value={sevenPickupData?.[0]?.RMS}
                            onClick={() => {
                              reservationModalPayload.current = {
                                AsOfDate: para?.asOfDate,
                                PickUpDate: selectSevenPickupDate
                                  ? commonService.convertDateInTimezone(
                                      selectSevenPickupDate
                                    )
                                  : commonService.convertDateInTimezone(
                                      sevenMaxDateRef.current
                                    ),
                                propertyCode: para?.propertyCode,
                                SelectedDate: item,
                                pickupType: "sevenDay",
                              };
                              setReservationModal(true);
                            }}
                          >
                            {sevenPickupData?.[0]?.RMS
                              ? commonService.formateRoom(
                                  sevenPickupData?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td className="text-end">
                            {sevenPickupData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  sevenPickupData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="border-right text-center">
                            <div className="d-flex justify-content-center align-items-center">
                              <p
                                className={`${foreCastSpiderTextColor} ${forecastSpiderBorder} m-0`}
                                style={{ padding: "2px" }}
                              >
                                {singleForecastData?.[0]?.RMS
                                  ? commonService.formateRoom(
                                      singleForecastData?.[0]?.RMS
                                    )
                                  : 0}
                              </p>
                            </div>
                          </td>
                          <td>
                            {singleForecastData?.[0]?.R28AVG
                              ? commonService.formateRoom(
                                  singleForecastData?.[0]?.R28AVG
                                )
                              : 0}
                          </td>
                          <td className="text-end">
                            {singleForecastData?.[0]?.["Optimal Bar"]
                              ? `${commonService.formateAmount(
                                  singleForecastData?.[0]?.["Optimal Bar"]
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          {rateShopListData && (
                            <td
                              className={`text-end border-right ${
                                isSelfData?.[0]?.Rate < avgCompetitor
                                  ? "greenText"
                                  : "redText"
                              }`}
                            >
                              {isSelfData?.length > 0
                                ? `${commonService.formateAmount(
                                    Math.round(isSelfData[0]?.Rate)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                          )}
                          {rateShopListData && (
                            <td
                              className="text-end cursorPointer"
                              onClick={() => {
                                setClickedDate(item);
                                setToolsSummaryModal(true);
                              }}
                            >
                              {avgCompetitor
                                ? `${commonService.formateAmount(
                                    avgCompetitor
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                          )}
                          {dataExistsOrNot?.length > 0
                            ? rateShopListData?.map((x) => {
                                if (
                                  x.CheckInDate ===
                                    moment(item).format("YYYY-MM-DD") &&
                                  x.IsSelf === false
                                ) {
                                  return (
                                    <td className="text-end">
                                      {x?.Rate
                                        ? `${commonService.formateAmount(
                                            Math.round(x?.Rate)
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                  );
                                }
                              })
                            : rateShopListData?.map((x, index) => {
                                if (
                                  x.CheckInDate ===
                                    moment(
                                      rateShopListData?.[0]?.CheckInDate
                                    ).format("YYYY-MM-DD") &&
                                  x.IsSelf === false
                                ) {
                                  return <td className="text-end">$0</td>;
                                }
                              })}
                          <td className="border-right">
                            <div>
                              {checkNoteExists?.length > 0 ? (
                                <img
                                  className="cursorPointer"
                                  src={darkNoteIcon}
                                  onClick={() => {
                                    clickedDateForNote.current = item;
                                    setShowNoteSidebar(true);
                                  }}
                                  alt="note"
                                />
                              ) : (
                                <img
                                  className="cursorPointer"
                                  src={NoteIcon}
                                  onClick={() => {
                                    clickedDateForNote.current = item;
                                    setShowNoteSidebar(true);
                                  }}
                                  alt="note"
                                />
                              )}
                              <div style={{ display: "none" }}>
                                {checkNoteExists?.map((item, index) => {
                                  return (
                                    <>
                                      <span>{`${
                                        item?.displayname
                                      } - ${htmlToText(
                                        item?.widgetnotes
                                      )}`}</span>{" "}
                                      <br />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {index === startDateArrayRef.current?.length - 1 && (
                          <tr className="shadowOnHover">
                            <td className="boldTd">Total</td>
                            <td className="boldTd border-right">
                              {commonService.formateRoom(ooo)} \{" "}
                              {commonService.formateRoom(RmsAvailable)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(leftToSell)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(otb)}
                            </td>
                            <td className="boldTd">
                              {occPercentage > 0
                                ? `${parseInt(occPercentage / diffDays)}%`
                                : `0%`}
                            </td>
                            <td className="boldTd text-end">
                              {rev > 0 && otb > 0
                                ? `${commonService.formateAmount(
                                    parseInt(rev / otb)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(rev)}
                            </td>
                            <td className="boldTd text-end">
                              {revPar > 0
                                ? `${commonService.formateAmount(
                                    parseInt(revPar / diffDays)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center border-right">
                              {commonService.formateRoom(groupOTB)}/
                              {commonService.formateRoom(groupBlock)}
                            </td>
                            <td className="boldTd text-center border-right">
                              {commonService.formateRoom(barBasedOTB)}
                            </td>
                            <td className="boldTd text-center">
                              {commonService.formateRoom(baeBasedRollingAvg)}
                            </td>
                            <td className="boldTd text-center border-right">
                              {commonService.formateRoom(pickupRooms)}
                            </td>
                            <td className="boldTd text-end">
                              {pickupAdr > 0
                                ? commonService.formateAmount(
                                    parseInt(pickupAdr / pickupAdrCount)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd text-center border-right">
                              {commonService.formateRoom(sevenPickupRooms)}
                            </td>
                            <td className="boldTd text-end">
                              {sevenPickupAdr > 0
                                ? commonService.formateAmount(
                                    parseInt(
                                      sevenPickupAdr / sevenPickupAdrCount
                                    )
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="boldTd border-right"></td>
                            <td className="boldTd"></td>
                            <td className="boldTd"></td>
                            {rateShopListData && (
                              <>
                                {" "}
                                <td className="boldTd"></td>
                                <td className="boldTd"></td>
                                <td className="boldTd"></td>
                                <td className="boldTd"></td>
                                <td className="boldTd"></td>
                                <td className="boldTd"></td>
                              </>
                            )}
                            <td className="boldTd"></td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {showTicketModal && (
          <EventViewModal
            showModal={showTicketModal}
            closeModal={closeTicketModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getEventList}
          />
        )}
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"ORG"} />}

      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
          style={{ zIndex: 999999 }}
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectSevenPickupDate
                  ? selectSevenPickupDate
                  : sevenMaxDateRef.current
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>

      <Overlay
        show={groupModal.IsDisplay}
        target={groupModal.Target}
        placement={groupModal.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option active singleDayGroupSummary">
            <SingleDayGroupModal
              closeModal={closeGroupDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              propertyCode={propertyCode}
            />
          </div>
        </Popover>
      </Overlay>
      {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={0}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
          dateForNote={clickedDateForNote.current}
        />
      )}
      {addNoteModal && (
        <AddWidgetModal
          showModal={addNoteModal}
          closeModal={closeNoteModal}
          widgetId={0}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
      {showToolsSummaryModal && (
        <ToolsSummaryModal
          showToolsSummaryModal={showToolsSummaryModal}
          closeToolsSummaryModal={closeToolsSummaryModal}
          selectedDate={ClickedDate}
          propertyDetail={propertyDetailFromRTK}
        />
      )}
      {reservationModal && (
        <ReservationActivityModal
          showModal={reservationModal}
          closeModal={closeReservationModal}
          payload={reservationModalPayload.current}
        />
      )}
    </div>
  );
};

export default StrategyByReservation;
