import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Overlay, Popover, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useParams } from "react-router-dom";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import emailIcon from "../../../../Assets/Images/Email.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { useSelector } from "react-redux";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName } from "../../../../utils/constant";
import { FixColumn } from "../../../../utils/dataTable";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import EventViewModal from "../../../CommonComponent/EventViewModal";
import Comments from "../Comments/Comment";

const EventStretegy = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  let date = new Date();
  if (para?.monthStart) {
    date = new Date(para.monthStart);
  }
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 2, 0)
  );
  const [eventStretegyData, setEventStretegyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [sevenDayPickup, setSevenDayPickup] = useState();
  const [fourteenDayPickup, setFourteenDayPickup] = useState();
  const [widgetModal, setWidgetModal] = useState(false);
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const widgetDetailRef = useRef();
  const sevenDayPickupRef = useRef();
  const fourteenDayPickupRef = useRef();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const allEventSourceIdFromDbRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const maxDateRef = useRef();

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  let total = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    weekDay = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    weekEnd = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    sun = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    mon = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    tue = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    wed = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    thu = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    fri = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    },
    sat = {
      lefttoSell: 0,
      otb: 0,
      occPercentage: 0,
      adr: 0,
      adrCount: 0,
      rev: 0,
      sevenRms: 0,
      sevenAdr: 0,
      sevenAdrCount: 0,
      sevenRev: 0,
      fourteenRms: 0,
      fourteenAdr: 0,
      fourteenAdrCount: 0,
      fourteenRev: 0,
      lyRms: 0,
      lyAdr: 0,
      lyAdrCount: 0,
      lyRev: 0,
      stlyRms: 0,
      stlyAdr: 0,
      stlyAdrCount: 0,
      stlyRev: 0,
    };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
    }
  };

  const handleFilterBtnClick = (event) => {
    // let screenY = event.screenY;
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    // let Left = event.nativeEvent.layerX + 30;
    if (Top + 400 > window.innerHeight) {
      // Top = Top - 400;
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    // setONTHEBOOKS({ IsDisplay: false, Property: { top: 0, left: 0 } });
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const getEventSourceIdFromDb = async () => {
    let eventSourceIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.EVENTS.GET_ALL_EVENT_SOURCE_ID,
        body: {
          id: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, eventSourceIdResponse);

    if (response?.isValidate) {
      let splitArray = response?.data?.data?.split(",");
      allEventSourceIdFromDbRef.current = splitArray;
    }

    if (!response?.isValidate) {
      allEventSourceIdFromDbRef.current = null;
    }
  };

  const getEventFromDotNet = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: propertyDetailFromRTK?.propertyid,
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: "",
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let eventsList = [];

      dataFromApi?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: moment(item?.startdate).format("YYYY-MM-DD"),
          endDate: moment(item?.enddate).format("YYYY-MM-DD"),
          start: moment(item?.startdate).format("YYYY-MM-DD"),
          end: moment(item?.enddate).format("YYYY-MM-DD"),
          title: item?.eventname,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
          backgroundColor: "#ffebeb",
          textColor: "black",
          className: "poiter-event-none",
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventsList.push(singleEventObj);
      });
      eventListRef.current = [...eventListRef.current, ...eventsList];
    }

    if (!response?.isValidate) {
    }
  };

  const getEventStretegyData = async () => {
    setLoading(true);
    eventListRef.current = [];
    await getEventSourceIdFromDb();
    let eventStretegyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.EVENT_STRETEGY,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
          sevendaypickup: sevenDayPickupRef.current
            ? commonService.getDateForNode(sevenDayPickupRef.current)
            : new Date(),
          fourteendaypickup: fourteenDayPickupRef.current
            ? commonService.getDateForNode(fourteenDayPickupRef.current)
            : new Date(),
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, eventStretegyResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let eventNotExistsInDb = [];
      dataFromApi?.forEach((item) => {
        item?.Event_details?.forEach((eventItem) => {
          let eventExistsInDb = allEventSourceIdFromDbRef.current?.filter(
            (x) => x === eventItem?.event_id
          );
          if (eventExistsInDb?.length <= 0) {
            let singleEventObj = {
              id: eventItem?.event_id,
              name: eventItem?.title,
              detail: eventItem?.description,
              startDate: moment(eventItem?.start_date).format("YYYY-MM-DD"),
              endDate: moment(eventItem?.end_date).format("YYYY-MM-DD"),
              start: moment(eventItem?.start_date).format("YYYY-MM-DD"),
              end: moment(eventItem?.end_date).format("YYYY-MM-DD"),
              title: eventItem?.title,
              labels: eventItem?.labels,
              rank: eventItem?.rank ? eventItem?.rank : 0,
              local_rank: eventItem?.local_rank ? eventItem?.local_rank : 0,
              phq_attendance: eventItem?.phq_attendance
                ? eventItem?.phq_attendance
                : 0,
              geo_lat: eventItem?.geo_lat ? eventItem?.geo_lat : "",
              geo_long: eventItem?.geo_long ? eventItem?.geo_long : "",
              backgroundColor: "#e1e8ff",
              textColor: "black",
              className: "poiter-event-none",
              eventJson: eventItem,
            };
            eventNotExistsInDb.push(singleEventObj);
          }
        });
      });
      eventListRef.current = eventNotExistsInDb;
      await getEventFromDotNet();
      setLoading(false);
      setEventStretegyData(response?.data?.data);
    }

    if (!response?.isValidate) {
      eventListRef.current = [];
      setLoading(false);
      setEventStretegyData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (
        para?.asOfDate &&
        sevenDayPickupRef.current &&
        fourteenDayPickupRef.current
      ) {
        getEventStretegyData();
      }
    }
  }, [
    para?.asOfDate,
    sevenDayPickupRef.current,
    fourteenDayPickupRef.current,
    para?.monthStart,
  ]);

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        let date = new Date(para?.asOfDate);

        let sevenDayDate = new Date().setDate(date.getDate() - 7);
        let fourteenDayDate = new Date().setDate(date.getDate() - 14);

        let mDate = new Date(para?.defaultAsOfDate);
        maxDateRef.current = mDate.setDate(mDate.getDate() - 1);

        setSevenDayPickup(sevenDayDate);
        setFourteenDayPickup(fourteenDayDate);
        sevenDayPickupRef.current = sevenDayDate;
        fourteenDayPickupRef.current = fourteenDayDate;
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    eventStrategyTable: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-otb`
  );

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-7DayRms`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-7DayAdr`
  );

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-14DayRms`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-14DayAdr`
  );

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-LyRms`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-LyAdr`
  );

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-StlyRms`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.eventStrategyTable} .perbackground-StlyAdr`
  );

  FixColumn(tblids.eventStrategyTable, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.eventStretegyWidget,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.eventStrategyTable,
                  "Event Strategy",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="download"
              import={tblids.eventStrategyTable}
              onClick={() => {
                commonService.fnExportInCSV(
                  tblids.eventStrategyTable,
                  // "Event Strategy"
                  `${para?.propertyCode}-Event Strategy-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                );
              }}
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table
              id={tblids.eventStrategyTable}
              className="table-with-spacing positionStaticTable dailySummaryTbl"
            >
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="border_right"></th>
                  <th className="border_right"></th>
                  <th colSpan={5} className="text-center border_right">
                    On The Books
                  </th>
                  <th className="text-center border_right" colSpan={3}>
                    <div className="">
                      <span>7 Day Pickup</span>
                      <DatePicker
                        className=""
                        id="pickupDate"
                        maxDate={new Date(maxDateRef.current)}
                        selected={sevenDayPickup}
                        onChange={(date) => {
                          setSevenDayPickup(date);
                          sevenDayPickupRef.current = date;
                        }}
                      />
                      <span style={{ display: "none" }}>
                        {commonService.getDateInFormat(
                          new Date(sevenDayPickup)
                        )}
                      </span>
                    </div>
                  </th>
                  <th className="text-center border_right" colSpan={3}>
                    <div className="">
                      <span>14 Day Pickup</span>
                      <DatePicker
                        className=""
                        id="pickupDate"
                        maxDate={new Date(maxDateRef.current)}
                        selected={fourteenDayPickup}
                        onChange={(date) => {
                          setFourteenDayPickup(date);
                          fourteenDayPickupRef.current = date;
                        }}
                      />
                      <span style={{ display: "none" }}>
                        {commonService.getDateInFormat(
                          new Date(fourteenDayPickup)
                        )}
                      </span>
                    </div>
                  </th>
                  <th className="text-center border_right">Forecast</th>
                  <th
                    className="text-center border_right"
                    colSpan={eventStretegyData?.[0]?.Rateshop?.length + 1}
                  >
                    Rate Shop
                  </th>
                  <th className="text-center border_right" colSpan={3}>
                    Last Year
                  </th>
                  <th className="text-center" colSpan={3}>
                    STLY
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width175 border_right"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  getEventStretegyData();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate && (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    )}
                  </th>
                  <th className="border_right text-center width150">Events</th>
                  <th className="width75 text-center">Left to Sell</th>
                  <th className="width75 text-center">On The Books</th>
                  <th className="text-center">Total OCC%</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">Revenue</th>
                  <th>RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th>RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="border_right text-center">Rooms</th>
                  {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                    if (x.IsSelf === true) {
                      return (
                        <th
                          className={`text-end`}
                          title={x?.competiterpropertyname}
                        >
                          {x?.competiterpropertyname?.length > 10
                            ? `${x?.competiterpropertyname?.slice(0, 10)}...`
                            : x?.competiterpropertyname}
                        </th>
                      );
                    }
                  })}
                  {eventStretegyData?.[0]?.Rateshop && <th>Avg Competitor</th>}
                  {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                    if (x.IsSelf === false) {
                      return (
                        <th
                          className={`text-end ${
                            eventStretegyData?.[0]?.Rateshop?.length - 1 === i
                              ? "border_right"
                              : ""
                          }`}
                          title={x?.competiterpropertyname}
                        >
                          {x?.competiterpropertyname?.length > 10
                            ? `${x?.competiterpropertyname?.slice(0, 10)}...`
                            : x?.competiterpropertyname}
                        </th>
                      );
                    }
                  })}
                  <th>RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th>RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">REV</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : eventStretegyData ? (
                  eventStretegyData?.map((item, index) => {
                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item?.Date);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    let isSelfData = item?.Rateshop?.filter(
                      (x) => x?.IsSelf === true
                    );
                    let countForRateshopAvg = 0,
                      rateshopTotal = 0;

                    item?.Rateshop?.forEach((rateshopItem) => {
                      if (!rateshopItem?.IsSelf) {
                        rateshopTotal =
                          rateshopTotal +
                          (rateshopItem?.Rate ? rateshopItem?.Rate : 0);

                        countForRateshopAvg =
                          rateshopItem?.Rate > 0
                            ? countForRateshopAvg + 1
                            : countForRateshopAvg;
                      }
                    });

                    let avgCompetitor = Math.round(
                      rateshopTotal / countForRateshopAvg
                    );

                    total.lefttoSell =
                      total.lefttoSell +
                      (item?.OnTheBook[0]?.LeftToSell
                        ? item?.OnTheBook[0]?.LeftToSell
                        : 0);
                    total.otb =
                      total.otb +
                      (item?.OnTheBook[0]?.OnTheBook
                        ? item?.OnTheBook[0]?.OnTheBook
                        : 0);
                    total.occPercentage =
                      total.occPercentage +
                      (item?.OnTheBook[0]?.TotalOCCPercentage
                        ? item?.OnTheBook[0]?.TotalOCCPercentage
                        : 0);
                    total.adr =
                      total.adr +
                      (item?.OnTheBook[0]?.ADR ? item?.OnTheBook[0]?.ADR : 0);
                    total.adrCount =
                      total.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                    total.rev =
                      total.rev +
                      (item?.OnTheBook[0]?.REV ? item?.OnTheBook[0]?.REV : 0);
                    total.sevenRms =
                      total.sevenRms +
                      (item?.["7 Day Pickup"]?.[0]?.RMS
                        ? item?.["7 Day Pickup"]?.[0]?.RMS
                        : 0);
                    total.sevenAdr =
                      total.sevenAdr +
                      (item?.["7 Day Pickup"]?.[0]?.ADR
                        ? item?.["7 Day Pickup"]?.[0]?.ADR
                        : 0);
                    total.sevenAdrCount =
                      total.sevenAdrCount +
                      (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                    total.sevenRev =
                      total.sevenRev +
                      (item?.["7 Day Pickup"]?.[0]?.REV
                        ? item?.["7 Day Pickup"]?.[0]?.REV
                        : 0);
                    total.fourteenRms =
                      total.fourteenRms +
                      (item?.["14 Day Pickup"]?.[0]?.RMS
                        ? item?.["14 Day Pickup"]?.[0]?.RMS
                        : 0);
                    total.fourteenAdr =
                      total.fourteenAdr +
                      (item?.["14 Day Pickup"]?.[0]?.ADR
                        ? item?.["14 Day Pickup"]?.[0]?.ADR
                        : 0);
                    total.fourteenAdrCount =
                      total.fourteenAdrCount +
                      (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                    total.fourteenRev =
                      total.fourteenRev +
                      (item?.["14 Day Pickup"]?.[0]?.REV
                        ? item?.["14 Day Pickup"]?.[0]?.REV
                        : 0);
                    total.lyRms =
                      total.lyRms +
                      (item?.LastYear?.[0]?.RMS ? item?.LastYear?.[0]?.RMS : 0);
                    total.lyAdr =
                      total.lyAdr +
                      (item?.LastYear?.[0]?.ADR ? item?.LastYear?.[0]?.ADR : 0);
                    total.lyAdrCount =
                      total.lyAdrCount + (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                    total.lyRev =
                      total.lyRev +
                      (item?.LastYear?.[0]?.REV ? item?.LastYear?.[0]?.REV : 0);
                    total.stlyRms =
                      total.stlyRms +
                      (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                    total.stlyAdr =
                      total.stlyAdr +
                      (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                    total.stlyAdrCount =
                      total.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                    total.stlyRev =
                      total.stlyRms +
                      (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);

                    if (
                      moment(new Date(item?.Date), "l").format("ddd") ===
                        "Sun" ||
                      moment(new Date(item?.Date), "l").format("ddd") === "Sat"
                    ) {
                      weekEnd.lefttoSell =
                        weekEnd.lefttoSell +
                        (item?.OnTheBook[0]?.LeftToSell
                          ? item?.OnTheBook[0]?.LeftToSell
                          : 0);
                      weekEnd.otb =
                        weekEnd.otb +
                        (item?.OnTheBook[0]?.OnTheBook
                          ? item?.OnTheBook[0]?.OnTheBook
                          : 0);
                      weekEnd.occPercentage =
                        weekEnd.occPercentage +
                        (item?.OnTheBook[0]?.TotalOCCPercentage
                          ? item?.OnTheBook[0]?.TotalOCCPercentage
                          : 0);
                      weekEnd.adr =
                        weekEnd.adr +
                        (item?.OnTheBook[0]?.ADR ? item?.OnTheBook[0]?.ADR : 0);
                      weekEnd.adrCount =
                        weekEnd.adrCount +
                        (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                      weekEnd.rev =
                        weekEnd.rev +
                        (item?.OnTheBook[0]?.REV ? item?.OnTheBook[0]?.REV : 0);
                      weekEnd.sevenRms =
                        weekEnd.sevenRms +
                        (item?.["7 Day Pickup"]?.[0]?.RMS
                          ? item?.["7 Day Pickup"]?.[0]?.RMS
                          : 0);
                      weekEnd.sevenAdr =
                        weekEnd.sevenAdr +
                        (item?.["7 Day Pickup"]?.[0]?.ADR
                          ? item?.["7 Day Pickup"]?.[0]?.ADR
                          : 0);
                      weekEnd.sevenAdrCount =
                        weekEnd.sevenAdrCount +
                        (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                      weekEnd.sevenRev =
                        weekEnd.sevenRev +
                        (item?.["7 Day Pickup"]?.[0]?.REV
                          ? item?.["7 Day Pickup"]?.[0]?.REV
                          : 0);
                      weekEnd.fourteenRms =
                        weekEnd.fourteenRms +
                        (item?.["14 Day Pickup"]?.[0]?.RMS
                          ? item?.["14 Day Pickup"]?.[0]?.RMS
                          : 0);
                      weekEnd.fourteenAdr =
                        weekEnd.fourteenAdr +
                        (item?.["14 Day Pickup"]?.[0]?.ADR
                          ? item?.["14 Day Pickup"]?.[0]?.ADR
                          : 0);
                      weekEnd.fourteenAdrCount =
                        weekEnd.fourteenAdrCount +
                        (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                      weekEnd.fourteenRev =
                        weekEnd.fourteenRev +
                        (item?.["14 Day Pickup"]?.[0]?.REV
                          ? item?.["14 Day Pickup"]?.[0]?.REV
                          : 0);
                      weekEnd.lyRms =
                        weekEnd.lyRms +
                        (item?.LastYear?.[0]?.RMS
                          ? item?.LastYear?.[0]?.RMS
                          : 0);
                      weekEnd.lyAdr =
                        weekEnd.lyAdr +
                        (item?.LastYear?.[0]?.ADR
                          ? item?.LastYear?.[0]?.ADR
                          : 0);
                      weekEnd.lyAdrCount =
                        weekEnd.lyAdrCount +
                        (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                      weekEnd.lyRev =
                        weekEnd.lyRev +
                        (item?.LastYear?.[0]?.REV
                          ? item?.LastYear?.[0]?.REV
                          : 0);
                      weekEnd.stlyRms =
                        weekEnd.stlyRms +
                        (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                      weekEnd.stlyAdr =
                        weekEnd.stlyAdr +
                        (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                      weekEnd.stlyAdrCount =
                        weekEnd.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                      weekEnd.stlyRev =
                        weekEnd.stlyRms +
                        (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      if (
                        moment(new Date(item?.Date), "l").format("ddd") ===
                        "Sat"
                      ) {
                        sat.lefttoSell =
                          sat.lefttoSell +
                          (item?.OnTheBook[0]?.LeftToSell
                            ? item?.OnTheBook[0]?.LeftToSell
                            : 0);
                        sat.otb =
                          sat.otb +
                          (item?.OnTheBook[0]?.OnTheBook
                            ? item?.OnTheBook[0]?.OnTheBook
                            : 0);
                        sat.occPercentage =
                          sat.occPercentage +
                          (item?.OnTheBook[0]?.TotalOCCPercentage
                            ? item?.OnTheBook[0]?.TotalOCCPercentage
                            : 0);
                        sat.adr =
                          sat.adr +
                          (item?.OnTheBook[0]?.ADR
                            ? item?.OnTheBook[0]?.ADR
                            : 0);
                        sat.adrCount =
                          sat.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                        sat.rev =
                          sat.rev +
                          (item?.OnTheBook[0]?.REV
                            ? item?.OnTheBook[0]?.REV
                            : 0);
                        sat.sevenRms =
                          sat.sevenRms +
                          (item?.["7 Day Pickup"]?.[0]?.RMS
                            ? item?.["7 Day Pickup"]?.[0]?.RMS
                            : 0);
                        sat.sevenAdr =
                          sat.sevenAdr +
                          (item?.["7 Day Pickup"]?.[0]?.ADR
                            ? item?.["7 Day Pickup"]?.[0]?.ADR
                            : 0);
                        sat.sevenAdrCount =
                          sat.sevenAdrCount +
                          (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        sat.sevenRev =
                          sat.sevenRev +
                          (item?.["7 Day Pickup"]?.[0]?.REV
                            ? item?.["7 Day Pickup"]?.[0]?.REV
                            : 0);
                        sat.fourteenRms =
                          sat.fourteenRms +
                          (item?.["14 Day Pickup"]?.[0]?.RMS
                            ? item?.["14 Day Pickup"]?.[0]?.RMS
                            : 0);
                        sat.fourteenAdr =
                          sat.fourteenAdr +
                          (item?.["14 Day Pickup"]?.[0]?.ADR
                            ? item?.["14 Day Pickup"]?.[0]?.ADR
                            : 0);
                        sat.fourteenAdrCount =
                          sat.fourteenAdrCount +
                          (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        sat.fourteenRev =
                          sat.fourteenRev +
                          (item?.["14 Day Pickup"]?.[0]?.REV
                            ? item?.["14 Day Pickup"]?.[0]?.REV
                            : 0);
                        sat.lyRms =
                          sat.lyRms +
                          (item?.LastYear?.[0]?.RMS
                            ? item?.LastYear?.[0]?.RMS
                            : 0);
                        sat.lyAdr =
                          sat.lyAdr +
                          (item?.LastYear?.[0]?.ADR
                            ? item?.LastYear?.[0]?.ADR
                            : 0);
                        sat.lyAdrCount =
                          sat.lyAdrCount +
                          (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                        sat.lyRev =
                          sat.lyRev +
                          (item?.LastYear?.[0]?.REV
                            ? item?.LastYear?.[0]?.REV
                            : 0);
                        sat.stlyRms =
                          sat.stlyRms +
                          (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                        sat.stlyAdr =
                          sat.stlyAdr +
                          (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                        sat.stlyAdrCount =
                          sat.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                        sat.stlyRev =
                          sat.stlyRms +
                          (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      }
                      if (
                        moment(new Date(item?.Date), "l").format("ddd") ===
                        "Sun"
                      ) {
                        sun.lefttoSell =
                          sun.lefttoSell +
                          (item?.OnTheBook[0]?.LeftToSell
                            ? item?.OnTheBook[0]?.LeftToSell
                            : 0);
                        sun.otb =
                          sun.otb +
                          (item?.OnTheBook[0]?.OnTheBook
                            ? item?.OnTheBook[0]?.OnTheBook
                            : 0);
                        sun.occPercentage =
                          sun.occPercentage +
                          (item?.OnTheBook[0]?.TotalOCCPercentage
                            ? item?.OnTheBook[0]?.TotalOCCPercentage
                            : 0);
                        sun.adr =
                          sun.adr +
                          (item?.OnTheBook[0]?.ADR
                            ? item?.OnTheBook[0]?.ADR
                            : 0);
                        sun.adrCount =
                          sun.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                        sun.rev =
                          sun.rev +
                          (item?.OnTheBook[0]?.REV
                            ? item?.OnTheBook[0]?.REV
                            : 0);
                        sun.sevenRms =
                          sun.sevenRms +
                          (item?.["7 Day Pickup"]?.[0]?.RMS
                            ? item?.["7 Day Pickup"]?.[0]?.RMS
                            : 0);
                        sun.sevenAdr =
                          sun.sevenAdr +
                          (item?.["7 Day Pickup"]?.[0]?.ADR
                            ? item?.["7 Day Pickup"]?.[0]?.ADR
                            : 0);
                        sun.sevenAdrCount =
                          sun.sevenAdrCount +
                          (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        sun.sevenRev =
                          sun.sevenRev +
                          (item?.["7 Day Pickup"]?.[0]?.REV
                            ? item?.["7 Day Pickup"]?.[0]?.REV
                            : 0);
                        sun.fourteenRms =
                          sun.fourteenRms +
                          (item?.["14 Day Pickup"]?.[0]?.RMS
                            ? item?.["14 Day Pickup"]?.[0]?.RMS
                            : 0);
                        sun.fourteenAdr =
                          sun.fourteenAdr +
                          (item?.["14 Day Pickup"]?.[0]?.ADR
                            ? item?.["14 Day Pickup"]?.[0]?.ADR
                            : 0);
                        sun.fourteenAdrCount =
                          sun.fourteenAdrCount +
                          (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        sun.fourteenRev =
                          sun.fourteenRev +
                          (item?.["14 Day Pickup"]?.[0]?.REV
                            ? item?.["14 Day Pickup"]?.[0]?.REV
                            : 0);
                        sun.lyRms =
                          sun.lyRms +
                          (item?.LastYear?.[0]?.RMS
                            ? item?.LastYear?.[0]?.RMS
                            : 0);
                        sun.lyAdr =
                          sun.lyAdr +
                          (item?.LastYear?.[0]?.ADR
                            ? item?.LastYear?.[0]?.ADR
                            : 0);
                        sun.lyAdrCount =
                          sun.lyAdrCount +
                          (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                        sun.lyRev =
                          sun.lyRev +
                          (item?.LastYear?.[0]?.REV
                            ? item?.LastYear?.[0]?.REV
                            : 0);
                        sun.stlyRms =
                          sun.stlyRms +
                          (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                        sun.stlyAdr =
                          sun.stlyAdr +
                          (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                        sun.stlyAdrCount =
                          sun.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                        sun.stlyRev =
                          sun.stlyRms +
                          (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      }
                    } else {
                      weekDay.lefttoSell =
                        weekDay.lefttoSell +
                        (item?.OnTheBook[0]?.LeftToSell
                          ? item?.OnTheBook[0]?.LeftToSell
                          : 0);
                      weekDay.otb =
                        weekDay.otb +
                        (item?.OnTheBook[0]?.OnTheBook
                          ? item?.OnTheBook[0]?.OnTheBook
                          : 0);
                      weekDay.occPercentage =
                        weekDay.occPercentage +
                        (item?.OnTheBook[0]?.TotalOCCPercentage
                          ? item?.OnTheBook[0]?.TotalOCCPercentage
                          : 0);
                      weekDay.adr =
                        weekDay.adr +
                        (item?.OnTheBook[0]?.ADR ? item?.OnTheBook[0]?.ADR : 0);
                      weekDay.adrCount =
                        weekDay.adrCount +
                        (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                      weekDay.rev =
                        weekDay.rev +
                        (item?.OnTheBook[0]?.REV ? item?.OnTheBook[0]?.REV : 0);
                      weekDay.sevenRms =
                        weekDay.sevenRms +
                        (item?.["7 Day Pickup"]?.[0]?.RMS
                          ? item?.["7 Day Pickup"]?.[0]?.RMS
                          : 0);
                      weekDay.sevenAdr =
                        weekDay.sevenAdr +
                        (item?.["7 Day Pickup"]?.[0]?.ADR
                          ? item?.["7 Day Pickup"]?.[0]?.ADR
                          : 0);
                      weekDay.sevenAdrCount =
                        weekDay.sevenAdrCount +
                        (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                      weekDay.sevenRev =
                        weekDay.sevenRev +
                        (item?.["7 Day Pickup"]?.[0]?.REV
                          ? item?.["7 Day Pickup"]?.[0]?.REV
                          : 0);
                      weekDay.fourteenRms =
                        weekDay.fourteenRms +
                        (item?.["14 Day Pickup"]?.[0]?.RMS
                          ? item?.["14 Day Pickup"]?.[0]?.RMS
                          : 0);
                      weekDay.fourteenAdr =
                        weekDay.fourteenAdr +
                        (item?.["14 Day Pickup"]?.[0]?.ADR
                          ? item?.["14 Day Pickup"]?.[0]?.ADR
                          : 0);
                      weekDay.fourteenAdrCount =
                        weekDay.fourteenAdrCount +
                        (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                      weekDay.fourteenRev =
                        weekDay.fourteenRev +
                        (item?.["14 Day Pickup"]?.[0]?.REV
                          ? item?.["14 Day Pickup"]?.[0]?.REV
                          : 0);
                      weekDay.lyRms =
                        weekDay.lyRms +
                        (item?.LastYear?.[0]?.RMS
                          ? item?.LastYear?.[0]?.RMS
                          : 0);
                      weekDay.lyAdr =
                        weekDay.lyAdr +
                        (item?.LastYear?.[0]?.ADR
                          ? item?.LastYear?.[0]?.ADR
                          : 0);
                      weekDay.lyAdrCount =
                        weekDay.lyAdrCount +
                        (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                      weekDay.lyRev =
                        weekDay.lyRev +
                        (item?.LastYear?.[0]?.REV
                          ? item?.LastYear?.[0]?.REV
                          : 0);
                      weekDay.stlyRms =
                        weekDay.stlyRms +
                        (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                      weekDay.stlyAdr =
                        weekDay.stlyAdr +
                        (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                      weekDay.stlyAdrCount =
                        weekDay.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                      weekDay.stlyRev =
                        weekDay.stlyRms +
                        (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      if (
                        moment(new Date(item?.Date), "l").format("ddd") ===
                        "Mon"
                      ) {
                        mon.lefttoSell =
                          mon.lefttoSell +
                          (item?.OnTheBook[0]?.LeftToSell
                            ? item?.OnTheBook[0]?.LeftToSell
                            : 0);
                        mon.otb =
                          mon.otb +
                          (item?.OnTheBook[0]?.OnTheBook
                            ? item?.OnTheBook[0]?.OnTheBook
                            : 0);
                        mon.occPercentage =
                          mon.occPercentage +
                          (item?.OnTheBook[0]?.TotalOCCPercentage
                            ? item?.OnTheBook[0]?.TotalOCCPercentage
                            : 0);
                        mon.adr =
                          mon.adr +
                          (item?.OnTheBook[0]?.ADR
                            ? item?.OnTheBook[0]?.ADR
                            : 0);
                        mon.adrCount =
                          mon.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                        mon.rev =
                          mon.rev +
                          (item?.OnTheBook[0]?.REV
                            ? item?.OnTheBook[0]?.REV
                            : 0);
                        mon.sevenRms =
                          mon.sevenRms +
                          (item?.["7 Day Pickup"]?.[0]?.RMS
                            ? item?.["7 Day Pickup"]?.[0]?.RMS
                            : 0);
                        mon.sevenAdr =
                          mon.sevenAdr +
                          (item?.["7 Day Pickup"]?.[0]?.ADR
                            ? item?.["7 Day Pickup"]?.[0]?.ADR
                            : 0);
                        mon.sevenAdrCount =
                          mon.sevenAdrCount +
                          (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        mon.sevenRev =
                          mon.sevenRev +
                          (item?.["7 Day Pickup"]?.[0]?.REV
                            ? item?.["7 Day Pickup"]?.[0]?.REV
                            : 0);
                        mon.fourteenRms =
                          mon.fourteenRms +
                          (item?.["14 Day Pickup"]?.[0]?.RMS
                            ? item?.["14 Day Pickup"]?.[0]?.RMS
                            : 0);
                        mon.fourteenAdr =
                          mon.fourteenAdr +
                          (item?.["14 Day Pickup"]?.[0]?.ADR
                            ? item?.["14 Day Pickup"]?.[0]?.ADR
                            : 0);
                        mon.fourteenAdrCount =
                          mon.fourteenAdrCount +
                          (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        mon.fourteenRev =
                          mon.fourteenRev +
                          (item?.["14 Day Pickup"]?.[0]?.REV
                            ? item?.["14 Day Pickup"]?.[0]?.REV
                            : 0);
                        mon.lyRms =
                          mon.lyRms +
                          (item?.LastYear?.[0]?.RMS
                            ? item?.LastYear?.[0]?.RMS
                            : 0);
                        mon.lyAdr =
                          mon.lyAdr +
                          (item?.LastYear?.[0]?.ADR
                            ? item?.LastYear?.[0]?.ADR
                            : 0);
                        mon.lyAdrCount =
                          mon.lyAdrCount +
                          (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                        mon.lyRev =
                          mon.lyRev +
                          (item?.LastYear?.[0]?.REV
                            ? item?.LastYear?.[0]?.REV
                            : 0);
                        mon.stlyRms =
                          mon.stlyRms +
                          (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                        mon.stlyAdr =
                          mon.stlyAdr +
                          (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                        mon.stlyAdrCount =
                          mon.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                        mon.stlyRev =
                          mon.stlyRms +
                          (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      }
                      if (
                        moment(new Date(item?.Date), "l").format("ddd") ===
                        "Tue"
                      ) {
                        tue.lefttoSell =
                          tue.lefttoSell +
                          (item?.OnTheBook[0]?.LeftToSell
                            ? item?.OnTheBook[0]?.LeftToSell
                            : 0);
                        tue.otb =
                          tue.otb +
                          (item?.OnTheBook[0]?.OnTheBook
                            ? item?.OnTheBook[0]?.OnTheBook
                            : 0);
                        tue.occPercentage =
                          tue.occPercentage +
                          (item?.OnTheBook[0]?.TotalOCCPercentage
                            ? item?.OnTheBook[0]?.TotalOCCPercentage
                            : 0);
                        tue.adr =
                          tue.adr +
                          (item?.OnTheBook[0]?.ADR
                            ? item?.OnTheBook[0]?.ADR
                            : 0);
                        tue.adrCount =
                          tue.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                        tue.rev =
                          tue.rev +
                          (item?.OnTheBook[0]?.REV
                            ? item?.OnTheBook[0]?.REV
                            : 0);
                        tue.sevenRms =
                          tue.sevenRms +
                          (item?.["7 Day Pickup"]?.[0]?.RMS
                            ? item?.["7 Day Pickup"]?.[0]?.RMS
                            : 0);
                        tue.sevenAdr =
                          tue.sevenAdr +
                          (item?.["7 Day Pickup"]?.[0]?.ADR
                            ? item?.["7 Day Pickup"]?.[0]?.ADR
                            : 0);
                        tue.sevenAdrCount =
                          tue.sevenAdrCount +
                          (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        tue.sevenRev =
                          tue.sevenRev +
                          (item?.["7 Day Pickup"]?.[0]?.REV
                            ? item?.["7 Day Pickup"]?.[0]?.REV
                            : 0);
                        tue.fourteenRms =
                          tue.fourteenRms +
                          (item?.["14 Day Pickup"]?.[0]?.RMS
                            ? item?.["14 Day Pickup"]?.[0]?.RMS
                            : 0);
                        tue.fourteenAdr =
                          tue.fourteenAdr +
                          (item?.["14 Day Pickup"]?.[0]?.ADR
                            ? item?.["14 Day Pickup"]?.[0]?.ADR
                            : 0);
                        tue.fourteenAdrCount =
                          tue.fourteenAdrCount +
                          (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        tue.fourteenRev =
                          tue.fourteenRev +
                          (item?.["14 Day Pickup"]?.[0]?.REV
                            ? item?.["14 Day Pickup"]?.[0]?.REV
                            : 0);
                        tue.lyRms =
                          tue.lyRms +
                          (item?.LastYear?.[0]?.RMS
                            ? item?.LastYear?.[0]?.RMS
                            : 0);
                        tue.lyAdr =
                          tue.lyAdr +
                          (item?.LastYear?.[0]?.ADR
                            ? item?.LastYear?.[0]?.ADR
                            : 0);
                        tue.lyAdrCount =
                          tue.lyAdrCount +
                          (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                        tue.lyRev =
                          tue.lyRev +
                          (item?.LastYear?.[0]?.REV
                            ? item?.LastYear?.[0]?.REV
                            : 0);
                        tue.stlyRms =
                          tue.stlyRms +
                          (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                        tue.stlyAdr =
                          tue.stlyAdr +
                          (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                        tue.stlyAdrCount =
                          tue.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                        tue.stlyRev =
                          tue.stlyRms +
                          (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      }
                      if (
                        moment(new Date(item?.Date), "l").format("ddd") ===
                        "Wed"
                      ) {
                        wed.lefttoSell =
                          wed.lefttoSell +
                          (item?.OnTheBook[0]?.LeftToSell
                            ? item?.OnTheBook[0]?.LeftToSell
                            : 0);
                        wed.otb =
                          wed.otb +
                          (item?.OnTheBook[0]?.OnTheBook
                            ? item?.OnTheBook[0]?.OnTheBook
                            : 0);
                        wed.occPercentage =
                          wed.occPercentage +
                          (item?.OnTheBook[0]?.TotalOCCPercentage
                            ? item?.OnTheBook[0]?.TotalOCCPercentage
                            : 0);
                        wed.adr =
                          wed.adr +
                          (item?.OnTheBook[0]?.ADR
                            ? item?.OnTheBook[0]?.ADR
                            : 0);
                        wed.adrCount =
                          wed.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                        wed.rev =
                          wed.rev +
                          (item?.OnTheBook[0]?.REV
                            ? item?.OnTheBook[0]?.REV
                            : 0);
                        wed.sevenRms =
                          wed.sevenRms +
                          (item?.["7 Day Pickup"]?.[0]?.RMS
                            ? item?.["7 Day Pickup"]?.[0]?.RMS
                            : 0);
                        wed.sevenAdr =
                          wed.sevenAdr +
                          (item?.["7 Day Pickup"]?.[0]?.ADR
                            ? item?.["7 Day Pickup"]?.[0]?.ADR
                            : 0);
                        wed.sevenAdrCount =
                          wed.sevenAdrCount +
                          (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        wed.sevenRev =
                          wed.sevenRev +
                          (item?.["7 Day Pickup"]?.[0]?.REV
                            ? item?.["7 Day Pickup"]?.[0]?.REV
                            : 0);
                        wed.fourteenRms =
                          wed.fourteenRms +
                          (item?.["14 Day Pickup"]?.[0]?.RMS
                            ? item?.["14 Day Pickup"]?.[0]?.RMS
                            : 0);
                        wed.fourteenAdr =
                          wed.fourteenAdr +
                          (item?.["14 Day Pickup"]?.[0]?.ADR
                            ? item?.["14 Day Pickup"]?.[0]?.ADR
                            : 0);
                        wed.fourteenAdrCount =
                          wed.fourteenAdrCount +
                          (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        wed.fourteenRev =
                          wed.fourteenRev +
                          (item?.["14 Day Pickup"]?.[0]?.REV
                            ? item?.["14 Day Pickup"]?.[0]?.REV
                            : 0);
                        wed.lyRms =
                          wed.lyRms +
                          (item?.LastYear?.[0]?.RMS
                            ? item?.LastYear?.[0]?.RMS
                            : 0);
                        wed.lyAdr =
                          wed.lyAdr +
                          (item?.LastYear?.[0]?.ADR
                            ? item?.LastYear?.[0]?.ADR
                            : 0);
                        wed.lyAdrCount =
                          wed.lyAdrCount +
                          (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                        wed.lyRev =
                          wed.lyRev +
                          (item?.LastYear?.[0]?.REV
                            ? item?.LastYear?.[0]?.REV
                            : 0);
                        wed.stlyRms =
                          wed.stlyRms +
                          (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                        wed.stlyAdr =
                          wed.stlyAdr +
                          (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                        wed.stlyAdrCount =
                          wed.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                        wed.stlyRev =
                          wed.stlyRms +
                          (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      }
                      if (
                        moment(new Date(item?.Date), "l").format("ddd") ===
                        "Thu"
                      ) {
                        thu.lefttoSell =
                          thu.lefttoSell +
                          (item?.OnTheBook[0]?.LeftToSell
                            ? item?.OnTheBook[0]?.LeftToSell
                            : 0);
                        thu.otb =
                          thu.otb +
                          (item?.OnTheBook[0]?.OnTheBook
                            ? item?.OnTheBook[0]?.OnTheBook
                            : 0);
                        thu.occPercentage =
                          thu.occPercentage +
                          (item?.OnTheBook[0]?.TotalOCCPercentage
                            ? item?.OnTheBook[0]?.TotalOCCPercentage
                            : 0);
                        thu.adr =
                          thu.adr +
                          (item?.OnTheBook[0]?.ADR
                            ? item?.OnTheBook[0]?.ADR
                            : 0);
                        thu.adrCount =
                          thu.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                        thu.rev =
                          thu.rev +
                          (item?.OnTheBook[0]?.REV
                            ? item?.OnTheBook[0]?.REV
                            : 0);
                        thu.sevenRms =
                          thu.sevenRms +
                          (item?.["7 Day Pickup"]?.[0]?.RMS
                            ? item?.["7 Day Pickup"]?.[0]?.RMS
                            : 0);
                        thu.sevenAdr =
                          thu.sevenAdr +
                          (item?.["7 Day Pickup"]?.[0]?.ADR
                            ? item?.["7 Day Pickup"]?.[0]?.ADR
                            : 0);
                        thu.sevenAdrCount =
                          thu.sevenAdrCount +
                          (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        thu.sevenRev =
                          thu.sevenRev +
                          (item?.["7 Day Pickup"]?.[0]?.REV
                            ? item?.["7 Day Pickup"]?.[0]?.REV
                            : 0);
                        thu.fourteenRms =
                          thu.fourteenRms +
                          (item?.["14 Day Pickup"]?.[0]?.RMS
                            ? item?.["14 Day Pickup"]?.[0]?.RMS
                            : 0);
                        thu.fourteenAdr =
                          thu.fourteenAdr +
                          (item?.["14 Day Pickup"]?.[0]?.ADR
                            ? item?.["14 Day Pickup"]?.[0]?.ADR
                            : 0);
                        thu.fourteenAdrCount =
                          thu.fourteenAdrCount +
                          (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        thu.fourteenRev =
                          thu.fourteenRev +
                          (item?.["14 Day Pickup"]?.[0]?.REV
                            ? item?.["14 Day Pickup"]?.[0]?.REV
                            : 0);
                        thu.lyRms =
                          thu.lyRms +
                          (item?.LastYear?.[0]?.RMS
                            ? item?.LastYear?.[0]?.RMS
                            : 0);
                        thu.lyAdr =
                          thu.lyAdr +
                          (item?.LastYear?.[0]?.ADR
                            ? item?.LastYear?.[0]?.ADR
                            : 0);
                        thu.lyAdrCount =
                          thu.lyAdrCount +
                          (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                        thu.lyRev =
                          thu.lyRev +
                          (item?.LastYear?.[0]?.REV
                            ? item?.LastYear?.[0]?.REV
                            : 0);
                        thu.stlyRms =
                          thu.stlyRms +
                          (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                        thu.stlyAdr =
                          thu.stlyAdr +
                          (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                        thu.stlyAdrCount =
                          thu.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                        thu.stlyRev =
                          thu.stlyRms +
                          (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      }
                      if (
                        moment(new Date(item?.Date), "l").format("ddd") ===
                        "Fri"
                      ) {
                        fri.lefttoSell =
                          fri.lefttoSell +
                          (item?.OnTheBook[0]?.LeftToSell
                            ? item?.OnTheBook[0]?.LeftToSell
                            : 0);
                        fri.otb =
                          fri.otb +
                          (item?.OnTheBook[0]?.OnTheBook
                            ? item?.OnTheBook[0]?.OnTheBook
                            : 0);
                        fri.occPercentage =
                          fri.occPercentage +
                          (item?.OnTheBook[0]?.TotalOCCPercentage
                            ? item?.OnTheBook[0]?.TotalOCCPercentage
                            : 0);
                        fri.adr =
                          fri.adr +
                          (item?.OnTheBook[0]?.ADR
                            ? item?.OnTheBook[0]?.ADR
                            : 0);
                        fri.adrCount =
                          fri.adrCount + (item?.OnTheBook[0]?.ADR > 0 ? 1 : 0);
                        fri.rev =
                          fri.rev +
                          (item?.OnTheBook[0]?.REV
                            ? item?.OnTheBook[0]?.REV
                            : 0);
                        fri.sevenRms =
                          fri.sevenRms +
                          (item?.["7 Day Pickup"]?.[0]?.RMS
                            ? item?.["7 Day Pickup"]?.[0]?.RMS
                            : 0);
                        fri.sevenAdr =
                          fri.sevenAdr +
                          (item?.["7 Day Pickup"]?.[0]?.ADR
                            ? item?.["7 Day Pickup"]?.[0]?.ADR
                            : 0);
                        fri.sevenAdrCount =
                          fri.sevenAdrCount +
                          (item?.["7 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        fri.sevenRev =
                          fri.sevenRev +
                          (item?.["7 Day Pickup"]?.[0]?.REV
                            ? item?.["7 Day Pickup"]?.[0]?.REV
                            : 0);
                        fri.fourteenRms =
                          fri.fourteenRms +
                          (item?.["14 Day Pickup"]?.[0]?.RMS
                            ? item?.["14 Day Pickup"]?.[0]?.RMS
                            : 0);
                        fri.fourteenAdr =
                          fri.fourteenAdr +
                          (item?.["14 Day Pickup"]?.[0]?.ADR
                            ? item?.["14 Day Pickup"]?.[0]?.ADR
                            : 0);
                        fri.fourteenAdrCount =
                          fri.fourteenAdrCount +
                          (item?.["14 Day Pickup"]?.[0]?.ADR > 0 ? 1 : 0);
                        fri.fourteenRev =
                          fri.fourteenRev +
                          (item?.["14 Day Pickup"]?.[0]?.REV
                            ? item?.["14 Day Pickup"]?.[0]?.REV
                            : 0);
                        fri.lyRms =
                          fri.lyRms +
                          (item?.LastYear?.[0]?.RMS
                            ? item?.LastYear?.[0]?.RMS
                            : 0);
                        fri.lyAdr =
                          fri.lyAdr +
                          (item?.LastYear?.[0]?.ADR
                            ? item?.LastYear?.[0]?.ADR
                            : 0);
                        fri.lyAdrCount =
                          fri.lyAdrCount +
                          (item?.LastYear?.[0]?.ADR > 0 ? 1 : 0);
                        fri.lyRev =
                          fri.lyRev +
                          (item?.LastYear?.[0]?.REV
                            ? item?.LastYear?.[0]?.REV
                            : 0);
                        fri.stlyRms =
                          fri.stlyRms +
                          (item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0);
                        fri.stlyAdr =
                          fri.stlyAdr +
                          (item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0);
                        fri.stlyAdrCount =
                          fri.stlyRms + (item?.STLY?.[0]?.ADR > 0 ? 1 : 0);
                        fri.stlyRev =
                          fri.stlyRms +
                          (item?.STLY?.[0]?.REV ? item?.STLY?.[0]?.REV : 0);
                      }
                    }

                    let dayName = false;

                    if (
                      moment(new Date(item?.Date), "l").format("ddd") ===
                        "Fri" ||
                      moment(new Date(item?.Date), "l").format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    return (
                      <>
                        <tr
                          key={item?.Date}
                          className={`shadowOnHover ${
                            dayName ? "backgroundTr" : ""
                          }`}
                        >
                          <td className="border_right">
                            {item?.Date
                              ? commonService.getDateInFormat(item?.Date)
                              : ""}{" "}
                            {moment(new Date(item?.Date), "l").format("ddd")}
                          </td>
                          <td
                            className="border_right text-center text-primary cursorPointer"
                            title={item?.titles}
                            onClick={() => {
                              selectedEventDateRef.current = item?.Date;
                              eventListPropsRef.current = checkEvent;
                              setShowTicketModal(true);
                            }}
                          >
                            {item?.titles?.length > 20
                              ? `${item?.titles?.slice(0, 20)}...`
                              : item?.titles}
                          </td>
                          <td className="text-center">
                            {item?.OnTheBook[0]?.LeftToSell
                              ? commonService.formateRoom(
                                  item?.OnTheBook[0]?.LeftToSell
                                )
                              : 0}
                          </td>
                          <td
                            className="perbackground perbackground-otb text-primary text-center"
                            value={
                              item?.OnTheBook[0]?.OnTheBook
                                ? item?.OnTheBook[0]?.OnTheBook
                                : 0
                            }
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                singleDateSelectedDateRef.current = item?.Date;
                                handleFilterBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {item?.OnTheBook[0]?.OnTheBook
                                ? commonService.formateRoom(
                                    item?.OnTheBook[0]?.OnTheBook
                                  )
                                : 0}
                            </span>
                          </td>
                          <td className="text-center">
                            {item?.OnTheBook[0]?.TotalOCCPercentage
                              ? item?.OnTheBook[0]?.TotalOCCPercentage
                              : 0}
                            %
                          </td>
                          <td className="text-end">
                            {item?.OnTheBook[0]?.ADR
                              ? commonService.formateAmount(
                                  item?.OnTheBook[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="border_right text-end">
                            {item?.OnTheBook[0]?.REV
                              ? commonService.formateAmount(
                                  item?.OnTheBook[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className="perbackground perbackground-7DayRms"
                            value={
                              item?.["7 Day Pickup"]?.[0]?.RMS
                                ? item?.["7 Day Pickup"]?.[0]?.RMS
                                : 0
                            }
                          >
                            {item?.["7 Day Pickup"]?.[0]?.RMS
                              ? commonService.formateRoom(
                                  item?.["7 Day Pickup"]?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td
                            className="perbackground perbackground-7DayAdr text-end"
                            value={
                              item?.["7 Day Pickup"]?.[0]?.ADR
                                ? item?.["7 Day Pickup"]?.[0]?.ADR
                                : 0
                            }
                          >
                            {item?.["7 Day Pickup"]?.[0]?.ADR
                              ? commonService.formateAmount(
                                  item?.["7 Day Pickup"]?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="border_right text-end">
                            {item?.["7 Day Pickup"]?.[0]?.REV
                              ? commonService.formateAmount(
                                  item?.["7 Day Pickup"]?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className="perbackground perbackground-14DayRms"
                            value={
                              item?.["14 Day Pickup"]?.[0]?.RMS
                                ? item?.["14 Day Pickup"]?.[0]?.RMS
                                : 0
                            }
                          >
                            {item?.["14 Day Pickup"]?.[0]?.RMS
                              ? commonService.formateRoom(
                                  item?.["14 Day Pickup"]?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td
                            className="perbackground perbackground-14DayAdr text-end"
                            value={
                              item?.["14 Day Pickup"]?.[0]?.ADR
                                ? item?.["14 Day Pickup"]?.[0]?.ADR
                                : 0
                            }
                          >
                            {item?.["14 Day Pickup"]?.[0]?.ADR
                              ? commonService.formateAmount(
                                  item?.["14 Day Pickup"]?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="border_right text-end">
                            {item?.["14 Day Pickup"]?.[0]?.REV
                              ? commonService.formateAmount(
                                  item?.["14 Day Pickup"]?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="border_right text-center">
                            {item?.Forecast?.[0]?.RMS
                              ? commonService.formateRoom(
                                  item?.Forecast?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td
                            className={`text-end ${
                              isSelfData?.[0]?.Rate < avgCompetitor
                                ? "greenText"
                                : "redText"
                            }`}
                          >
                            {isSelfData?.[0]?.Rate
                              ? commonService.formateAmount(
                                  isSelfData?.[0]?.Rate
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {commonService.formateAmount(avgCompetitor)}
                          </td>
                          {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                            if (x.IsSelf === false) {
                              return (
                                <td
                                  className={`text-end ${
                                    eventStretegyData?.[0]?.Rateshop?.length -
                                      1 ===
                                    i
                                      ? "border_right"
                                      : ""
                                  }`}
                                  title={x?.competiterpropertyname}
                                >
                                  {x?.Rate
                                    ? commonService.formateAmount(x?.Rate)
                                    : commonService.formateAmount(0)}
                                </td>
                              );
                            }
                          })}
                          <td
                            className="perbackground perbackground-LyRms"
                            value={
                              item?.LastYear?.[0]?.RMS
                                ? item?.LastYear?.[0]?.RMS
                                : 0
                            }
                          >
                            {item?.LastYear?.[0]?.RMS
                              ? commonService.formateRoom(
                                  item?.LastYear?.[0]?.RMS
                                )
                              : 0}
                          </td>
                          <td
                            className="perbackground perbackground-LyAdr text-end"
                            value={
                              item?.LastYear?.[0]?.ADR
                                ? item?.LastYear?.[0]?.ADR
                                : 0
                            }
                          >
                            {item?.LastYear?.[0]?.ADR
                              ? commonService.formateAmount(
                                  item?.LastYear?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="border_right text-end">
                            {item?.LastYear?.[0]?.REV
                              ? commonService.formateAmount(
                                  item?.LastYear?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td
                            className="perbackground perbackground-StlyRms"
                            value={
                              item?.STLY?.[0]?.RMS ? item?.STLY?.[0]?.RMS : 0
                            }
                          >
                            {item?.STLY?.[0]?.RMS
                              ? commonService.formateRoom(item?.STLY?.[0]?.RMS)
                              : 0}
                          </td>
                          <td
                            className="perbackground perbackground-StlyAdr text-end"
                            value={
                              item?.STLY?.[0]?.ADR ? item?.STLY?.[0]?.ADR : 0
                            }
                          >
                            {item?.STLY?.[0]?.ADR
                              ? commonService.formateAmount(
                                  item?.STLY?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {item?.STLY?.[0]?.REV
                              ? commonService.formateAmount(
                                  item?.STLY?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                        </tr>
                        {eventStretegyData?.length - 1 === index && (
                          <>
                            {/* WEEKDAY */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">WeekDay</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDay?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDay?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {weekDay?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {weekDay.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekDay?.adr / weekDay.adrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(weekDay?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(weekDay?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDay.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekDay?.sevenAdr /
                                          weekDay.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekDay?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(
                                  weekDay?.fourteenRms
                                )}
                              </td>
                              <td className="boldTd text-end">
                                {weekDay.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekDay?.fourteenAdr /
                                          weekDay.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(
                                  weekDay?.fourteenRev
                                )}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(weekDay?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDay?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekDay?.lyAdr / weekDay?.lyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(weekDay?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(weekDay?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDay?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekDay?.stlyAdr / weekDay?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(weekDay?.stlyRev)}
                              </td>
                            </tr>

                            {/* WEEKEND */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">WeekEnd</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEnd?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEnd?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {weekEnd?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {weekEnd.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekEnd?.adr / weekEnd.adrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(weekEnd?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(weekEnd?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEnd.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekEnd?.sevenAdr /
                                          weekEnd.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekEnd?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(
                                  weekEnd?.fourteenRms
                                )}
                              </td>
                              <td className="boldTd text-end">
                                {weekEnd.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekEnd?.fourteenAdr /
                                          weekEnd.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(
                                  weekEnd?.fourteenRev
                                )}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(weekEnd?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEnd?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekEnd?.lyAdr / weekEnd?.lyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(weekEnd?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(weekEnd?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEnd?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekEnd?.stlyAdr / weekEnd?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(weekEnd?.stlyRev)}
                              </td>
                            </tr>

                            {/* SUN */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Sun</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(sun?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(sun?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {sun?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {sun.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(sun?.adr / sun.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(sun?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(sun?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sun.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        sun?.sevenAdr / sun.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(sun?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(sun?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sun.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        sun?.fourteenAdr / sun.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(sun?.fourteenRev)}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(sun?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sun?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(sun?.lyAdr / sun?.lyAdrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(sun?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(sun?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sun?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        sun?.stlyAdr / sun?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(sun?.stlyRev)}
                              </td>
                            </tr>

                            {/* MON */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Mon</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(mon?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(mon?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {mon?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {mon.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(mon?.adr / mon.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(mon?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(mon?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {mon.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        mon?.sevenAdr / mon.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(mon?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(mon?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {mon.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        mon?.fourteenAdr / mon.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(mon?.fourteenRev)}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(mon?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {mon?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(mon?.lyAdr / mon?.lyAdrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(mon?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(mon?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {mon?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        mon?.stlyAdr / mon?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(mon?.stlyRev)}
                              </td>
                            </tr>

                            {/* TUE */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Tue</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(tue?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(tue?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {tue?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {tue.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(tue?.adr / tue.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(tue?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(tue?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {tue.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        tue?.sevenAdr / tue.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(tue?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(tue?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {tue.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        tue?.fourteenAdr / tue.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(tue?.fourteenRev)}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(tue?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {tue?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(tue?.lyAdr / tue?.lyAdrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(tue?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(tue?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {tue?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        tue?.stlyAdr / tue?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(tue?.stlyRev)}
                              </td>
                            </tr>

                            {/* WED */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Wed</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(wed?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(wed?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {wed?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {wed.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(wed?.adr / wed.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(wed?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(wed?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {wed.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        wed?.sevenAdr / wed.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(wed?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(wed?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {wed.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        wed?.fourteenAdr / wed.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(wed?.fourteenRev)}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(wed?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {wed?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(wed?.lyAdr / wed?.lyAdrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(wed?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(wed?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {wed?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        wed?.stlyAdr / wed?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(wed?.stlyRev)}
                              </td>
                            </tr>

                            {/* THU */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Thu</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(thu?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(thu?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {thu?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {thu.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(thu?.adr / thu.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(thu?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(thu?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {thu.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        thu?.sevenAdr / thu.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(thu?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(thu?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {thu.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        thu?.fourteenAdr / thu.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(thu?.fourteenRev)}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(thu?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {thu?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(thu?.lyAdr / thu?.lyAdrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(thu?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(thu?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {thu?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        thu?.stlyAdr / thu?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(thu?.stlyRev)}
                              </td>
                            </tr>

                            {/* FRI */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Fri</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(fri?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(fri?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {fri?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {fri.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(fri?.adr / fri.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(fri?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(fri?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {fri.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        fri?.sevenAdr / fri.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(fri?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(fri?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {fri.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        fri?.fourteenAdr / fri.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(fri?.fourteenRev)}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(fri?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {fri?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(fri?.lyAdr / fri?.lyAdrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(fri?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(fri?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {fri?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        fri?.stlyAdr / fri?.stlyAdrCount
                                      )
                                    )
                                  : 0}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(fri?.stlyRev)}
                              </td>
                            </tr>

                            {/* SAT */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Sat</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(sat?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(sat?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {sat?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {sat.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(sat?.adr / sat.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(sat?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(sat?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sat.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        sat?.sevenAdr / sat.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(sat?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(sat?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sat.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        sat?.fourteenAdr / sat.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(sat?.fourteenRev)}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(sat?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sat?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(sat?.lyAdr / sat?.lyAdrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(sat?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(sat?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {sat?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        sat?.stlyAdr / sat?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(sat?.stlyRev)}
                              </td>
                            </tr>

                            {/* TOTAL */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Total</td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(total?.lefttoSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(total?.otb)}
                              </td>
                              <td className="boldTd text-center">
                                {total?.occPercentage}%
                              </td>
                              <td className="boldTd text-end">
                                {total.adrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(total?.adr / total.adrCount)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(total?.rev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(total?.sevenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {total.sevenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        total?.sevenAdr / total.sevenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(total?.sevenRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(total?.fourteenRms)}
                              </td>
                              <td className="boldTd text-end">
                                {total.fourteenAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        total?.fourteenAdr /
                                          total.fourteenAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(
                                  total?.fourteenRev
                                )}
                              </td>
                              <td className="boldTd border_right"></td>
                              <td className="boldTd"></td>
                              <td className="boldTd"></td>
                              {eventStretegyData?.[0]?.Rateshop?.map((x, i) => {
                                if (x.IsSelf === false) {
                                  return (
                                    <td
                                      className={`boldTd ${
                                        eventStretegyData?.[0]?.Rateshop
                                          ?.length -
                                          1 ===
                                        i
                                          ? "border_right"
                                          : ""
                                      }`}
                                    ></td>
                                  );
                                }
                              })}
                              <td className="boldTd">
                                {commonService.formateRoom(total?.lyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {total?.lyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        total?.lyAdr / total?.lyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(total?.lyRev)}
                              </td>
                              <td className="boldTd">
                                {commonService.formateRoom(total?.stlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {total?.stlyAdrCount > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        total?.stlyAdr / total?.stlyAdrCount
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd border_right text-end">
                                {commonService.formateAmount(total?.stlyRev)}
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={50} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Overlay
          show={ONTHEBOOKS.IsDisplay}
          target={ONTHEBOOKS.Target}
          placement={ONTHEBOOKS.Position}
        >
          <Popover
            id="popover-contained"
            className="SingleDateSummaryModalPopoverMain"
            style={{ zIndex: 999999 }}
          >
            <div className="horizontal-menu-option active">
              <SingleDateSummaryModal
                closeModal={closeSingleDate}
                asOfDate={para?.asOfDate}
                selectedDate={singleDateSelectedDateRef.current}
                pickupDate={sevenDayPickupRef.current}
                propertyDetail={{ propertyCode: para?.propertyCode }}
              />
            </div>
          </Popover>
        </Overlay>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"EventStretegy"} />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventStretegyData}
        />
      )}
    </div>
  );
};

export default EventStretegy;
