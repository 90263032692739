import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import {
    Button,
    Col,
    Form,
    Overlay,
    Popover,
    Row,
    Table,
} from "react-bootstrap";
import { dataTable } from "../../../../utils/dataTable";
import { commonService } from "../../../../utils/commonService";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import lowPrio from "../../../../Assets/Images/Revenue-management/lowPriority.svg";
import highPrio from "../../../../Assets/Images/Revenue-management/highPriority.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import FilterModal from "./FilterModal";

const ReservationActivity = ({
    setCompDataFromChild,
    rowIndex,
    colIndex,
    api,
    para,
    setting,
}) => {
    const tableData = [
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
        {
            propertyCode: "",
            AsOfDate: "2023-08-16",
            RateCode: "GROUP~",
            RateCodeDescription: "0",
            Company: "No Name",
            GroupName: "Comfirt",
            lata: "",
            GuestName: "BNSF, 4",
            ConfirmationNumber: "",
            ArrivalDate: "2023-08-20",
            LOS: "",
            RoomNight: 5,
            Status: "R",
            RateOfTheDay: "",
            Rate: "",
            Source: "DIRECT",
            RoomType: "NDD",
            Channel: "Yatra",
            User: "jyount.mo215",
            BookingDate: "2023-08-15",
        },
    ];

    const [reservationData, setReservationData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [widgetModal, setWidgetModal] = useState(false);
    const widgetDetailRef = useRef();
    const [showNoteSidebar, setShowNoteSidebar] = useState(false);
    const [ReservationRateTitle, setReservationRateTitle] = useState(null);

    const [threshold_rulevalues, setthreshold_rulevalues] = useState(null);

    const propertyDetailFromRTK = useSelector(
        (store) => store.headerProperty.propertyDetail
    );
    const defaultAsOfDateFromRTK = useSelector(
        (store) => store.headerProperty.defaultAsOfDate
    );
    const { snapshottitle, propertyCode, snapshotId } = useParams();
    const [stayStartDate, setStayStartDate] = useState(
        new Date().setDate(new Date().getDate() - 3)
    );
    const [stayEndDate, setStayEndDate] = useState(new Date());

    const [bookingStartDate, setBookingStartDate] = useState("");
    const [bookingEndDate, setBookingEndDate] = useState("");
    const [error, setError] = useState({
        arrivalDate: "",
        bookingDate: "",
    });
    const [tblids, settblids] = useState({
        tblreservationActivitySnapShotTable: commonService.GenerateGUID(),
    });
    const sortingRef = useRef({
        column: "",
        order: "",
    });
    const [filterModal, setFilterModal] = useState({
        IsDisplay: false,
        Target: null,
        Position: "top",
    });
    const allFilterDataRef = useRef();
    const allSelectedDataRef = useRef();
    const selectedFilterRef = useRef();

    const closeNoteSideBar = () => {
        setShowNoteSidebar(false);
    };

    const closeWidgetModal = () => {
        setWidgetModal(false);
    };

    const findDiffRate = (adr, rate, min, max) => {
        let diff = ((rate - adr) / adr) * 100;
        if (diff < 0) {
            diff = -diff;
            if (min < diff) {
                return {
                    status: 1,
                    diff: diff,
                };
            }
        }
        if (diff > 0) {
            if (max < diff) {
                return {
                    status: 2,
                    diff: diff,
                };
            } else {
                return 0;
            }
        }
    };

    const handleStayDateChange = (dates) => {
        const [start, end] = dates;
        setStayStartDate(start);
        setStayEndDate(end ? end : "");
    };

    const handleBookingDateChange = (dates) => {
        const [start, end] = dates;
        setBookingStartDate(start);
        setBookingEndDate(end ? end : "");
    };

    const handleFilterBtnClick = (event) => {
        let Position = "bottom";
        let Top = event.nativeEvent.layerY - 2;
        if (Top + 400 > window.innerHeight) {
            Position = "top";
        }
        setFilterModal({
            IsDisplay: true,
            Target: event.target,
            Position: Position,
        });
    };

    const closeFilterModal = () => {
        setFilterModal({
            IsDisplay: false,
            Target: null,
            Position: "bottom",
        });
        selectedFilterRef.current = "";
    };

    const getReservationData = async () => {
        setReservationRateTitle(null);
        setthreshold_rulevalues(null)
        setLoading(true);
        let reservationDataResponse = await apiCall(
            {
                method: "POST",
                url: API_URL.BASE_API_URL.SnapShot + API_URL.RESERVATION_ACTIVITY,
                body: {
                    // AsOfDate: para?.asOfDate,
                    // year: `${new Date().getFullYear()}`,
                    // propertyCode: para?.propertyCode,

                    AsOfDate: para?.asOfDate,
                    BookingstartDate: bookingStartDate
                        ? commonService.getDateForNode(bookingStartDate)
                        : "",
                    BookingendDate: bookingEndDate
                        ? commonService.getDateForNode(bookingEndDate)
                        : "",
                    ArrivalstartDate: stayStartDate
                        ? commonService.getDateForNode(stayStartDate)
                        : "",
                    ArrivalendDate: stayEndDate
                        ? commonService.getDateForNode(stayEndDate)
                        : "",
                    propertyCode: para?.propertyCode,
                },
            },
            false
        );

        let response = apiResponse(false, reservationDataResponse);

        if (response?.isValidate) {
            let groupingJson = {
                rateCode: {},
                company: {},
                combinedChannel: {},
                status: {},
            };
            setReservationRateTitle({
                min: JSON.parse(response?.data?.data?.[0].rulevalues)?.[0]?.min,
                max: JSON.parse(response?.data?.data?.[0].rulevalues)?.[0]?.max,
            });

            var _threshold_rulevalues = {
                min: 0,
                max: 0,
            };

            if (response?.data?.data?.[0]?.threshold_rulevalues) {
                _threshold_rulevalues.min = parseFloat(JSON.parse(response?.data?.data?.[0].threshold_rulevalues)?.[0]?.min);
                _threshold_rulevalues.max = parseFloat(JSON.parse(response?.data?.data?.[0].threshold_rulevalues)?.[0]?.max);
            }

            setthreshold_rulevalues(_threshold_rulevalues)


            const handleObj = (key, value) => {
                if (!groupingJson[key][value] && value) {
                    groupingJson[key][value?.trim()] = value?.trim();
                }
            };

            response?.data?.data?.forEach((item) => {
                let status = "";
                if (item?.Status === "R") {
                    status = "reserved";
                } else if (item?.Status === "I") {
                    status = "in house";
                } else if (item?.Status === "O") {
                    status = "checked out";
                } else if (item?.Status === "C") {
                    status = "cancelled";
                } else if (item?.Status === "N") {
                    status = "no show";
                }
                handleObj("rateCode", item?.RateCode);
                handleObj("company", item?.Company);
                handleObj("combinedChannel", item?.CombinedChannel);
                if (status) {
                    handleObj("status", status);
                }
            });

            allFilterDataRef.current = { ...groupingJson };
            allSelectedDataRef.current = { ...groupingJson };

            setReservationData(
                response?.data?.data.map((d) => ({
                    ...d,
                    RateStatus: findDiffRate(
                        d.ADR,
                        d.Rate,
                        JSON.parse(d?.rulevalues)?.[0]?.min,
                        JSON.parse(d?.rulevalues)?.[0]?.max
                    ),
                    NetRateStatus: _threshold_rulevalues.min > 0 ? Math.round(d?.NetRate ? d?.NetRate : 0) >= _threshold_rulevalues.min ? 2 : 1 : 0,
                }))
            );
            setLoading(false);
        }

        if (!response?.isValidate) {
            setReservationData(null);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (api === 0) {
            setReservationData(tableData);
        } else {
            if (para?.asOfDate) {
                if (stayEndDate) {
                    getReservationData();
                }
            }
        }
    }, [para?.asOfDate]);

    // useEffect(() => {
    //   if (api === 0) {
    //     setReservationData(tableData);
    //   } else {
    //     if (para?.asOfDate) {
    //       if (bookingEndDate) {
    //         getReservationData();
    //       }
    //     }
    //   }
    // }, [bookingEndDate]);

    const handleSearch = () => {
        let rateCodeSearchInput = document.getElementById("tblRateCode");
        let ratePlanDescriptionSearchInput =
            document.getElementById("tblRatePlanDesc");
        let companySearchInput = document.getElementById("tblCompany");
        let groupNameSearchInput = document.getElementById("tblGroupName");
        let guestNameSearchInput = document.getElementById("tblGuestName");
        let confirmationNumberSearchInput = document.getElementById(
            "tblConfirmationNumber"
        );
        let LOSSearchInput = document.getElementById("tblLOS");
        let statusSearchInput = document.getElementById("tblStatus");
        let adrSearchInput = document.getElementById("tblAdr");
        let rateSearchInput = document.getElementById("tblRate");
        let roomTypeSearchInput = document.getElementById("tblRoomType");
        let channelSearchInput = document.getElementById("tblChannel");
        let sourceSearchInput = document.getElementById("tblSource");

        let table = document.getElementById(
            `${tblids.tblreservationActivitySnapShotTable}`
        );
        let tr = table?.getElementsByTagName("tr");

        for (let i = 0; i < tr?.length; i++) {
            let td = tr[i]?.getElementsByTagName("td");

            if (td?.length > 0) {
                let rateCodeTxtValue = td?.[1]?.innerText || "";
                let ratePlanDescriptionTxtValue = td?.[2]?.innerText || "";
                let companyTxtValue = td?.[3]?.innerText || "";
                let groupNameTxtValue = td?.[4]?.innerText || "";
                let guestNameTxtValue = td?.[5]?.innerText || "";
                let confirmationNumberTxtValue = td?.[6]?.innerText || "";
                let LOSTxtValue = td?.[8]?.innerText || "";
                let statusTxtValue = td?.[9]?.innerText || "";
                let adrTxtValue = td?.[10]?.innerText || "";
                let rateTxtValue = td?.[11]?.innerText || "";
                let roomTypeTxtValue = td?.[12]?.innerText || "";
                let channelTxtValue = td?.[13]?.innerText || "";
                let sourceTxtValue = td?.[14]?.innerText || "";

                const checkFilter = () => {
                    let styleForTr = "";

                    if (
                        rateCodeTxtValue
                            .toLowerCase()
                            .includes(rateCodeSearchInput?.value?.toLowerCase()) &&
                        // allSelectedDataRef.current?.rateCode?.[rateCodeTxtValue] &&
                        ratePlanDescriptionTxtValue
                            .toLowerCase()
                            .includes(ratePlanDescriptionSearchInput?.value?.toLowerCase()) &&
                        companyTxtValue
                            .toLowerCase()
                            .includes(companySearchInput?.value?.toLowerCase()) &&
                        // allSelectedDataRef.current?.company?.[companyTxtValue] &&
                        groupNameTxtValue
                            .toLowerCase()
                            .includes(groupNameSearchInput?.value?.toLowerCase()) &&
                        guestNameTxtValue
                            .toLowerCase()
                            .includes(guestNameSearchInput?.value?.toLowerCase()) &&
                        confirmationNumberTxtValue
                            .toLowerCase()
                            .includes(confirmationNumberSearchInput?.value?.toLowerCase()) &&
                        statusTxtValue
                            .toLowerCase()
                            .includes(statusSearchInput?.value?.toLowerCase()) &&
                        // allSelectedDataRef.current?.status?.[statusTxtValue] &&
                        roomTypeTxtValue
                            .toLowerCase()
                            .includes(roomTypeSearchInput?.value?.toLowerCase()) &&
                        channelTxtValue
                            .toLowerCase()
                            .includes(channelSearchInput?.value?.toLowerCase()) &&
                        // allSelectedDataRef.current?.combinedChannel?.[channelTxtValue] &&
                        sourceTxtValue
                            .toLowerCase()
                            .includes(sourceSearchInput?.value?.toLowerCase())
                    ) {
                        styleForTr = "";
                    } else {
                        styleForTr = "none";
                    }

                    let rateCodeFilter = Object.keys(
                        allSelectedDataRef.current?.rateCode
                    );
                    if (rateCodeFilter?.length > 0) {
                        if (!rateCodeTxtValue && styleForTr === "") {
                        } else {
                            if (
                                allSelectedDataRef.current?.rateCode?.[rateCodeTxtValue] &&
                                styleForTr === ""
                            ) {
                                styleForTr = "";
                            } else {
                                styleForTr = "none";
                            }
                        }
                    }

                    let companyFilter = Object.keys(allSelectedDataRef.current?.company);
                    if (companyFilter?.length > 0) {
                        if (!companyTxtValue && styleForTr === "") {
                        } else {
                            if (
                                allSelectedDataRef.current?.company?.[companyTxtValue] &&
                                styleForTr === ""
                            ) {
                                styleForTr = "";
                            } else {
                                styleForTr = "none";
                            }
                        }
                    }

                    let statusFilter = Object.keys(allSelectedDataRef.current?.status);
                    if (statusFilter?.length > 0) {
                        if (!statusTxtValue && styleForTr === "") {
                        } else {
                            if (
                                allSelectedDataRef.current?.status?.[statusTxtValue] &&
                                styleForTr === ""
                            ) {
                                styleForTr = "";
                            } else {
                                styleForTr = "none";
                            }
                        }
                    }

                    let channelFilter = Object.keys(
                        allSelectedDataRef.current?.combinedChannel
                    );
                    if (channelFilter?.length > 0) {
                        if (!channelTxtValue && styleForTr === "") {
                        } else {
                            if (
                                allSelectedDataRef.current?.combinedChannel?.[
                                channelTxtValue
                                ] &&
                                styleForTr === ""
                            ) {
                                styleForTr = "";
                            } else {
                                styleForTr = "none";
                            }
                        }
                    }
                    return styleForTr;
                };

                tr[i].style.display = checkFilter();
            }
        }
    };

    const handleSort = (n, trClassName) => {
        var table,
            rows,
            switching,
            i,
            x,
            y,
            shouldSwitch,
            dir,
            switchcount = 0;
        table = document.getElementById(
            `${tblids.tblreservationActivitySnapShotTable}`
        );
        switching = true;
        dir = sortingRef.current.order;
        while (switching) {
            switching = false;
            rows = table.rows;

            for (i = 3; i < rows.length - 1; i++) {
                shouldSwitch = false;
                x = rows[i].getElementsByTagName("TD")[n]?.innerHTML || "";
                y = rows[i + 1].getElementsByTagName("TD")[n]?.innerHTML || "";

                if (n === 11) {
                    if (x?.split(">")?.[1]) {
                        x = x.split(">")[1];
                    }

                    if (y?.split(">")?.[1]) {
                        y = y.split(">")[1];
                    }
                }
                x = x?.replace("$", "");
                y = y?.replace("$", "");

                if (dir == "asc") {
                    if (parseInt(x) > parseInt(y)) {
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (parseInt(x) < parseInt(y)) {
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                switchcount++;
            } else {
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }

        let upIconArr = document.querySelectorAll(".up-arrow-icon");
        for (let j = 0; j < upIconArr?.length; j++) {
            upIconArr?.[j]?.setAttribute("fill", "#DFDFDF");
        }

        let downIconArr = document.querySelectorAll(".down-arrow-icon");
        for (let j = 0; j < upIconArr?.length; j++) {
            downIconArr?.[j]?.setAttribute("fill", "#DFDFDF");
        }

        let upIcon = document.querySelectorAll(`.${trClassName} .up-arrow-icon`);
        let downIcon = document.querySelectorAll(
            `.${trClassName} .down-arrow-icon`
        );

        if (sortingRef.current.order === "asc") {
            upIcon?.[0].setAttribute("fill", "#000000");
            downIcon?.[0].setAttribute("fill", "#DFDFDF");
        } else {
            downIcon?.[0].setAttribute("fill", "#000000");
            upIcon?.[0].setAttribute("fill", "#DFDFDF");
        }
    };

    const updateFilterModal = (key, value) => {
        allSelectedDataRef.current[key] = value;
        closeFilterModal();

        handleSearch();
    };

    return (
        <div className="widget-container">
            <Row>
                <div className="d-flex align-items-start gap-4 conditional-class mt-3">
                    <div className="form-groups from-groups no-border-break meghaDatePicker width225">
                        <Form.Label>Arrival Date</Form.Label>
                        <DatePicker
                            onChange={handleStayDateChange}
                            startDate={stayStartDate}
                            endDate={stayEndDate}
                            selectsRange
                            monthsShown={2}
                        />
                        {error?.arrivalDate && (
                            <small className="text-danger">{error?.arrivalDate}</small>
                        )}
                    </div>
                    <div className="form-groups from-groups no-border-break meghaDatePicker width225">
                        <Form.Label>Booking Date</Form.Label>
                        <DatePicker
                            onChange={handleBookingDateChange}
                            startDate={bookingStartDate}
                            endDate={bookingEndDate}
                            selectsRange
                            monthsShown={2}
                        />
                        {error?.bookingDate && (
                            <small className="text-danger">{error?.bookingDate}</small>
                        )}
                    </div>
                    <div className="marign-condition-btn">
                        <Button
                            className="default-btn primary-btn gobtnwidth"
                            onClick={() => {
                                let arrivalDateError = "",
                                    bookingDateError = "";
                                if (stayStartDate && !stayEndDate) {
                                    arrivalDateError = "Dates are required";
                                }
                                if (bookingStartDate && !bookingEndDate) {
                                    bookingDateError = "Dates are required";
                                }

                                if (arrivalDateError === "" && bookingDateError === "") {
                                    setError({
                                        arrivalDate: "",
                                        bookingDate: "",
                                    });
                                    getReservationData();
                                } else {
                                    setError({
                                        arrivalDate: arrivalDateError,
                                        bookingDate: bookingDateError,
                                    });
                                }
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </Row>
            {api !== 0 && (
                <div className="gap-1 widget-container-menu">
                    <Button className="heading-menu-btns" type="button" title="Send Mail">
                        <img
                            src={emailIcon}
                            alt="emailIcon"
                            onClick={() => {
                                commonService.fnSendWidgetMail(
                                    tblids.tblreservationActivitySnapShotTable,
                                    "Reservation Activity",
                                    snapshotId,
                                    defaultAsOfDateFromRTK,
                                    "",
                                    para?.widgetId
                                );
                            }}
                        />
                    </Button>
                    <Button className="heading-menu-btns" type="button">
                        <img
                            src={infoIcon}
                            alt="info"
                            onClick={() => {
                                widgetDetailRef.current = {
                                    widgetdetails: "",
                                    usernote: "",
                                    compName: compName?.reservationActivity,
                                    widgetId: para?.widgetId,
                                };
                                setWidgetModal(true);
                            }}
                        />
                    </Button>
                    <Button className="heading-menu-btns" type="button">
                        <img
                            src={downloadIcon}
                            alt="menuIcon"
                            import={tblids.tblreservationActivitySnapShotTable}
                            onClick={() =>
                                commonService.fnExportInCSV(
                                    tblids.tblreservationActivitySnapShotTable,
                                    // "Reservation Activity"
                                    `${para?.propertyCode}-Reservation Activity-${moment(
                                        new Date()
                                    ).format("MMDDYYYY")}`
                                )
                            }
                        />
                    </Button>
                </div>
            )}
            <div className="ViewPropertySetupTable mt-2">
                <div className="stripped__table">
                    <div className="table-responsive tblDynamicPickupSticky">
                        <Table id={tblids.tblreservationActivitySnapShotTable}>
                            <thead
                                id="tblreservationActivitySnapShotHead"
                                className="tbl-header-sticky"
                            >
                                <tr>
                                    <th className="text-center" colSpan={16}>
                                        Reservation Activity
                                        <span style={{ marginLeft: "10px", fontSize: "7px" }}>
                                            {`${ReservationRateTitle
                                                ? `(Reservation Rate < ${ReservationRateTitle.min} % or > ${ReservationRateTitle.max} % by Rate of the Day (ADR))`
                                                : ""
                                                }`}
                                        </span>

                                        <span style={{ marginLeft: "10px", fontSize: "7px" }}>
                                            {`${threshold_rulevalues
                                                ? `(Rateplan Threshold $${threshold_rulevalues.min})`
                                                : ""
                                                }`}
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Booking Date</th>
                                    <th className="d-flex justify-content-between align-items-center gap-2">
                                        <p>Rate Code</p>
                                        <svg
                                            onClick={(e) => {
                                                if (selectedFilterRef.current === "rateCode") {
                                                    closeFilterModal();
                                                } else {
                                                    selectedFilterRef.current = "rateCode";
                                                    handleFilterBtnClick(e);
                                                }
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                                                stroke-width="0"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </th>
                                    <th>Rate Plan Description</th>
                                    <th className="d-flex justify-content-between align-items-center gap-2">
                                        <p>Company</p>
                                        <svg
                                            onClick={(e) => {
                                                if (selectedFilterRef.current === "company") {
                                                    closeFilterModal();
                                                } else {
                                                    selectedFilterRef.current = "company";
                                                    handleFilterBtnClick(e);
                                                }
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                                                stroke-width="0"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </th>
                                    <th>Group Name</th>
                                    <th>Guest Name</th>
                                    <th>Confirmation Number</th>
                                    <th>Arrival Date</th>
                                    <th
                                        className="text-center"
                                        onClick={() => {
                                            let orderForSort = "";
                                            if (sortingRef.current.column === 8) {
                                                orderForSort =
                                                    sortingRef.current.order === "asc" ? "desc" : "asc";
                                            } else {
                                                orderForSort = "asc";
                                            }
                                            sortingRef.current = {
                                                column: 8,
                                                order: orderForSort,
                                            };
                                            handleSort(8, "losTr");
                                        }}
                                    >
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                            <p>LOS</p>
                                            <div className="d-flex flex-column losTr">
                                                <svg
                                                    className="up-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
                                                </svg>
                                                <svg
                                                    className="down-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="d-flex justify-content-between align-items-center gap-2">
                                        <p>Status</p>
                                        <svg
                                            onClick={(e) => {
                                                if (selectedFilterRef.current === "status") {
                                                    closeFilterModal();
                                                } else {
                                                    selectedFilterRef.current = "status";
                                                    handleFilterBtnClick(e);
                                                }
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                                                stroke-width="0"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </th>
                                    <th
                                        className="text-end"
                                        onClick={() => {
                                            let orderForSort = "";
                                            if (sortingRef.current.column === 10) {
                                                orderForSort =
                                                    sortingRef.current.order === "asc" ? "desc" : "asc";
                                            } else {
                                                orderForSort = "asc";
                                            }
                                            sortingRef.current = {
                                                column: 10,
                                                order: orderForSort,
                                            };
                                            handleSort(10, "adrTr");
                                        }}
                                    >
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                            <p>ADR</p>
                                            <div className="d-flex flex-column adrTr">
                                                <svg
                                                    className="up-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
                                                </svg>
                                                <svg
                                                    className="down-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </th>
                                    <th
                                        className="text-end width75"
                                        onClick={() => {
                                            let orderForSort = "";
                                            if (sortingRef.current.column === 11) {
                                                orderForSort =
                                                    sortingRef.current.order === "asc" ? "desc" : "asc";
                                            } else {
                                                orderForSort = "asc";
                                            }
                                            sortingRef.current = {
                                                column: 11,
                                                order: orderForSort,
                                            };
                                            handleSort(11, "rateTr");
                                        }}
                                    >
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                            <p>Rate</p>
                                            <div className="d-flex flex-column rateTr">
                                                <svg
                                                    className="up-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
                                                </svg>
                                                <svg
                                                    className="down-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </th>
                                    <th
                                        className="text-end width75"
                                        onClick={() => {
                                            let orderForSort = "";
                                            if (sortingRef.current.column === 12) {
                                                orderForSort =
                                                    sortingRef.current.order === "asc" ? "desc" : "asc";
                                            } else {
                                                orderForSort = "asc";
                                            }
                                            sortingRef.current = {
                                                column: 12,
                                                order: orderForSort,
                                            };
                                            handleSort(12, "netrate");
                                        }}
                                    >
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                            <p>Net Rate</p>
                                            <div className="d-flex flex-column netrate">
                                                <svg
                                                    className="up-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />
                                                </svg>
                                                <svg
                                                    className="down-arrow-icon"
                                                    fill="#DFDFDF"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="9px"
                                                    height="9px"
                                                    viewBox="0 0 52 52"
                                                    enable-background="new 0 0 52 52"
                                                >
                                                    <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Room Type</th>
                                    <th className="d-flex justify-content-between align-items-center gap-2">
                                        <p>Channel</p>
                                        <svg
                                            onClick={(e) => {
                                                if (selectedFilterRef.current === "combinedChannel") {
                                                    closeFilterModal();
                                                } else {
                                                    selectedFilterRef.current = "combinedChannel";
                                                    handleFilterBtnClick(e);
                                                }
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-caret-down-filled cursorPointer"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path
                                                d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                                                stroke-width="0"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </th>
                                    <th>Source</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblRateCode"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblRatePlanDesc"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblCompany"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblGroupName"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblGuestName"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblConfirmationNumber"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th></th>
                                    <th className="text-center">

                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblStatus"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>

                                    </th>
                                    <th>

                                    </th>
                                    <th>

                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblRoomType"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblChannel"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <div class="search-group-for-table input-group">
                                            <input
                                                aria-label="Search"
                                                class="border-0 form-control"
                                                id="tblSource"
                                                onChange={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr style={{ height: "80px", position: "relative" }}>
                                        <Loader />
                                    </tr>
                                ) : reservationData ? (
                                    reservationData?.map((item, index) => {
                                        let status = "";
                                        if (item?.Status === "R") {
                                            status = "reserved";
                                        } else if (item?.Status === "I") {
                                            status = "in house";
                                        } else if (item?.Status === "O") {
                                            status = "checked out";
                                        } else if (item?.Status === "C") {
                                            status = "cancelled";
                                        } else if (item?.Status === "N") {
                                            status = "no show";
                                        }
                                        let textColor = "";
                                        if (item?.RateStatus?.status == 1) {
                                            textColor = "redText";
                                        }
                                        if (item?.RateStatus?.status == 2) {
                                            textColor = "greenText";
                                        }

                                        let NetRateStatustextColor = "";
                                        if (item?.NetRateStatus == 1) {
                                            NetRateStatustextColor = "redText";
                                        }
                                        if (item?.NetRateStatus == 2) {
                                            NetRateStatustextColor = "greenText";
                                        }



                                        return (
                                            <tr key={index} className="shadowOnHover">
                                                <td>
                                                    {commonService.getDateInFormat(item?.BookingDate)}
                                                </td>
                                                <td>{item?.RateCode}</td>
                                                <td>
                                                    {item?.RateCodeDescript
                                                        ? item?.RateCodeDescript
                                                        : "-"}
                                                </td>
                                                <td>{item?.Company}</td>
                                                <td>{item?.GroupName ? item?.GroupName : "UNKNOWN"}</td>
                                                <td>{item?.GuestName}</td>
                                                <td>{item?.Account}</td>
                                                <td>
                                                    {commonService.getDateInFormat(item?.ArrivalDate)}
                                                </td>
                                                <td className="text-center">{item?.LOS}</td>
                                                <td>{status}</td>
                                                <td className="text-end">
                                                    {commonService.formateAmount(
                                                        Math.round(item?.ADR ? item?.ADR : 0)
                                                    )}
                                                </td>
                                                <td className={`text-end ${textColor}`}>
                                                    {item?.RateStatus?.status == 1 ? (
                                                        <img
                                                            src={lowPrio}
                                                            title={item?.RateStatus?.diff?.toFixed(2) + "%"}
                                                        />
                                                    ) : item?.RateStatus?.status == 2 ? (
                                                        <img
                                                            src={highPrio}
                                                            title={item?.RateStatus?.diff?.toFixed(2) + "%"}
                                                        />
                                                    ) : null}
                                                    {commonService.formateAmount(
                                                        Math.round(item?.Rate ? item?.Rate : 0)
                                                    )}
                                                </td>
                                                <td className={`text-end ${NetRateStatustextColor}`}>
                                                    {item?.NetRateStatus == 1 ? (
                                                        <img
                                                            src={lowPrio}

                                                        />
                                                    ) : item?.NetRateStatus == 2 ? (
                                                        <img
                                                            src={highPrio}

                                                        />
                                                    ) : null}
                                                    {commonService.formateAmount(
                                                        Math.round(item?.NetRate ? item?.NetRate : 0)
                                                    )}
                                                </td>
                                                <td>{item?.RoomType}</td>
                                                <td>{item?.CombinedChannel}</td>
                                                <td>{item?.User}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr className="text-center">
                                        <td colSpan={15}>No Data Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            {api !== 0 && (
                <Comments widgetDetails={para} editorId={"ReservationActivity"} />
            )}

            {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
            {showNoteSidebar && (
                <AddWidgetModal
                    showModal={showNoteSidebar}
                    closeModal={closeNoteSideBar}
                    widgetId={para?.widgetId}
                    propertyId={propertyDetailFromRTK?.propertyid}
                    rowTitle={para?.rowTitle}
                />
            )}
            {widgetModal && (
                <WidgetDetailModal
                    showModal={widgetModal}
                    closeModal={closeWidgetModal}
                    data={widgetDetailRef.current}
                />
            )}
            <Overlay
                show={filterModal.IsDisplay}
                target={filterModal.Target}
                placement={filterModal.Position}
            >
                <Popover
                    id="popover-contained"
                    className="SingleDateSummaryModalPopoverMain"
                >
                    <div className="horizontal-menu-option active reservationFilter">
                        <FilterModal
                            keyName={selectedFilterRef.current}
                            list={allFilterDataRef.current?.[selectedFilterRef.current]}
                            selectedData={
                                allSelectedDataRef.current?.[selectedFilterRef.current]
                            }
                            updateFilter={updateFilterModal}
                        />
                    </div>
                </Popover>
            </Overlay>
        </div>
    );
};

export default ReservationActivity;
