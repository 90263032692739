import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import FilterModal from "./FilterModal";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { Button, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import downArrow from "../../../../Assets/Images/Header/downArrow.svg";
import todo from "../../../../Assets/Images/Header/to-do-list-icon.svg";
import listViewIcon from "../../../../Assets/Images/CRM/listViewIcon.svg";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import moment from "moment";

const SegmentDrillDown = ({
    setCompDataFromChild,
    rowIndex,
    colIndex,
    api,
    para,
    setting,
}) => {
    const date = commonService.convertDateInTimezone(new Date());
    const startDate = commonService.convertDateInTimezone(
        commonService.getDateInDBFormat(
            new Date(date.getFullYear(), date.getMonth(), 1)
        )
    );
    const endDate = commonService.convertDateInTimezone(
        commonService.getDateInDBFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0)
        )
    );

    let rightStartDate = commonService.convertDateInTimezone(
        new Date(startDate).setFullYear(new Date(startDate).getFullYear() - 1)
    );

    let rightEndDate = commonService.convertDateInTimezone(
        new Date(endDate).setFullYear(new Date(endDate).getFullYear() - 1)
    );

    let rightAsOfDate = para?.asOfDate ? new Date(para?.asOfDate) : "";

  let dayOfWeek = {
    Sunday: "0",
    Monday: "1",
    Tuesday: "2",
    Wednesday: "3",
    Thursday: "4",
    Friday: "5",
    Saturday: "6",
  };
  let leftSideObj = {
    asOfDate: commonService.getDateForNode(
      commonService.convertDateInTimezone(para?.asOfDate)
    ),
    stayDateStart: commonService.getDateForNode(
      commonService.convertDateInTimezone(startDate)
    ),
    stayDateEnd: commonService.getDateForNode(
      commonService.convertDateInTimezone(endDate)
    ),
    // bookStartDate: commonService.getDateForNode(startDate),
    // bookEndDate: commonService.getDateForNode(endDate),
    bookStartDate: "",
    bookEndDate: "",
    marketSegment: {},
    roomType: {},
    weekDays: {
      Sunday: true,
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: true,
    },
    leadTimeOperator: ">=",
    leadTime: "-1",
    losOperator: ">=",
    los: "0",
    status: {},
  };
  let rightSideObj = {
    asOfDate: commonService.getDateForNode(
      new Date(rightAsOfDate).setFullYear(
        new Date(rightAsOfDate).getFullYear() - 1
      )
    ),
    stayDateStart: commonService.getDateForNode(
      commonService.convertDateInTimezone(rightStartDate)
    ),
    stayDateEnd: commonService.getDateForNode(
      commonService.convertDateInTimezone(rightEndDate)
    ),
    // bookStartDate: commonService.getDateForNode(rightStartDate),
    // bookEndDate: commonService.getDateForNode(rightEndDate),
    bookStartDate: "",
    bookEndDate: "",
    marketSegment: {},
    roomType: {},
    weekDays: {
      Sunday: true,
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: true,
    },
    leadTimeOperator: ">=",
    leadTime: "-1",
    losOperator: ">=",
    los: "0",
    status: {},
  };
  const [showFilter, setShowFilter] = useState(false);
  const leftFilterRef = useRef(leftSideObj);
  const rightFilterRef = useRef(rightSideObj);
  const callApi = useRef(false);
  const isFilterApiCalled = useRef(false);
  const [segmentDrilldownData, setSegmentDrilldownData] = useState(null);
  const [loading, setLoading] = useState(false);
  const rateCodeDataRef = useRef(false);
  const companyDataRef = useRef(false);
  const guestDataRef = useRef(false);
  const totalRef = useRef();
  const clickedTrRef = useRef();
  const selectedMarketSegmentRef = useRef();
  const selectedRateCodeRef = useRef();
  const selectedCompanyRef = useRef();
  const calledMarketSegmentRef = useRef([]);
  const [msLoading, setMsLoading] = useState(false);
  const calledRateCodeRef = useRef([]);
  const [rateCodeLoading, setRateCodeLoading] = useState(false);
  const calledCompanyRef = useRef([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const showOnApiCallTrRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const [stlyToggle, setStylToggle] = useState(false);
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

    const closeFilterModal = () => {
        setShowFilter(false);
    };

  const updatePayloadFilter = (leftsideFilter, rightSideFilter) => {
    leftFilterRef.current = leftsideFilter;
    rightFilterRef.current = rightSideFilter;
    callApi.current = !callApi.current;
    setStylToggle(false)
  };

    const showOnApiCall = (idArray) => {
        // for (let i = 0; i < idArray?.length; i++) {
        //   const classList = document.getElementsByClassName(idArray[i]);
        //   for (let i = 0; i < classList?.length; i++) {
        //     if (classList[i].classList.contains("nestedTableRowNone")) {
        //       classList[i].classList.remove("nestedTableRowNone");
        //       classList[i].classList.add("singleDateSummmaryTr");
        //     }
        //   }
        // }

        const classList = document.getElementsByClassName(idArray);
        for (let i = 0; i < classList?.length; i++) {
            if (classList[i].classList.contains("nestedTableRowNone")) {
                classList[i].classList.remove("nestedTableRowNone");
                classList[i].classList.add("singleDateSummmaryTr");
            }
        }
    };

    const getKeyFromObj = (obj) => {
        let selectedValue = [];

        Object?.entries(obj)?.map(([key, value]) => {
            if (value) {
                selectedValue = [...selectedValue, key];
            }
        });

        return selectedValue?.toString();
    };

    const getDayOfWeek = (obj) => {
        let selectedValue = [];
        Object?.entries(obj)?.map(([key, value]) => {
            if (value) {
                selectedValue = [...selectedValue, dayOfWeek[key]];
            }
        });

        return selectedValue?.toString();
    };

    const handleShowHideRow = (className, itemDetail) => {
        if (document.getElementById(className) !== null) {
            if (document.getElementById(className).classList.contains("active")) {
                document.getElementById(className).classList.remove("active");
            } else {
                document.getElementById(className).classList.add("active");
            }
        }

        const removeClass = (classNameDetail) => {
            const classList = document.getElementsByClassName(classNameDetail);
            for (let i = 0; i < classList?.length; i++) {
                if (classList[i].classList.contains("nestedTableRowNone")) {
                    classList[i].classList.remove("nestedTableRowNone");
                    classList[i].classList.add("singleDateSummmaryTr");
                } else {
                    classList[i].classList.add("nestedTableRowNone");
                    classList[i].classList.remove("singleDateSummmaryTr");
                }
            }
        };

        removeClass(className);

        const addChildClass = (childClassName) => {
            const classList = document.getElementsByClassName(childClassName);
            for (let i = 0; i < classList?.length; i++) {
                if (!classList[i].classList.contains("nestedTableRowNone")) {
                    classList[i].classList.add("nestedTableRowNone");
                    classList[i].classList.remove("singleDateSummmaryTr");
                }
            }
        };

        const removeAllClass = (childItems) => {
            for (let i = 0; i < childItems?.length; i++) {
                addChildClass(childItems[i]?.guid);

                if (
                    Array.isArray(childItems[i]?.items) &&
                    childItems[i]?.items?.length > 0
                ) {
                    removeAllClass(childItems[i]?.items);
                }
            }
        };

        if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
            removeAllClass(itemDetail?.items);
        }
    };

    const checkActiveRow = (e, guid, itemDetail) => {
        if (e.currentTarget.classList.contains("active")) {
            e.currentTarget.classList.remove("active");
            e.currentTarget.classList.remove("singleDateSummmaryTr");
        } else {
            e.currentTarget.classList.add("active");
            e.currentTarget.classList.add("singleDateSummmaryTr");
        }
    };

    const getFilterData = async () => {
        setLoading(true);
        let filterDataResponse = await apiCall(
            {
                method: "POST",
                url: API_URL.BASE_API_URL.SnapShot + API_URL.SEGMENT_DRILLDOWN.FILTER,
                body: { propertyCode: para?.propertyCode },
            },
            false
        );

        let filterResponse = apiResponse(false, filterDataResponse);

        if (filterResponse?.isValidate) {
            let dataFromApi = filterResponse?.data?.data;
            let marketSegment = {},
                roomType = {},
                status = {};

            dataFromApi?.MarketSegment?.map((item) => {
                marketSegment = { ...marketSegment, [item?.MarketSegment]: true };
            });

            dataFromApi?.RoomType?.map((item) => {
                roomType = { ...roomType, [item?.roomtypecode]: true };
            });

            dataFromApi?.Status?.map((item) => {
                let value = false;
                if (
                    item?.Status === "I" ||
                    item?.Status === "O" ||
                    item?.Status === "R"
                ) {
                    value = true;
                }
                status = { ...status, [item?.Status]: value };
            });

            leftFilterRef.current = {
                ...leftFilterRef.current,
                marketSegment: marketSegment,
                roomType: roomType,
                status: status,
            };

            rightFilterRef.current = {
                ...rightFilterRef.current,
                marketSegment: marketSegment,
                roomType: roomType,
                status: status,
            };

            isFilterApiCalled.current = true;
        }

        if (!filterResponse?.isValidate) {
            setLoading(false);
        }
    };

    const getMarketSegmentData = async () => {
        setLoading(true);
        let marketSegmentResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.SnapShot +
                    API_URL.SEGMENT_DRILLDOWN.GET_MARKETSEGMENT,
                body: {
                    leftAsOfDate: leftFilterRef?.current?.asOfDate,
                    leftpropertyCode: para?.propertyCode,
                    leftstartDate: leftFilterRef?.current?.stayDateStart,
                    leftendDate: leftFilterRef?.current?.stayDateEnd,
                    leftStatus: getKeyFromObj(leftFilterRef.current?.status),
                    // leftDay: "0,1,2,3,4,5,6",
                    leftDay: getDayOfWeek(leftFilterRef.current?.weekDays),
                    leftstartBookingDate: leftFilterRef?.current?.bookStartDate,
                    leftendBookingDate: leftFilterRef?.current?.bookEndDate,
                    leftMarketSegment: getKeyFromObj(
                        leftFilterRef?.current?.marketSegment
                    ),
                    leftRoomType: getKeyFromObj(leftFilterRef?.current?.roomType),
                    leftLeadTimeOperator: leftFilterRef?.current?.leadTimeOperator,
                    leftLeadTime: leftFilterRef?.current?.leadTime,
                    leftLOSOperator: leftFilterRef?.current?.losOperator,
                    leftLOS: leftFilterRef?.current?.los,
                    rightAsOfDate: rightFilterRef.current?.asOfDate,
                    rightpropertyCode: para?.propertyCode,
                    rightstartDate: rightFilterRef.current?.stayDateStart,
                    rightendDate: rightFilterRef.current?.stayDateEnd,
                    rightStatus: getKeyFromObj(rightFilterRef.current?.status),
                    // rightDay: "0,1,2,3,4,5,6",
                    rightDay: getDayOfWeek(rightFilterRef.current?.weekDays),
                    rightstartBookingDate: rightFilterRef.current?.bookStartDate,
                    rightendBookingDate: rightFilterRef.current?.bookEndDate,
                    rightMarketSegment: getKeyFromObj(
                        rightFilterRef.current?.marketSegment
                    ),
                    rightRoomType: getKeyFromObj(rightFilterRef.current?.roomType),
                    rightLeadTimeOperator: rightFilterRef.current?.leadTimeOperator,
                    rightLeadTime: rightFilterRef.current?.leadTime,
                    rightLOSOperator: rightFilterRef.current?.losOperator,
                    rightLOS: rightFilterRef.current?.los,
                    propertyCode: para?.propertyCode,
                },
            },
            false
        );

        let response = apiResponse(false, marketSegmentResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let total = {
        leftRoom: 0,
        leftAdr: 0,
        leftRev: 0,
        leftRevContri: 100,
        rightRoom: 0,
        rightAdr: 0,
        rightRev: 0,
        rightRevContri: 100,
        stlyRoom: 0,
        stlyAdr: 0,
        stlyRev: 0,
        stlyRevContri: 100,
      };

            let groupingData = dataFromApi?.map((item) => {
                item.items = [];
                item.guid = commonService.GenerateGUID();
                item.name = item?.marketsegment;
                item.varientRms =
                    (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
                    (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
                item.varientAdr =
                    (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
                    (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
                item.varientRev =
                    (item?.left_REV ? parseInt(item?.left_REV) : 0) -
                    (item?.right_REV ? parseInt(item?.right_REV) : 0);

        item.stlyRms =
          (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
          (item?.stly_RMS ? parseInt(item?.stly_RMS) : 0);
        item.stlyAdr =
          (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
          (item?.stly_ADR ? parseInt(item?.stly_ADR) : 0);
        item.stlyRev =
          (item?.left_REV ? parseInt(item?.left_REV) : 0) -
          (item?.stly_REV ? parseInt(item?.stly_REV) : 0);

        if (item?.left_RMS) {
          total.leftRoom = total.leftRoom + parseInt(item?.left_RMS);
        }
        if (item?.left_ADR) {
          total.leftAdr = total.leftAdr + parseInt(item?.left_ADR);
        }
        if (item?.left_REV) {
          total.leftRev = total.leftRev + parseInt(item?.left_REV);
        }

                if (item?.right_RMS) {
                    total.rightRoom = total.rightRoom + parseInt(item?.right_RMS);
                }
                if (item?.right_ADR) {
                    total.rightAdr = total.rightAdr + parseInt(item?.right_ADR);
                }
                if (item?.right_REV) {
                    total.rightRev = total.rightRev + parseInt(item?.right_REV);
                }

        if (item?.stly_RMS) {
          total.stlyRoom = total.stlyRoom + parseInt(item?.stly_RMS);
        }
        if (item?.stly_ADR) {
          total.stlyAdr = total.stlyAdr + parseInt(item?.stly_ADR);
        }
        if (item?.stly_REV) {
          total.stlyRev = total.stlyRev + parseInt(item?.stly_REV);
        }

        return item;
      });

            setSegmentDrilldownData(groupingData);
            totalRef.current = total;
            setLoading(false);
        }

        if (!response?.isValidate) {
            setSegmentDrilldownData([]);
            setLoading(false);
        }
    };

    const callBothApi = async () => {
        await getFilterData();
        await getMarketSegmentData();
    };

    const getRateCodeData = async () => {
        // setLoading(true);
        setMsLoading(true);
        let rateCodeResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.SnapShot +
                    API_URL.SEGMENT_DRILLDOWN.GET_RATECODE,
                body: {
                    leftAsOfDate: leftFilterRef?.current?.asOfDate,
                    leftpropertyCode: para?.propertyCode,
                    leftstartDate: leftFilterRef?.current?.stayDateStart,
                    leftendDate: leftFilterRef?.current?.stayDateEnd,
                    leftStatus: getKeyFromObj(leftFilterRef.current?.status),
                    // leftDay: "0,1,2,3,4,5,6",
                    leftDay: getDayOfWeek(leftFilterRef.current?.weekDays),
                    leftstartBookingDate: leftFilterRef?.current?.bookStartDate,
                    leftendBookingDate: leftFilterRef?.current?.bookEndDate,
                    leftMarketSegment: getKeyFromObj(
                        leftFilterRef?.current?.marketSegment
                    ),
                    leftRoomType: getKeyFromObj(leftFilterRef?.current?.roomType),
                    leftLeadTimeOperator: leftFilterRef?.current?.leadTimeOperator,
                    leftLeadTime: leftFilterRef?.current?.leadTime,
                    leftLOSOperator: leftFilterRef?.current?.losOperator,
                    leftLOS: leftFilterRef?.current?.los,
                    rightAsOfDate: rightFilterRef.current?.asOfDate,
                    rightpropertyCode: para?.propertyCode,
                    rightstartDate: rightFilterRef.current?.stayDateStart,
                    rightendDate: rightFilterRef.current?.stayDateEnd,
                    rightStatus: getKeyFromObj(rightFilterRef.current?.status),
                    // rightDay: "0,1,2,3,4,5,6",
                    rightDay: getDayOfWeek(rightFilterRef.current?.weekDays),
                    rightstartBookingDate: rightFilterRef.current?.bookStartDate,
                    rightendBookingDate: rightFilterRef.current?.bookEndDate,
                    rightMarketSegment: getKeyFromObj(
                        rightFilterRef.current?.marketSegment
                    ),
                    rightRoomType: getKeyFromObj(rightFilterRef.current?.roomType),
                    rightLeadTimeOperator: rightFilterRef.current?.leadTimeOperator,
                    rightLeadTime: rightFilterRef.current?.leadTime,
                    rightLOSOperator: rightFilterRef.current?.losOperator,
                    rightLOS: rightFilterRef.current?.los,
                    selectedMarketSegment: selectedMarketSegmentRef.current,
                    propertyCode: para?.propertyCode,
                },
            },
            false
        );

        let response = apiResponse(false, rateCodeResponse);

        if (response?.isValidate) {
            let dataFromApi = response?.data?.data;

            let groupingJson = [...segmentDrilldownData];

            dataFromApi?.forEach((item) => {
                let findMarketsegment = groupingJson?.findIndex(
                    (x) => x?.name === item?.MarketSegment
                );

        if (findMarketsegment >= 0) {
          item.name = item?.RateCode;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);

          item.stlyRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.stly_RMS ? parseInt(item?.stly_RMS) : 0);
          item.stlyAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.stly_ADR ? parseInt(item?.stly_ADR) : 0);
          item.stlyRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.stly_REV ? parseInt(item?.stly_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items = [
            ...groupingJson?.[findMarketsegment]?.items,
            item,
          ];
        }
      });
      // rateCodeDataRef.current = true;
      calledMarketSegmentRef.current = [
        ...calledMarketSegmentRef.current,
        selectedMarketSegmentRef.current,
      ];
      setSegmentDrilldownData(groupingJson);
      // setLoading(false);
      setMsLoading(false);
    }

        if (!response?.isValidate) {
            setMsLoading(false);
        }
    };

    const getCompanyData = async () => {
        // setLoading(true);
        setRateCodeLoading(true);
        let companyDataResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.SnapShot + API_URL.SEGMENT_DRILLDOWN.GET_COMPANY,
                body: {
                    leftAsOfDate: leftFilterRef?.current?.asOfDate,
                    leftpropertyCode: para?.propertyCode,
                    leftstartDate: leftFilterRef?.current?.stayDateStart,
                    leftendDate: leftFilterRef?.current?.stayDateEnd,
                    leftStatus: getKeyFromObj(leftFilterRef.current?.status),
                    // leftDay: "0,1,2,3,4,5,6",
                    leftDay: getDayOfWeek(leftFilterRef.current?.weekDays),
                    leftstartBookingDate: leftFilterRef?.current?.bookStartDate,
                    leftendBookingDate: leftFilterRef?.current?.bookEndDate,
                    leftMarketSegment: getKeyFromObj(
                        leftFilterRef?.current?.marketSegment
                    ),
                    leftRoomType: getKeyFromObj(leftFilterRef?.current?.roomType),
                    leftLeadTimeOperator: leftFilterRef?.current?.leadTimeOperator,
                    leftLeadTime: leftFilterRef?.current?.leadTime,
                    leftLOSOperator: leftFilterRef?.current?.losOperator,
                    leftLOS: leftFilterRef?.current?.los,
                    rightAsOfDate: rightFilterRef.current?.asOfDate,
                    rightpropertyCode: para?.propertyCode,
                    rightstartDate: rightFilterRef.current?.stayDateStart,
                    rightendDate: rightFilterRef.current?.stayDateEnd,
                    rightStatus: getKeyFromObj(rightFilterRef.current?.status),
                    // rightDay: "0,1,2,3,4,5,6",
                    rightDay: getDayOfWeek(rightFilterRef.current?.weekDays),
                    rightstartBookingDate: rightFilterRef.current?.bookStartDate,
                    rightendBookingDate: rightFilterRef.current?.bookEndDate,
                    rightMarketSegment: getKeyFromObj(
                        rightFilterRef.current?.marketSegment
                    ),
                    rightRoomType: getKeyFromObj(rightFilterRef.current?.roomType),
                    rightLeadTimeOperator: rightFilterRef.current?.leadTimeOperator,
                    rightLeadTime: rightFilterRef.current?.leadTime,
                    rightLOSOperator: rightFilterRef.current?.losOperator,
                    rightLOS: rightFilterRef.current?.los,
                    selectedMarketSegment: selectedMarketSegmentRef.current,
                    selectedRateCode: selectedRateCodeRef.current,
                    propertyCode: para?.propertyCode,
                },
            },
            false
        );

        let response = apiResponse(false, companyDataResponse);

        if (response?.isValidate) {
            let dataFromApi = response?.data?.data;

            let groupingJson = [...segmentDrilldownData];

            dataFromApi?.forEach((item) => {
                let findMarketsegment = groupingJson?.findIndex(
                    (x) => x?.name === item?.MarketSegment
                );
                let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
                    (x) => x?.name === item?.RateCode
                );

        if (findRatecode >= 0) {
          item.name = item?.Company;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);

          item.stlyRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.stly_RMS ? parseInt(item?.stly_RMS) : 0);
          item.stlyAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.stly_ADR ? parseInt(item?.stly_ADR) : 0);
          item.stlyRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.stly_REV ? parseInt(item?.stly_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items[findRatecode].items = [
            ...groupingJson[findMarketsegment].items[findRatecode].items,
            item,
          ];
        }
      });
      // companyDataRef.current = true;
      calledRateCodeRef.current = [
        ...calledRateCodeRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      // setLoading(false);
      setRateCodeLoading(false);
    }

        if (!response?.isValidate) {
            setRateCodeLoading(false);
        }
    };

    const getGuestData = async () => {
        // setLoading(true);
        setCompanyLoading(true);
        let guestDataResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.SnapShot + API_URL.SEGMENT_DRILLDOWN.GET_GUEST,
                body: {
                    leftAsOfDate: leftFilterRef?.current?.asOfDate,
                    leftpropertyCode: para?.propertyCode,
                    leftstartDate: leftFilterRef?.current?.stayDateStart,
                    leftendDate: leftFilterRef?.current?.stayDateEnd,
                    leftStatus: getKeyFromObj(leftFilterRef.current?.status),
                    // leftDay: "0,1,2,3,4,5,6",
                    leftDay: getDayOfWeek(leftFilterRef.current?.weekDays),
                    leftstartBookingDate: leftFilterRef?.current?.bookStartDate,
                    leftendBookingDate: leftFilterRef?.current?.bookEndDate,
                    leftMarketSegment: getKeyFromObj(
                        leftFilterRef?.current?.marketSegment
                    ),
                    leftRoomType: getKeyFromObj(leftFilterRef?.current?.roomType),
                    leftLeadTimeOperator: leftFilterRef?.current?.leadTimeOperator,
                    leftLeadTime: leftFilterRef?.current?.leadTime,
                    leftLOSOperator: leftFilterRef?.current?.losOperator,
                    leftLOS: leftFilterRef?.current?.los,
                    rightAsOfDate: rightFilterRef.current?.asOfDate,
                    rightpropertyCode: para?.propertyCode,
                    rightstartDate: rightFilterRef.current?.stayDateStart,
                    rightendDate: rightFilterRef.current?.stayDateEnd,
                    rightStatus: getKeyFromObj(rightFilterRef.current?.status),
                    // rightDay: "0,1,2,3,4,5,6",
                    rightDay: getDayOfWeek(rightFilterRef.current?.weekDays),
                    rightstartBookingDate: rightFilterRef.current?.bookStartDate,
                    rightendBookingDate: rightFilterRef.current?.bookEndDate,
                    rightMarketSegment: getKeyFromObj(
                        rightFilterRef.current?.marketSegment
                    ),
                    rightRoomType: getKeyFromObj(rightFilterRef.current?.roomType),
                    rightLeadTimeOperator: rightFilterRef.current?.leadTimeOperator,
                    rightLeadTime: rightFilterRef.current?.leadTime,
                    rightLOSOperator: rightFilterRef.current?.losOperator,
                    rightLOS: rightFilterRef.current?.los,
                    selectedMarketSegment: selectedMarketSegmentRef.current,
                    selectedRateCode: selectedRateCodeRef.current,
                    selectedCompany: selectedCompanyRef.current,
                    propertyCode: para?.propertyCode,
                },
            },
            false
        );

        let response = apiResponse(false, guestDataResponse);

        if (response?.isValidate) {
            let dataFromApi = response?.data?.data;

            let groupingJson = [...segmentDrilldownData];

            dataFromApi?.forEach((item) => {
                let findMarketsegment = groupingJson?.findIndex(
                    (x) => x?.name === item?.MarketSegment
                );
                let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
                    (x) => x?.name === item?.RateCode
                );

                let findCompany = groupingJson[findMarketsegment].items[
                    findRatecode
                ].items?.findIndex((x) => x?.name === item?.Company);

        if (findCompany >= 0) {
          item.name = item?.GuestName;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);

          item.stlyRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.stly_RMS ? parseInt(item?.stly_RMS) : 0);
          item.stlyAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.stly_ADR ? parseInt(item?.stly_ADR) : 0);
          item.stlyRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.stly_REV ? parseInt(item?.stly_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items[findRatecode].items[
            findCompany
          ].items = [
            ...groupingJson[findMarketsegment].items[findRatecode].items[
              findCompany
            ].items,
            item,
          ];
        }
      });
      // guestDataRef.current = true;
      calledCompanyRef.current = [
        ...calledCompanyRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
          company: selectedCompanyRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      // setLoading(false);
      setCompanyLoading(false);
    }

        if (!response?.isValidate) {
            setCompanyLoading(false);
        }
    };

    useEffect(() => {
        if (api === 0) {
        } else {
            if (para?.asOfDate) {
                leftFilterRef.current = {
                    ...leftFilterRef.current,
                    asOfDate: para?.asOfDate,
                };
                rightFilterRef.current = {
                    ...rightFilterRef.current,
                    asOfDate: para?.asOfDate,
                };
                if (isFilterApiCalled.current) {
                    getMarketSegmentData();
                } else {
                    callBothApi();
                }
            }
        }
    }, [para?.asOfDate, callApi.current]);

    useLayoutEffect(() => {
        // if (clickedTrRef.current) {
        //   showOnApiCall(clickedTrRef.current);
        // }
        if (showOnApiCallTrRef.current) {
            showOnApiCall(showOnApiCallTrRef.current);
        }
    }, [
        rateCodeDataRef.current,
        companyDataRef.current,
        guestDataRef.current,
        segmentDrilldownData,
    ]);

    const [tblids, settblids] = useState({
        mainSegmentDrilldownTable: commonService.GenerateGUID(),
    });

    return (
        <div style={{ position: "relative" }}>
            <img
                className="heading-menu-btns filter-icon-position"
                src={listViewIcon}
                alt="listViewIcon"
                onClick={() => {
                    setShowFilter(true);
                }}
            />
            <div className="widget-container">
                {/* <img
        className="heading-menu-btns filter-icon-position"
        src={listViewIcon}
        alt="listViewIcon"
        onClick={() => {
          setShowFilter(true);
        }}
      /> */}
                {api !== 0 && (
                    <div
                        className="gap-1 widget-container-menu"
                        style={{ right: "85px" }}
                    >
                        {/* <Button className="heading-menu-btns" type="button">
              <img
                src={NoteIcon}
                alt="menuIcon"
                onClick={() => {
                  setShowNoteSidebar(true);
                }}
              />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.mainSegmentDrilldownTable,
                    "Segment Drilldown",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.segmentDrillDown,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.mainSegmentDrilldownTable}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.mainSegmentDrilldownTable,
                    // "Segment Drilldown"
                    `${para?.propertyCode}-Segment Drilldown-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table id={tblids.mainSegmentDrilldownTable} responsive>
                <thead>
                  <tr>
                    <th className="border_right text-center"></th>
                    <th colSpan={4} className="border_right text-center">
                      {`${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(
                          leftFilterRef.current?.stayDateStart
                        )
                      )} - ${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(
                          leftFilterRef.current?.stayDateEnd
                        )
                      )} as of ${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(
                          leftFilterRef.current?.asOfDate
                        )
                      )}`}
                    </th>
                    <th colSpan={4} className="border_right text-center">
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        {`${commonService.getDateInFormat(
                          commonService.convertDateInTimezone(
                            rightFilterRef.current?.stayDateStart
                          )
                        )} - ${commonService.getDateInFormat(
                          commonService.convertDateInTimezone(
                            rightFilterRef.current?.stayDateEnd
                          )
                        )} as of ${commonService.getDateInFormat(
                          commonService.convertDateInTimezone(
                            rightFilterRef.current?.asOfDate
                          )
                        )}`}
                        <div className="form-check form-switch no-border-break from-groups jay-put-this-checkbox">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            role="switch"
                            checked={stlyToggle}
                            onChange={() => {
                              setStylToggle(!stlyToggle);
                            }}
                          />
                          <label className="form-check-label">STLY</label>
                        </div>
                      </div>
                    </th>
                    <th colSpan={4} className="text-center">
                      Variance
                    </th>
                  </tr>
                  <tr>
                    <th className="border_right"></th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="border_right text-center">
                      Rev Contribution
                    </th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="border_right text-center">
                      Rev Contribution
                    </th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="text-center">Rev Contribution</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    segmentDrilldownData &&
                    segmentDrilldownData?.map((item, index) => {
                      let msLeftRevContri = 0,
                        msRightRevContri = 0;
                      if (item?.left_REV > 0 && totalRef.current?.leftRev > 0) {
                        msLeftRevContri = (
                          (parseInt(item?.left_REV) * 100) /
                          totalRef.current?.leftRev
                        )?.toFixed(1);
                      }

                      if (
                        item?.right_REV > 0 &&
                        totalRef.current?.rightRev > 0
                      ) {
                        msRightRevContri = (
                          (parseInt(item?.right_REV) * 100) /
                          totalRef.current?.rightRev
                        )?.toFixed(1);
                      }
                      let toggleRms = item?.right_RMS ? item?.right_RMS : 0;
                      let toggleAdr = item?.right_ADR ? item?.right_ADR : 0;
                      let toggleRev = item?.right_REV ? item?.right_REV : 0;
                      let toggleVarRms = item?.varientRms;
                      let toggleVarAdr = item?.varientAdr;
                      let toggleVarRev = item?.varientRev;
                      if (stlyToggle) {
                        toggleRms = item?.stly_RMS ? item?.stly_RMS : 0;
                        toggleAdr = item?.stly_ADR ? item?.stly_ADR : 0;
                        toggleRev = item?.stly_REV ? item?.stly_REV : 0;
                        toggleVarRms = item?.stlyRms;
                        toggleVarAdr = item?.stlyAdr;
                        toggleVarRev = item?.stlyRev;
                        if (
                          item?.stly_REV > 0 &&
                          totalRef.current?.stlyRev > 0
                        ) {
                          msRightRevContri = (
                            (parseInt(item?.stly_REV) * 100) /
                            totalRef.current?.stlyRev
                          )?.toFixed(1);
                        } else {
                          msRightRevContri = 0;
                        }
                      }
                      return (
                        <>
                          <tr id={item?.guid} className="shadowOnHover">
                            <td className="border_right">
                              <div
                                className={`d-flex gap-2`}
                                onClick={() => {
                                  let findSelectedMarketSegment =
                                    calledMarketSegmentRef.current?.filter(
                                      (x) => x === item?.name
                                    );

                                                                    if (findSelectedMarketSegment?.length > 0) {
                                                                        const classList =
                                                                            document.getElementsByClassName(
                                                                                item?.guid
                                                                            );
                                                                        if (
                                                                            classList?.[0]?.classList?.contains(
                                                                                "nestedTableRowNone"
                                                                            )
                                                                        ) {
                                                                            handleShowHideRow(item?.guid);
                                                                        } else {
                                                                            handleShowHideRow(item?.guid, item);
                                                                        }
                                                                    } else {
                                                                        if (!msLoading) {
                                                                            selectedMarketSegmentRef.current =
                                                                                item?.name;
                                                                            clickedTrRef.current = [item?.guid];
                                                                            showOnApiCallTrRef.current = item?.guid;
                                                                            getRateCodeData();
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <p>{item?.name}</p>
                                                                <img src={downArrow} />

                                {msLoading &&
                                  selectedMarketSegmentRef.current ===
                                    item?.name && (
                                    <div
                                      class="spinner-border text-primary ms-2"
                                      role="status"
                                      style={{
                                        height: "13px",
                                        width: "13px",
                                        color: "#3565FC",
                                      }}
                                    >
                                      <span class="sr-only"></span>
                                    </div>
                                  )}
                              </div>
                            </td>
                            <td className="text-center">
                              {item?.left_RMS
                                ? commonService.formateRoom(item?.left_RMS)
                                : 0}
                            </td>
                            <td className="text-end">
                              {item?.left_ADR
                                ? `${commonService.formateAmount(
                                    item?.left_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="text-end">
                              {item?.left_REV
                                ? `${commonService.formateAmount(
                                    item?.left_REV
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="border_right text-center">
                              {msLeftRevContri}%
                            </td>
                            <td className="text-center">
                              {toggleRms
                                ? commonService.formateRoom(toggleRms)
                                : 0}
                            </td>
                            <td className="text-end">
                              {toggleAdr
                                ? `${commonService.formateAmount(toggleAdr)}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="text-end">
                              {toggleRev
                                ? `${commonService.formateAmount(toggleRev)}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="border_right text-center">
                              {msRightRevContri}%
                            </td>
                            <td
                              className={`text-center ${
                                toggleVarRms < 0 ? "redText" : ""
                              }`}
                            >
                              {commonService.formateRoom(toggleVarRms)}
                            </td>
                            <td
                              className={`text-end ${
                                toggleVarAdr < 0 ? "redText" : ""
                              }`}
                            >
                              {commonService.formateAmount(toggleVarAdr)}
                            </td>
                            <td
                              className={`text-end ${
                                toggleVarRev < 0 ? "redText" : ""
                              }`}
                            >
                              {commonService.formateAmount(toggleVarRev)}
                            </td>
                            <td
                              className={`text-center ${
                                (msLeftRevContri - msRightRevContri)?.toFixed(
                                  1
                                ) < 0
                                  ? "redText"
                                  : ""
                              }`}
                            >
                              {(msLeftRevContri - msRightRevContri)?.toFixed(1)}
                              %
                            </td>
                          </tr>
                          {Array.isArray(item?.items) &&
                            item?.items?.length > 0 &&
                            item?.items?.map((rateCodeItem, rateCodeIndex) => {
                              let rateCodeLeftRevContri = 0,
                                rateCodeRightRevContri = 0;
                              if (
                                rateCodeItem?.left_REV > 0 &&
                                totalRef.current?.leftRev > 0
                              ) {
                                rateCodeLeftRevContri = (
                                  (parseInt(rateCodeItem?.left_REV) * 100) /
                                  totalRef.current?.leftRev
                                )?.toFixed(1);
                              }

                              if (
                                rateCodeItem?.right_REV > 0 &&
                                totalRef.current?.rightRev > 0
                              ) {
                                rateCodeRightRevContri = (
                                  (parseInt(rateCodeItem?.right_REV) * 100) /
                                  totalRef.current?.rightRev
                                )?.toFixed(1);
                              }
                              let rateCodeToggleRms = rateCodeItem?.right_RMS
                                ? rateCodeItem?.right_RMS
                                : 0;
                              let rateCodeToggleAdr = rateCodeItem?.right_ADR
                                ? rateCodeItem?.right_ADR
                                : 0;
                              let rateCodeToggleRev = rateCodeItem?.right_REV
                                ? rateCodeItem?.right_REV
                                : 0;
                              let rateCodeToggleVarRms = rateCodeItem?.varientRms;
                              let rateCodeToggleVarAdr = rateCodeItem?.varientAdr;
                              let rateCodeToggleVarRev = rateCodeItem?.varientRev;
                              if (stlyToggle) {
                                rateCodeToggleRms = rateCodeItem?.stly_RMS
                                  ? rateCodeItem?.stly_RMS
                                  : 0;
                                rateCodeToggleAdr = rateCodeItem?.stly_ADR
                                  ? rateCodeItem?.stly_ADR
                                  : 0;
                                rateCodeToggleRev = rateCodeItem?.stly_REV
                                  ? rateCodeItem?.stly_REV
                                  : 0;
                                rateCodeToggleVarRms = rateCodeItem?.stlyRms;
                                rateCodeToggleVarAdr = rateCodeItem?.stlyAdr;
                                rateCodeToggleVarRev = rateCodeItem?.stlyRev;
                                if (
                                  rateCodeItem?.stly_REV > 0 &&
                                  totalRef.current?.stlyRev > 0
                                ) {
                                  rateCodeRightRevContri = (
                                    (parseInt(rateCodeItem?.stly_REV) * 100) /
                                    totalRef.current?.stlyRev
                                  )?.toFixed(1);
                                } else {
                                  rateCodeRightRevContri = 0;
                                }
                              }
                              return (
                                <>
                                  <tr
                                    className={`${item?.guid} nestedTableRowNone shadowOnHover`}
                                    id={rateCodeItem?.guid}
                                  >
                                    <td className="border_right">
                                      <div
                                        style={{ paddingLeft: "10px" }}
                                        className="d-flex gap-2"
                                        onClick={() => {
                                          if (!rateCodeLoading) {
                                            selectedMarketSegmentRef.current =
                                              item?.name;
                                            selectedRateCodeRef.current =
                                              rateCodeItem?.name;
                                          }
                                          let findSelectedRateCode =
                                            calledRateCodeRef.current?.filter(
                                              (x) =>
                                                x?.ms ===
                                                  selectedMarketSegmentRef.current &&
                                                x?.rc ===
                                                  selectedRateCodeRef.current
                                            );

                                                                                    if (
                                                                                        findSelectedRateCode?.length > 0
                                                                                    ) {
                                                                                        const classList =
                                                                                            document.getElementsByClassName(
                                                                                                rateCodeItem?.guid
                                                                                            );

                                                                                        if (
                                                                                            classList?.[0]?.classList?.contains(
                                                                                                "nestedTableRowNone"
                                                                                            )
                                                                                        ) {
                                                                                            handleShowHideRow(
                                                                                                rateCodeItem?.guid
                                                                                            );
                                                                                        } else {
                                                                                            handleShowHideRow(
                                                                                                rateCodeItem?.guid,
                                                                                                rateCodeItem
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        if (!rateCodeLoading) {
                                                                                            clickedTrRef.current = [
                                                                                                ...clickedTrRef.current,
                                                                                                rateCodeItem?.guid,
                                                                                            ];
                                                                                            showOnApiCallTrRef.current =
                                                                                                rateCodeItem?.guid;
                                                                                            getCompanyData();
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <p>{rateCodeItem?.name}</p>
                                                                                <img src={downArrow} />
                                                                                {rateCodeLoading &&
                                                                                    selectedMarketSegmentRef.current ===
                                                                                    item?.name &&
                                                                                    selectedRateCodeRef.current ===
                                                                                    rateCodeItem?.name && (
                                                                                        <div
                                                                                            class="spinner-border text-primary ms-2"
                                                                                            role="status"
                                                                                            style={{
                                                                                                height: "13px",
                                                                                                width: "13px",
                                                                                                color: "#3565FC",
                                                                                            }}
                                                                                        >
                                                                                            <span class="sr-only"></span>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        </td>

                                    <td className="text-center">
                                      {rateCodeItem?.left_RMS
                                        ? commonService.formateRoom(
                                            rateCodeItem?.left_RMS
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.left_ADR
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.left_ADR
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.left_REV
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.left_REV
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="border_right text-center">
                                      {rateCodeLeftRevContri}%
                                    </td>
                                    <td className="text-center">
                                      {rateCodeToggleRms
                                        ? commonService.formateRoom(
                                            rateCodeToggleRms
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeToggleAdr
                                        ? `${commonService.formateAmount(
                                            rateCodeToggleAdr
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeToggleRev
                                        ? `${commonService.formateAmount(
                                            rateCodeToggleRev
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="border_right text-center">
                                      {rateCodeRightRevContri}%
                                    </td>
                                    <td
                                      className={`text-center ${
                                        rateCodeToggleVarRms < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {commonService.formateRoom(
                                        rateCodeToggleVarRms
                                      )}
                                    </td>
                                    <td
                                      className={`text-end ${
                                        rateCodeToggleVarAdr < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {commonService.formateAmount(
                                        rateCodeToggleVarAdr
                                      )}
                                    </td>
                                    <td
                                      className={`text-end ${
                                        rateCodeToggleVarRev < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {commonService.formateAmount(
                                        rateCodeToggleVarRev
                                      )}
                                    </td>
                                    <td
                                      className={`text-center ${
                                        (
                                          rateCodeLeftRevContri -
                                          rateCodeRightRevContri
                                        )?.toFixed(1) < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {(
                                        rateCodeLeftRevContri -
                                        rateCodeRightRevContri
                                      )?.toFixed(1)}
                                      %
                                    </td>
                                  </tr>
                                  {Array.isArray(rateCodeItem?.items) &&
                                    rateCodeItem?.items?.length > 0 &&
                                    rateCodeItem?.items?.map(
                                      (companyItem, companyIndex) => {
                                        let companyItemLeftRevContri = 0,
                                          companyItemRightRevContri = 0;
                                        if (
                                          companyItem?.left_REV > 0 &&
                                          totalRef.current?.leftRev > 0
                                        ) {
                                          companyItemLeftRevContri = (
                                            (parseInt(companyItem?.left_REV) *
                                              100) /
                                            totalRef.current?.leftRev
                                          )?.toFixed(1);
                                        }

                                        if (
                                          companyItem?.right_REV > 0 &&
                                          totalRef.current?.rightRev > 0
                                        ) {
                                          companyItemRightRevContri = (
                                            (parseInt(companyItem?.right_REV) *
                                              100) /
                                            totalRef.current?.rightRev
                                          )?.toFixed(1);
                                        }
                                        let companyToggleRms =
                                          companyItem?.right_RMS
                                            ? companyItem?.right_RMS
                                            : 0;
                                        let companyToggleAdr =
                                          companyItem?.right_ADR
                                            ? companyItem?.right_ADR
                                            : 0;
                                        let companyToggleRev =
                                          companyItem?.right_REV
                                            ? companyItem?.right_REV
                                            : 0;
                                        let companyToggleVarRms =
                                          companyItem?.varientRms;
                                        let companyToggleVarAdr =
                                          companyItem?.varientAdr;
                                        let companyToggleVarRev =
                                          companyItem?.varientRev;
                                        if (stlyToggle) {
                                          companyToggleRms =
                                            companyItem?.stly_RMS
                                              ? companyItem?.stly_RMS
                                              : 0;
                                          companyToggleAdr =
                                            companyItem?.stly_ADR
                                              ? companyItem?.stly_ADR
                                              : 0;
                                          companyToggleRev =
                                            companyItem?.stly_REV
                                              ? companyItem?.stly_REV
                                              : 0;
                                          companyToggleVarRms = companyItem?.stlyRms;
                                          companyToggleVarAdr = companyItem?.stlyAdr;
                                          companyToggleVarRev = companyItem?.stlyRev;
                                          if (
                                            companyItem?.stly_REV > 0 &&
                                            totalRef.current?.stlyRev > 0
                                          ) {
                                            companyItemRightRevContri = (
                                              (parseInt(companyItem?.stly_REV) *
                                                100) /
                                              totalRef.current?.stlyRev
                                            )?.toFixed(1);
                                          } else {
                                            companyItemRightRevContri = 0;
                                          }
                                        }
                                        return (
                                          <>
                                            <tr
                                              className={`${rateCodeItem?.guid} nestedTableRowNone shadowOnHover`}
                                              id={companyItem?.guid}
                                            >
                                              <td className="border_right">
                                                <div
                                                  style={{
                                                    paddingLeft: "20px",
                                                  }}
                                                  className="d-flex gap-2"
                                                  onClick={() => {
                                                    if (!companyLoading) {
                                                      selectedMarketSegmentRef.current =
                                                        item?.name;
                                                      selectedRateCodeRef.current =
                                                        rateCodeItem?.name;
                                                      selectedCompanyRef.current =
                                                        companyItem?.name;
                                                    }
                                                    let findSelectedCompany =
                                                      calledCompanyRef.current?.filter(
                                                        (x) =>
                                                          x?.ms ===
                                                            selectedMarketSegmentRef.current &&
                                                          x?.rc ===
                                                            selectedRateCodeRef.current &&
                                                          x?.company ===
                                                            selectedCompanyRef.current
                                                      );

                                                                                                        if (
                                                                                                            findSelectedCompany?.length >
                                                                                                            0
                                                                                                        ) {
                                                                                                            const classList =
                                                                                                                document.getElementsByClassName(
                                                                                                                    companyItem?.guid
                                                                                                                );

                                                                                                            if (
                                                                                                                classList?.[0]?.classList?.contains(
                                                                                                                    "nestedTableRowNone"
                                                                                                                )
                                                                                                            ) {
                                                                                                                handleShowHideRow(
                                                                                                                    companyItem?.guid
                                                                                                                );
                                                                                                            } else {
                                                                                                                handleShowHideRow(
                                                                                                                    companyItem?.guid,
                                                                                                                    companyItem
                                                                                                                );
                                                                                                            }
                                                                                                        } else {
                                                                                                            if (!companyLoading) {
                                                                                                                clickedTrRef.current = [
                                                                                                                    ...clickedTrRef.current,
                                                                                                                    companyItem?.guid,
                                                                                                                ];
                                                                                                                showOnApiCallTrRef.current =
                                                                                                                    companyItem?.guid;
                                                                                                                getGuestData();
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <p>{companyItem?.name}</p>
                                                                                                    <img src={downArrow} />
                                                                                                    {companyLoading &&
                                                                                                        selectedMarketSegmentRef.current ===
                                                                                                        item?.name &&
                                                                                                        selectedRateCodeRef.current ===
                                                                                                        rateCodeItem?.name &&
                                                                                                        selectedCompanyRef.current ===
                                                                                                        companyItem?.name && (
                                                                                                            <div
                                                                                                                class="spinner-border text-primary ms-2"
                                                                                                                role="status"
                                                                                                                style={{
                                                                                                                    height: "13px",
                                                                                                                    width: "13px",
                                                                                                                    color: "#3565FC",
                                                                                                                }}
                                                                                                            >
                                                                                                                <span class="sr-only"></span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </td>

                                              <td className="text-center">
                                                {companyItem?.left_RMS
                                                  ? commonService.formateRoom(
                                                      companyItem?.left_RMS
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.left_ADR
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.left_ADR
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.left_REV
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.left_REV
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="border_right text-center">
                                                {companyItemLeftRevContri}%
                                              </td>
                                              <td className="text-center">
                                                {companyToggleRms
                                                  ? commonService.formateRoom(
                                                      companyToggleRms
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="text-end">
                                                {companyToggleAdr
                                                  ? `${commonService.formateAmount(
                                                      companyToggleAdr
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="text-end">
                                                {companyToggleRev
                                                  ? `${commonService.formateAmount(
                                                      companyToggleRev
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="border_right text-center">
                                                {companyItemRightRevContri}%
                                              </td>
                                              <td
                                                className={`text-center ${
                                                  companyToggleVarRms < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {commonService.formateRoom(
                                                  companyToggleVarRms
                                                )}
                                              </td>
                                              <td
                                                className={`text-end ${
                                                  companyToggleVarAdr < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {commonService.formateAmount(
                                                  companyToggleVarAdr
                                                )}
                                              </td>
                                              <td
                                                className={`text-end ${
                                                  companyToggleVarRev < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {commonService.formateAmount(
                                                  companyToggleVarRev
                                                )}
                                              </td>
                                              <td
                                                className={`text-center ${
                                                  (
                                                    companyItemLeftRevContri -
                                                    companyItemRightRevContri
                                                  )?.toFixed(1) < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {(
                                                  companyItemLeftRevContri -
                                                  companyItemRightRevContri
                                                )?.toFixed(1)}
                                                %
                                              </td>
                                            </tr>
                                            {Array.isArray(
                                              companyItem?.items
                                            ) &&
                                              companyItem?.items?.length > 0 &&
                                              companyItem?.items?.map(
                                                (guestItem, guestIndex) => {
                                                  let guestLeftRevContri = 0,
                                                    guestRightRevContri = 0;
                                                  if (
                                                    guestItem?.left_REV > 0 &&
                                                    totalRef.current?.leftRev >
                                                      0
                                                  ) {
                                                    guestLeftRevContri = (
                                                      (parseInt(
                                                        guestItem?.left_REV
                                                      ) *
                                                        100) /
                                                      totalRef.current?.leftRev
                                                    )?.toFixed(1);
                                                  }

                                                  if (
                                                    guestItem?.right_REV > 0 &&
                                                    totalRef.current?.rightRev >
                                                      0
                                                  ) {
                                                    guestRightRevContri = (
                                                      (parseInt(
                                                        guestItem?.right_REV
                                                      ) *
                                                        100) /
                                                      totalRef.current?.rightRev
                                                    )?.toFixed(1);
                                                  }
                                                  let guestToggleRms =
                                                    guestItem?.right_RMS
                                                      ? guestItem?.right_RMS
                                                      : 0;
                                                  let guestToggleAdr =
                                                    guestItem?.right_ADR
                                                      ? guestItem?.right_ADR
                                                      : 0;
                                                  let guestToggleRev =
                                                    guestItem?.right_REV
                                                      ? guestItem?.right_REV
                                                      : 0;
                                                  let guestToggleVarRms =
                                                    guestItem?.varientRms;
                                                  let guestToggleVarAdr =
                                                    guestItem?.varientAdr;
                                                  let guestToggleVarRev =
                                                    guestItem?.varientRev;
                                                  if (stlyToggle) {
                                                    guestToggleRms =
                                                      guestItem?.stly_RMS
                                                        ? guestItem?.stly_RMS
                                                        : 0;
                                                    guestToggleAdr =
                                                      guestItem?.stly_ADR
                                                        ? guestItem?.stly_ADR
                                                        : 0;
                                                    guestToggleRev =
                                                      guestItem?.stly_REV
                                                        ? guestItem?.stly_REV
                                                        : 0;
                                                    guestToggleVarRms =
                                                      guestItem?.stlyRms;
                                                    guestToggleVarAdr =
                                                      guestItem?.stlyAdr;
                                                    guestToggleVarRev =
                                                      guestItem?.stlyRev;
                                                    if (
                                                      guestItem?.stly_REV > 0 &&
                                                      totalRef.current
                                                        ?.stlyRev > 0
                                                    ) {
                                                      guestRightRevContri = (
                                                        (parseInt(
                                                          guestItem?.stly_REV
                                                        ) *
                                                          100) /
                                                        totalRef.current
                                                          ?.stlyRev
                                                      )?.toFixed(1);
                                                    } else {
                                                      guestRightRevContri = 0;
                                                    }
                                                  }
                                                  return (
                                                    <tr
                                                      className={`${companyItem?.guid} nestedTableRowNone shadowOnHover`}
                                                      onClick={(e) => {
                                                        checkActiveRow(e);
                                                      }}
                                                    >
                                                      <td className="border_right">
                                                        <div
                                                          style={{
                                                            paddingLeft: "30px",
                                                          }}
                                                          className="d-flex gap-2"
                                                        >
                                                          <p>
                                                            {guestItem?.name}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td className="text-center">
                                                        {guestItem?.left_RMS
                                                          ? commonService.formateRoom(
                                                              guestItem?.left_RMS
                                                            )
                                                          : 0}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.left_ADR
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.left_ADR
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.left_REV
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.left_REV
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="border_right text-center">
                                                        {guestLeftRevContri}%
                                                      </td>
                                                      <td className="text-center">
                                                        {guestToggleRms
                                                          ? commonService.formateRoom(
                                                              guestToggleRms
                                                            )
                                                          : 0}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestToggleAdr
                                                          ? `${commonService.formateAmount(
                                                              guestToggleAdr
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestToggleRev
                                                          ? `${commonService.formateAmount(
                                                              guestToggleRev
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="border_right text-center">
                                                        {guestRightRevContri}%
                                                      </td>
                                                      <td
                                                        className={`text-center ${
                                                          guestToggleVarRms < 0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {commonService.formateRoom(
                                                          guestToggleVarRms
                                                        )}
                                                      </td>
                                                      <td
                                                        className={`text-end ${
                                                          guestToggleVarAdr < 0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {commonService.formateAmount(
                                                          guestToggleVarAdr
                                                        )}
                                                      </td>
                                                      <td
                                                        className={`text-end ${
                                                          guestToggleVarRev < 0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {commonService.formateAmount(
                                                          guestToggleVarRev
                                                        )}
                                                      </td>
                                                      <td
                                                        className={`text-center ${
                                                          (
                                                            guestLeftRevContri -
                                                            guestRightRevContri
                                                          )?.toFixed(1) < 0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {(
                                                          guestLeftRevContri -
                                                          guestRightRevContri
                                                        )?.toFixed(1)}
                                                        %
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </>
                                        );
                                      }
                                    )}
                                </>
                              );
                            })}
                          {index === segmentDrilldownData?.length - 1 && (
                            <tr>
                              <td className="border_right boldTd">Totals</td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  totalRef.current.leftRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                {totalRef.current.leftRoom > 0 &&
                                totalRef.current.leftRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.leftRev /
                                          totalRef.current.leftRoom
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  totalRef.current.leftRev
                                )}
                              </td>
                              <td className="border_right text-center boldTd">
                                {totalRef.current.leftRevContri}%
                              </td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  stlyToggle
                                    ? totalRef.current.stlyRoom
                                    : totalRef.current.rightRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                {stlyToggle
                                  ? totalRef.current.stlyRoom > 0 &&
                                    totalRef.current.stlyRev > 0
                                    ? commonService.formateAmount(
                                        Math.round(
                                          totalRef.current.stlyRev /
                                            totalRef.current.stlyRoom
                                        )
                                      )
                                    : commonService.formateAmount(0)
                                  : totalRef.current.rightRoom > 0 &&
                                    totalRef.current.rightRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.rightRev /
                                          totalRef.current.rightRoom
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  stlyToggle
                                    ? totalRef.current.stlyRev
                                    : totalRef.current.rightRev
                                )}
                              </td>
                              <td className="border_right text-center boldTd">
                                {stlyToggle
                                  ? totalRef.current.stlyRevContri
                                  : totalRef.current.rightRevContri}
                                %
                              </td>
                              <td className="text-center boldTd">
                                {stlyToggle
                                  ? commonService.formateRoom(
                                      totalRef.current.leftRoom -
                                        totalRef.current.stlyRoom
                                    )
                                  : commonService.formateRoom(
                                      totalRef.current.leftRoom -
                                        totalRef.current.rightRoom
                                    )}
                              </td>
                              <td className="text-end boldTd">
                                {propertyDetailFromRTK?.currency_term
                                  ? propertyDetailFromRTK?.currency_term
                                  : "$"}
                                {stlyToggle
                                  ? (totalRef.current.leftRoom > 0 &&
                                    totalRef.current.leftRev > 0
                                      ? Math.round(
                                          totalRef.current.leftRev /
                                            totalRef.current.leftRoom
                                        )
                                      : 0) -
                                    (totalRef.current.stlyRoom > 0 &&
                                    totalRef.current.stlyRev > 0
                                      ? Math.round(
                                          totalRef.current.stlyRev /
                                            totalRef.current.stlyRoom
                                        )
                                      : 0)
                                  : (totalRef.current.leftRoom > 0 &&
                                    totalRef.current.leftRev > 0
                                      ? Math.round(
                                          totalRef.current.leftRev /
                                            totalRef.current.leftRoom
                                        )
                                      : 0) -
                                    (totalRef.current.rightRoom > 0 &&
                                    totalRef.current.rightRev > 0
                                      ? Math.round(
                                          totalRef.current.rightRev /
                                            totalRef.current.rightRoom
                                        )
                                      : 0)}
                              </td>
                              <td className="text-end boldTd">
                                {stlyToggle
                                  ? commonService.formateAmount(
                                      totalRef.current.leftRev -
                                        totalRef.current.stlyRev
                                    )
                                  : commonService.formateAmount(
                                      totalRef.current.leftRev -
                                        totalRef.current.rightRev
                                    )}
                              </td>
                              <td className="boldTd"></td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {api !== 0 && (
          <Comments widgetDetails={para} editorId={"SegmentDrilldown"} />
        )}

                {showFilter && (
                    <FilterModal
                        showModal={showFilter}
                        closeModal={closeFilterModal}
                        propertyCode={para?.propertyCode}
                        leftFilterProp={leftFilterRef.current}
                        rightFilterProp={rightFilterRef.current}
                        updateFilter={updatePayloadFilter}
                    />
                )}
                {/* {showNoteSidebar && (
          <WidgetNote
            show={showNoteSidebar}
            handleClose={closeNoteSideBar}
            widgetId={para?.widgetId}
            propertyId={propertyDetailFromRTK?.propertyid}
            rowTitle={para?.rowTitle}
          />
        )} */}
                {showNoteSidebar && (
                    <AddWidgetModal
                        showModal={showNoteSidebar}
                        closeModal={closeNoteSideBar}
                        widgetId={para?.widgetId}
                        propertyId={propertyDetailFromRTK?.propertyid}
                        rowTitle={para?.rowTitle}
                    />
                )}
                {widgetModal && (
                    <WidgetDetailModal
                        showModal={widgetModal}
                        closeModal={closeWidgetModal}
                        data={widgetDetailRef.current}
                    />
                )}
            </div>
        </div>
    );
};

export default SegmentDrillDown;
