import React, { useEffect, useState } from "react";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
// import { Chart } from "chart.js/auto";
import Loader from "../../../Loader/Loader";
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import Comments from "../Comments/Comment";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

const BookingPaceMonthlyForecast = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);

  // const getChartData = async () => {
  //   const date = new Date();
  //   const startDate = commonService.getDateInDBFormat(
  //     new Date(date.getFullYear(), date.getMonth(), 1)
  //   );
  //   const endDate = commonService.getDateInDBFormat(
  //     new Date(date.getFullYear(), date.getMonth() + 1, 0)
  //   );
  //   setLoading(true);
  //   let chartDataResponse = await apiCall(
  //     {
  //       method: "POST",
  //       url:
  //         API_URL.BASE_API_URL.SnapShot + API_URL.BOOKING_PACE.COMPARISON_CHART,
  //       body: {
  //         AsOfDate: para?.asOfDate,
  //         startDate: commonService.getDateForNode(startDate),
  //         endDate: commonService.getDateForNode(endDate),
  //         propertyCode: para?.propertyCode,
  //       },
  //     },
  //     false
  //   );

  //   let response = apiResponse(false, chartDataResponse);

  //   if (response?.isValidate) {
  //     let datesArr = commonService.getDateBetweenTwoDates(
  //       new Date(startDate),
  //       new Date(endDate)
  //     );
  //     let dataFromApi = response?.data?.data;
  //     let labels = datesArr?.map((i, index) => {
  //       return index + 1;
  //     });
  //     let chartDataset = {
  //       label: [],
  //       solid: {
  //         label: "OTB",
  //         data: [],
  //         borderColor: "gray",
  //         fill: false,
  //         order: 3,
  //       },
  //       dashed: {
  //         label: "OTB",
  //         data: [],
  //         borderColor: "gray",
  //         borderDash: [5, 5],
  //         fill: false,
  //         order: 4,
  //       },
  //       minData: {
  //         type: "line",
  //         stacked: true,
  //         label: "Min",
  //         data: [],
  //         fill: "+1",
  //         backgroundColor: "#FDDFBC66",
  //         borderColor: "#FDDFBC66",
  //         borderWidth: 0,
  //         tension: 0.2,
  //         order: 2,
  //       },
  //       maxData: {
  //         type: "line",
  //         stacked: true,
  //         label: "Max",
  //         data: [],
  //         fill: "+1",
  //         backgroundColor: "#FDDFBC66",
  //         borderColor: "#FDDFBC66",
  //         borderWidth: 0,
  //         tension: 0.2,
  //         order: 1,
  //       },
  //     };

  //     function getWeekNumberOfMonth(date) {
  //       if (!(date instanceof Date)) {
  //         date = new Date(date);
  //       }

  //       const firstDayOfMonth = new Date(
  //         date.getFullYear(),
  //         date.getMonth(),
  //         1
  //       );

  //       const firstDayWeekDay = firstDayOfMonth.getDay();

  //       const currentDayOfMonth = date.getDate();

  //       const weekNumber = Math.ceil((currentDayOfMonth + firstDayWeekDay) / 7);

  //       return weekNumber;
  //     }

  //     let weeksArrData = {},
  //       weekMinMax = {};

  //     dataFromApi?.forEach((item) => {
  //       let weekNumber = getWeekNumberOfMonth(item?.staydate);
  //       if (weeksArrData[weekNumber]) {
  //         weeksArrData[weekNumber] = [...weeksArrData[weekNumber], item];
  //       } else {
  //         weeksArrData[weekNumber] = [item];
  //       }
  //     });

  //     Object.entries(weeksArrData).map(([key, value]) => {
  //       let weekNumber = getWeekNumberOfMonth(new Date());
  //       chartDataset.label.push(value?.[0]?.staydate);
  //       let min = value?.[0]?.forcastroom,
  //         max = 0,
  //         otbValue = 0,
  //         forecastValue = 0;
  //       if (new Date(value?.[0]?.staydate) < new Date()) {
  //         min = value?.[0]?.total_booking;
  //       }

  //       value?.forEach((valueItem) => {
  //         if (new Date(valueItem?.staydate) < new Date()) {
  //           if (valueItem?.total_booking) {
  //             min =
  //               min > valueItem?.total_booking ? valueItem?.total_booking : min;
  //             max =
  //               max < valueItem?.total_booking ? valueItem?.total_booking : max;
  //           }
  //         } else {
  //           if (valueItem?.forcastroom) {
  //             min = min > valueItem?.forcastroom ? valueItem?.forcastroom : min;
  //             max = max < valueItem?.forcastroom ? valueItem?.forcastroom : max;
  //           }
  //         }

  //         otbValue =
  //           otbValue +
  //           (valueItem?.total_booking ? valueItem?.total_booking : 0);
  //         forecastValue =
  //           forecastValue +
  //           (valueItem?.forcastroom ? valueItem?.forcastroom : 0);
  //       });
  //       let finalOtbValue = Math.round(otbValue / value?.length);
  //       let finalForecastValue = Math.round(forecastValue / value?.length);

  //       if (weekNumber >= parseInt(key)) {
  //         chartDataset.solid.data.push(finalOtbValue);
  //       } else {
  //         chartDataset.dashed.data.push(finalForecastValue);
  //       }
  //       chartDataset.minData.data.push(min);
  //       chartDataset.maxData.data.push(max);
  //     });
  //     let arr = new Array(chartDataset?.solid?.data?.length).fill();
  //     arr[arr.length - 1] =
  //       chartDataset.solid.data[chartDataset.solid.data.length - 1];

  //     chartDataset.dashed.data = [...arr, ...chartDataset.dashed.data];

  //     setChartData({
  //       label: chartDataset?.label,
  //       dataset: [
  //         chartDataset?.solid,
  //         chartDataset?.dashed,
  //         chartDataset?.minData,
  //         chartDataset?.maxData,
  //       ],
  //     });
  //     setLoading(false);
  //   }

  //   if (!response?.isValidate) {
  //     setLoading(false);
  //   }
  // };

  const getChartData = async () => {
    const date = new Date();
    const startDate = commonService.getDateInDBFormat(
      new Date(date.getFullYear(), date.getMonth(), 1)
    );
    const endDate = commonService.getDateInDBFormat(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    );
    setLoading(true);
    let chartDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.BOOKING_PACE.COMPARISON_CHART,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDate),
          endDate: commonService.getDateForNode(endDate),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let chartDataset = {
        label: [],
        min: {
          label: "Min",
          backgroundColor: "#156082",
          borderColor: "#156082",
          data: [],
          stack: "stack1",
        },
        max: {
          label: "Max",
          backgroundColor: "#196B24",
          borderColor: "#196B24",
          data: [],
          stack: "stack2",
        },
        Avg: {
          label: "Avg",
          backgroundColor: "#E97132",
          borderColor: "#E97132",
          data: [],
          stack: "stack3",
        },
        OTB: {
          label: "OTB",
          backgroundColor: "#0F9ED5",
          borderColor: "#0F9ED5",
          data: [],
          stack: "stack4",
        },
      };

      function getWeekNumberOfMonth(date) {
        if (!(date instanceof Date)) {
          date = new Date(date);
        }

        const firstDayOfMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );

        const firstDayWeekDay = firstDayOfMonth.getDay();

        const currentDayOfMonth = date.getDate();

        const weekNumber = Math.ceil((currentDayOfMonth + firstDayWeekDay) / 7);

        let dateRange = commonService.getWeekStartAndEndDate(date);

        if (weekNumber === 1) {
          dateRange.start = firstDayOfMonth;
        }

        if (new Date(dateRange.end).getMonth() !== new Date().getMonth()) {
          dateRange.end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }

        // return weekNumber;
        return `${moment(dateRange?.start).format("MM/DD")}-${moment(
          dateRange?.end
        ).format("MM/DD")}`;
      }

      let weeksArrData = {};

      dataFromApi?.forEach((item) => {
        let weekNumber = getWeekNumberOfMonth(item?.staydate);
        if (weeksArrData[weekNumber]) {
          weeksArrData[weekNumber] = [...weeksArrData[weekNumber], item];
        } else {
          weeksArrData[weekNumber] = [item];
        }
      });

      Object.entries(weeksArrData).map(([key, value]) => {
        let max = value?.[0]?.forcastroom,
          min = value?.[0]?.forcastroom,
          avgTotal = 0,
          otbTotal = 0;
        value?.forEach((valueItem) => {
          max = max < valueItem?.forcastroom ? valueItem?.forcastroom : max;
          min = min > valueItem?.forcastroom ? valueItem?.forcastroom : min;
          avgTotal =
            avgTotal + (valueItem?.forcastroom ? valueItem?.forcastroom : 0);
          otbTotal =
            otbTotal +
            (valueItem?.total_booking ? valueItem?.total_booking : 0);
        });
        // chartDataset.label.push(`W-${key}`);
        chartDataset.label.push(`${key}`);
        chartDataset.min.data.push(min);
        chartDataset.max.data.push(max);
        chartDataset.Avg.data.push(Math.round(avgTotal / value?.length));
        chartDataset.OTB.data.push(Math.round(otbTotal / value?.length));
      });

      setChartData({
        labels: chartDataset?.label,
        datasets: [
          chartDataset?.min,
          chartDataset?.max,
          chartDataset?.Avg,
          chartDataset?.OTB,
        ],
      });
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getChartData();
      }
    }
  }, [para?.asOfDate]);

  // useEffect(() => {
  //   if (chartData) {
  //     var ctx = document?.getElementById("bookingPaceMonthlyForecast");

  //     var myChart = new Chart(ctx, {
  //       type: "line",
  //       data: {
  //         labels: chartData?.label,
  //         datasets: chartData?.dataset,
  //       },
  //       options: {
  //         plugins: {
  //           tooltip: {
  //             mode: "index",
  //             intersect: false,
  //           },
  //         },
  //         maintainAspectRatio: false,
  //       },
  //     });
  //   }
  // }, [chartData]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + tooltipItem.raw;
          },
        },
      },
    },
  };

  Chart.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler
  );

  return (
    <div>
      {loading ? (
        <Loader />
      ) : chartData ? (
        // <canvas
        //   style={{ height: "400px" }}
        //   id="bookingPaceMonthlyForecast"
        // ></canvas>
        <div style={{ height: "400px" }}>
          <Line data={chartData} options={chartOptions} />
        </div>
      ) : (
        <div>
          <p>No Data Found</p>
        </div>
      )}
      {api !== 0 && (
        <Comments
          widgetDetails={para}
          editorId={"BookingPaceMonthlyForecast"}
        />
      )}
    </div>
  );
};

export default BookingPaceMonthlyForecast;
