import React, { useEffect, useState } from "react";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import Comments from "../Comments/Comment";

const SevenDayBookingExpected = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [bookingExpectedData, setBookingExpectedData] = useState(null);
  const [loading, setLoading] = useState(false);

  let chartData = {
    labels: ["-1", "0", "1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        label: "",
        data: [],
        fill: false,
        backgroundColor: "#5591F5",
        borderColor: "#5591F5",
      },
    ],
  };

  let options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Expected Pick up",
        },
      },
      x: {
        title: {
          display: true,
          text: "Days Out",
        },
      },
    },
  };

  const getBookingReservation = async () => {
    setLoading(true);
    let reservationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.PICK_UP.SEVEN_DAY_BOOKING_EXPECTED,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, reservationResponse);

    if (response?.isValidate) {
      response?.data?.data?.map((item) => {
        chartData.datasets[0].data.push(item?.ExpectedPickUp);
      });
      setBookingExpectedData(chartData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      chartData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      setBookingExpectedData(chartData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      chartData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      setBookingExpectedData(chartData);
    } else {
      if (para?.asOfDate) {
        getBookingReservation();
      }
    }
  }, [para?.asOfDate]);

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        bookingExpectedData && (
          <div className="ThreeBarAndLineChartWrapper">
            <h4>Booking Expected</h4>
            <Line data={bookingExpectedData} options={options} />
          </div>
        )
      )}
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"SevenDayBookingExpected"} />
      )}
    </div>
  );
};

export default SevenDayBookingExpected;
