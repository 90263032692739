import React, { useEffect, useState } from "react";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import Comments from "../Comments/Comment";

const SevenWeekBookingPace = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [bookingPaceData, setBookingPaceData] = useState(null);
  const [loading, setLoading] = useState(false);

  let chartData = {
    labels: ["0", "1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        label: "",
        data: [],
        fill: false,
        backgroundColor: "#5591F5",
        borderColor: "#5591F5",
      },
    ],
  };

  let options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Percent Of Reservations",
        },
        ticks: {
          callback: function (value) {
            return value + "%";
            // return value + "%";
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Weeks Out",
        },
      },
    },
  };

  const getBookingReservation = async () => {
    setLoading(true);
    let reservationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.PICK_UP.SEVEN_WEEK_BOOKING_PACE,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, reservationResponse);

    if (response?.isValidate) {
      response?.data?.data?.map((item) => {
        chartData.datasets[0].data.push(item?.ExpectedPickUp);
      });
      let totalBooking = response?.data?.data?.[0]?.total_booking;
      chartData.datasets[0].data = [
        response?.data?.data?.[0]?.dba_0
          ? Math.round((response?.data?.data?.[0]?.dba_0 * 100) / totalBooking)
          : 0,
        response?.data?.data?.[0]?.dba_1
          ? Math.round((response?.data?.data?.[0]?.dba_1 * 100) / totalBooking)
          : 0,
        response?.data?.data?.[0]?.dba_2
          ? Math.round((response?.data?.data?.[0]?.dba_2 * 100) / totalBooking)
          : 0,
        response?.data?.data?.[0]?.dba_3
          ? Math.round((response?.data?.data?.[0]?.dba_3 * 100) / totalBooking)
          : 0,
        response?.data?.data?.[0]?.dba_4
          ? Math.round((response?.data?.data?.[0]?.dba_4 * 100) / totalBooking)
          : 0,
        response?.data?.data?.[0]?.dba_5
          ? Math.round((response?.data?.data?.[0]?.dba_5 * 100) / totalBooking)
          : 0,
        response?.data?.data?.[0]?.dba_6
          ? Math.round((response?.data?.data?.[0]?.dba_6 * 100) / totalBooking)
          : 0,
        response?.data?.data?.[0]?.dba_7
          ? Math.round((response?.data?.data?.[0]?.dba_7 * 100) / totalBooking)
          : 0,
      ];
      setBookingPaceData(chartData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      chartData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0];
      setBookingPaceData(chartData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      chartData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0];
      setBookingPaceData(chartData);
    } else {
      if (para?.asOfDate) {
        getBookingReservation();
      }
    }
  }, [para?.asOfDate]);

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        bookingPaceData && (
          <div className="ThreeBarAndLineChartWrapper">
            <h4>% Of Room Sold By Booking Week</h4>
            <Line data={bookingPaceData} options={options} />
          </div>
        )
      )}
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"SevenWeekBookingPace"} />
      )}
    </div>
  );
};

export default SevenWeekBookingPace;
