import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Col, Row, Table, Button, Form } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import emailIcon from "../../../../Assets/Images/Email.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { FixColumn } from "../../../../utils/dataTable";
import DatePicker from "react-datepicker";
import EventViewModal from "../../../CommonComponent/EventViewModal";
import Comments from "../Comments/Comment";

const ForecastUsingNinetyDayPickupNew = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [loading, setLoading] = useState(false);
  const [forecastPickupData, setForecastPickupData] = useState(null);

  let dummyArr = Array.from(Array(91).keys());
  const [widgetModal, setWidgetModal] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [rateIqData, setRateIqData] = useState(null);
  const rateIqDataRef = useRef();
  const [selectedCol, setSelectedCol] = useState(90);
  const selectedColArr = [90, 60, 40, 30, 15, 7];
  const widgetDetailRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );

  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const redLineForChartRef = useRef([]);
  const maxValueForInventoryRef = useRef();
  const [selectedDate, setSelectedDate] = useState(
    para?.asOfDate ? new Date(para?.asOfDate) : ""
  );
  const [weekWiseTbl, setWeekWiseTbl] = useState(null);
  const labelFirstPositionRef = useRef();
  const allChartDataRef = useRef();
  const [selectedRange, setSelectedRange] = useState("");
  const [showTableData, setShowTableData] = useState(false);
  const firstTimeApiCallRef = useRef(false);
  const weekWiseTblAvgRef = useRef({
    Sunday: { count: 0, total: 0 },
    Monday: { count: 0, total: 0 },
    Tuesday: { count: 0, total: 0 },
    Wednesday: { count: 0, total: 0 },
    Thursday: { count: 0, total: 0 },
    Friday: { count: 0, total: 0 },
    Saturday: { count: 0, total: 0 },
  });
  const [weekWiseChart, setWeekWiseChart] = useState(null);
  const dateForDash = new Date();
  const startDateForDash = new Date(
    dateForDash.getFullYear(),
    dateForDash.getMonth(),
    1
  );
  const endDateForDash = new Date(
    dateForDash.getFullYear(),
    dateForDash.getMonth() + 1,
    0
  );
  const defaultStartDate = new Date().setDate(
    new Date(selectedDate).getDate() - 90
  );
  const [dateFilter, setDateFilter] = useState({
    start: defaultStartDate,
    end: new Date().setDate(new Date(selectedDate).getDate() - 1),
  });
  let maxDateForPicker = new Date().setDate(
    new Date(selectedDate).getDate() - 1
  );

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  let dbaTotalNormalObj = {
    dba_FINAL: 0,
    dba_0: 0,
    dba_1: 0,
    dba_2: 0,
    dba_3: 0,
    dba_4: 0,
    dba_5: 0,
    dba_6: 0,
    dba_7: 0,
    dba_8: 0,
    dba_9: 0,
    dba_10: 0,
    dba_11: 0,
    dba_12: 0,
    dba_13: 0,
    dba_14: 0,
    dba_15: 0,
    dba_16: 0,
    dba_17: 0,
    dba_18: 0,
    dba_19: 0,
    dba_20: 0,
    dba_21: 0,
    dba_22: 0,
    dba_23: 0,
    dba_24: 0,
    dba_25: 0,
    dba_26: 0,
    dba_27: 0,
    dba_28: 0,
    dba_29: 0,
    dba_30: 0,
    dba_31: 0,
    dba_32: 0,
    dba_33: 0,
    dba_34: 0,
    dba_35: 0,
    dba_36: 0,
    dba_37: 0,
    dba_38: 0,
    dba_39: 0,
    dba_40: 0,
    dba_41: 0,
    dba_42: 0,
    dba_43: 0,
    dba_44: 0,
    dba_45: 0,
    dba_46: 0,
    dba_47: 0,
    dba_48: 0,
    dba_49: 0,
    dba_50: 0,
    dba_51: 0,
    dba_52: 0,
    dba_53: 0,
    dba_54: 0,
    dba_55: 0,
    dba_56: 0,
    dba_57: 0,
    dba_58: 0,
    dba_59: 0,
    dba_60: 0,
    dba_61: 0,
    dba_62: 0,
    dba_63: 0,
    dba_64: 0,
    dba_65: 0,
    dba_66: 0,
    dba_67: 0,
    dba_68: 0,
    dba_69: 0,
    dba_70: 0,
    dba_71: 0,
    dba_72: 0,
    dba_73: 0,
    dba_74: 0,
    dba_75: 0,
    dba_76: 0,
    dba_77: 0,
    dba_78: 0,
    dba_79: 0,
    dba_80: 0,
    dba_81: 0,
    dba_82: 0,
    dba_83: 0,
    dba_84: 0,
    dba_85: 0,
    dba_86: 0,
    dba_87: 0,
    dba_88: 0,
    dba_89: 0,
    dba_90: 0,
  };

  const dbaTotalRef = useRef(dbaTotalNormalObj);

  const getRateIqData = async () => {
    rateIqDataRef.current = null;
    let startDate = new Date(selectedDate);
    let endDate = moment(selectedDate).add(90, "d");
    // let endDate = new Date(para?.asOfDate);
    // endDate.setDate(new Date(startDate.getDate() + 90));
    let rateIqResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEIQ.FORECAST_LIST,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDate),
          endDate: commonService.getDateForNode(endDate),
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, rateIqResponse);

    if (response?.isValidate) {
      setRateIqData(response?.data?.data);
      rateIqDataRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      setRateIqData(null);
      rateIqDataRef.current = null;
    }
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getForecastPickupData = async () => {
    dbaTotalRef.current = dbaTotalNormalObj;
    setLoading(true);
    getEventList();
    await getRateIqData();
    let forecastPickupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.PICK_UP.DYNAMIC_FORECAST_USING_90_DAY_PICKUP_NEW,
        body: {
          SelectedDate: commonService.getDateForNode(selectedDate),
          startDate: dateFilter?.start
            ? commonService?.getDateForNode(dateFilter?.start)
            : "",
          endDate: dateFilter?.end
            ? commonService?.getDateForNode(dateFilter?.end)
            : "",
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, forecastPickupResponse);

    if (response?.isValidate) {
      redLineForChartRef.current = [];
      let OTB = {
          type: "line",
          label: `OTB`,
          borderColor: "#1686F9",
          backgroundColor: "#1686F9",
          data: [],
          stack: "stack1" || "default",
        },
        Forecast = {
          type: "line",
          label: `Forecast`,
          borderColor: "#25A855",
          backgroundColor: "#25A855",
          data: [],
          stack: "stack2" || "default",
          hidden: true,
        },
        avgPickup = {
          type: "line",
          label: `RSA`,
          borderColor: "#E64839",
          backgroundColor: "#E64839",
          data: [],
          stack: "stack3" || "default",
        },
        LY_OTB = {
          type: "line",
          label: `LY OTB`,
          borderColor: "#000000",
          backgroundColor: "#000000",
          data: [],
          stack: "stack4" || "default",
          hidden: true,
        },
        MLForecast = {
          type: "line",
          label: `ML Forecast`,
          borderColor: "#25A855",
          backgroundColor: "#25A855",
          data: [],
          stack: "stack2" || "default",
          hidden: true,
        },
        labels = [],
        maxvalueForChart = 0,
        weekWiseTable = {
          Sunday: { count: 0, otb: 0 },
          Monday: { count: 0, otb: 0 },
          Tuesday: { count: 0, otb: 0 },
          Wednesday: { count: 0, otb: 0 },
          Thursday: { count: 0, otb: 0 },
          Friday: { count: 0, otb: 0 },
          Saturday: { count: 0, otb: 0 },
        };
      let weekChartLabels = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        weekOtb = {
          type: "line",
          label: `DBA Avg`,
          borderColor: "#1686F9",
          backgroundColor: "#1686F9",
          data: [],
          stack: "stack1" || "default",
        };

      const setWeekWiseData = (data) => {
        weekWiseTable[data.Weekday].count =
          weekWiseTable[data.Weekday]?.count + 1;
        weekWiseTable[data.Weekday].otb =
          weekWiseTable[data.Weekday]?.otb + data?.total_booking;

        dummyArr?.map((item, index) => {
          if (weekWiseTable[data.Weekday]?.[`dba_${index}`]) {
            weekWiseTable[data.Weekday][`dba_${index}`] =
              weekWiseTable[data.Weekday]?.[`dba_${index}`] +
              data?.[`dba_${index}`];
          } else {
            weekWiseTable[data.Weekday][`dba_${index}`] =
              data?.[`dba_${index}`];
          }

          if (weekWiseTable[data.Weekday]?.[`pu_${index}`]) {
            weekWiseTable[data.Weekday][`pu_${index}`] =
              weekWiseTable[data.Weekday]?.[`pu_${index}`] +
              data?.[`pu_${index}`];
          } else {
            weekWiseTable[data.Weekday][`pu_${index}`] = data?.[`pu_${index}`];
          }
        });
      };

      response?.data?.data.map((item) => {
        if (item?.day_diff < 0) {
          setWeekWiseData(item);
        }
        let date = moment(selectedDate);
        let checkWithDate = moment(para?.asOfDate);
        let stayDate = moment(item?.staydate);
        if (date.isAfter(checkWithDate)) {
          if (stayDate.isSameOrAfter(checkWithDate) && item?.day_diff <= 90) {
            if (item?.day_diff >= 0) {
              redLineForChartRef.current = [
                ...redLineForChartRef.current,
                item?.dba_avg,
              ];
            } else {
              redLineForChartRef.current = [
                ...redLineForChartRef.current,
                undefined,
              ];
            }
          }
        } else {
          if (item?.day_diff >= -1 && item?.day_diff <= 90) {
            if (item?.day_diff >= 0) {
              redLineForChartRef.current = [
                ...redLineForChartRef.current,
                item?.dba_avg,
              ];
            } else {
              redLineForChartRef.current = [
                ...redLineForChartRef.current,
                undefined,
              ];
            }
          }
        }
      });

      response?.data?.data?.map((item, index) => {
        let date = moment(selectedDate);
        let checkWithDate = moment(para?.asOfDate);
        let stayDate = moment(item?.staydate);
        if (date.isAfter(checkWithDate)) {
          if (stayDate.isSameOrAfter(checkWithDate) && item?.day_diff <= 90) {
            labels.push(item?.day_diff);
            if (item?.total_booking > maxvalueForChart) {
              maxvalueForChart = item?.total_booking;
            }
            if (item?.forcastroom > maxvalueForChart) {
              maxvalueForChart = item?.forcastroom;
            }
            if (item?.ly_booking > maxvalueForChart) {
              maxvalueForChart = item?.ly_booking;
            }
            if (redLineForChartRef.current[index] > maxvalueForChart) {
              maxvalueForChart = redLineForChartRef.current[index];
            }
            let findMLData = rateIqDataRef.current?.filter(
              (x) =>
                commonService?.getDateInFormat(x?.Dates) ===
                commonService?.getDateInFormat(item?.["staydate"])
            );
            OTB.data = [...OTB.data, item?.total_booking];
            Forecast.data = [...Forecast.data, item?.forcastroom];
            LY_OTB.data = [
              ...LY_OTB.data,
              item?.ly_booking ? item?.ly_booking : 0,
            ];
            MLForecast.data = [
              ...MLForecast.data,
              findMLData?.[0]?.ml_room ? findMLData?.[0]?.ml_room : 0,
            ];
          }
        } else {
          if (item?.day_diff >= -1 && item?.day_diff <= 90) {
            labels.push(item?.day_diff);
            if (item?.total_booking > maxvalueForChart) {
              maxvalueForChart = item?.total_booking;
            }
            if (item?.forcastroom > maxvalueForChart) {
              maxvalueForChart = item?.forcastroom;
            }
            if (item?.ly_booking > maxvalueForChart) {
              maxvalueForChart = item?.ly_booking;
            }
            if (redLineForChartRef.current[index] > maxvalueForChart) {
              maxvalueForChart = redLineForChartRef.current[index];
            }
            let findMLData = rateIqDataRef.current?.filter(
              (x) =>
                commonService?.getDateInFormat(x?.Dates) ===
                commonService?.getDateInFormat(item?.["staydate"])
            );

            OTB.data = [...OTB.data, item?.total_booking];
            Forecast.data = [...Forecast.data, item?.forcastroom];
            LY_OTB.data = [
              ...LY_OTB.data,
              item?.ly_booking ? item?.ly_booking : 0,
            ];
            MLForecast.data = [
              ...MLForecast.data,
              findMLData?.[0]?.ml_room ? findMLData?.[0]?.ml_room : 0,
            ];
          }
        }
      });
      avgPickup.data = redLineForChartRef.current;

      // for (let i = -2; i >= -30; i--) {
      //   let isLabelExists = labels?.filter((x) => x === i);
      //   if (isLabelExists?.length <= 0) {
      //     labels.unshift(i);
      //     OTB.data.unshift(undefined);
      //     redLineForChartRef.current.unshift(undefined);
      //   }
      // }

      let chartDetail = {
        labels: labels,
        // datasets: [OTB, Forecast, avgPickup, LY_OTB],
        datasets: [OTB, avgPickup, LY_OTB, MLForecast],
      };
      labelFirstPositionRef.current = labels[0];
      Object.entries(weekWiseTable)?.forEach(([key, value], index) => {
        {
          dummyArr?.forEach((item, index) => {
            if (index <= selectedCol) {
              let avg =
                value?.[`dba_${index}`] && value?.count
                  ? Math.round(value?.[`dba_${index}`] / value?.count)
                  : 0;
              weekWiseTblAvgRef.current[key].total =
                weekWiseTblAvgRef.current?.[key]?.total + avg;
              if (avg > 0) {
                weekWiseTblAvgRef.current[key].count =
                  weekWiseTblAvgRef.current?.[key]?.count + 1;
              }
            }
          });
        }
      });
      Object.entries(weekWiseTblAvgRef.current)?.forEach(([key, value]) => {
        let avgForChart =
          value.total && value.count
            ? Math.round(value.total / value.count)
            : 0;
        weekOtb.data.push(avgForChart);
      });
      setWeekWiseChart({
        labels: weekChartLabels,
        datasets: [weekOtb],
      });
      setWeekWiseTbl(weekWiseTable);
      maxValueForInventoryRef.current = maxvalueForChart;
      allChartDataRef.current = chartDetail;
      setChartData(chartDetail);
      setForecastPickupData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      redLineForChartRef.current = [];
      allChartDataRef.current = null;
      setChartData(null);
      setForecastPickupData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (
        para?.asOfDate &&
        selectedDate &&
        dateFilter?.start &&
        dateFilter?.end
      ) {
        getForecastPickupData();
      }
    }
  }, [para?.asOfDate, selectedDate, dateFilter?.start, dateFilter?.end]);

  const [tblids, settblids] = useState({
    tblForecastPickup: commonService.GenerateGUID(),
    tblWeekwiseData: commonService.GenerateGUID(),
    tblNextForecastPickup: commonService.GenerateGUID(),
  });

  let chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Days Out",
        },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Inventory",
        },
        grid: {
          display: true,
        },
        offset: true,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        // max: maxValueForInventoryRef.current,
        min: 0,
        stepSize: 10,
        position: { x: labelFirstPositionRef.current * -1 },
      },
    },
    elements: {
      line: {
        fill: false,
      },
      // point: {
      //   radius: 0,
      // },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  let weekChartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Weekwise DBA Avg",
        },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Inventory",
        },
        grid: {
          display: true,
        },
        offset: true,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        min: 0,
        stepSize: 10,
      },
    },
    elements: {
      line: {
        fill: false,
      },
      // point: {
      //   radius: 0,
      // },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  FixColumn(tblids.tblForecastPickup, [1]);

  useEffect(() => {
    if (forecastPickupData?.length > 0) {
      if (selectedCol === 90) {
        setChartData(allChartDataRef.current);
      } else {
        let isSelectedDateFromFuture = false;
        let date = moment(selectedDate);
        let checkWithDate = moment(para?.asOfDate);
        if (date.isAfter(checkWithDate)) {
          isSelectedDateFromFuture = true;
        }
        let arr = [];

        if (isSelectedDateFromFuture) {
          arr = forecastPickupData?.filter((x) => {
            let stayDate = moment(x?.staydate);
            if (stayDate.isSameOrAfter(checkWithDate) && x?.day_diff <= 90) {
              if (x?.day_diff <= selectedCol) {
                return x;
              }
            }
          });
        } else {
          arr = forecastPickupData?.filter((x) => {
            if (x?.day_diff >= -1 && x?.day_diff <= 90) {
              if (x?.day_diff <= selectedCol) {
                return x;
              }
            }
          });
        }
        let newArr = { ...allChartDataRef.current };

        let updatedDataSet = newArr?.datasets?.map((item) => ({
          ...item,
          data: item?.data?.slice(0, arr?.length),
        }));
        let chartDataSet = {
          labels: newArr?.labels?.slice(0, arr?.length),
          datasets: updatedDataSet,
        };
        setChartData(chartDataSet);
      }
    }
  }, [selectedCol, forecastPickupData]);

  const handleDateChange = (event) => {
    const [start, end] = event;
    setDateFilter({
      start,
      end,
    });
    setSelectedRange("");
  };

  return (
    <>
      <Col xl={12}>
        <Row>
          <div className="d-flex gap-2 justify-content-between align-items-center crm-dashboard-bot-top">
            <div className="width125">
              <DatePicker
                id="checkInDate"
                selected={selectedDate ? new Date(selectedDate) : ""}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
              />
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div className="width175">
                <DatePicker
                  onChange={(event) => handleDateChange(event)}
                  startDate={dateFilter?.start ? dateFilter?.start : ""}
                  endDate={dateFilter?.end ? dateFilter?.end : ""}
                  maxDate={maxDateForPicker}
                  selectsRange
                  monthsShown={2}
                />
              </div>
              <div className="form-groups form-groups-radio-btn">
                <div className="form-groups-radio">
                  <div className="form-radio">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="radioGroup2"
                      value="daily"
                      id="YtoD"
                      checked={selectedRange === "YtoD" ? true : false}
                      onClick={() => {
                        setSelectedRange("YtoD");

                        if (new Date() <= new Date(maxDateForPicker)) {
                          setDateFilter({
                            start: new Date(new Date().getFullYear(), 0, 1),
                            end: new Date(),
                          });
                        } else {
                          setDateFilter({
                            start: new Date(new Date().getFullYear(), 0, 1),
                            end: maxDateForPicker,
                          });
                        }
                      }}
                    />
                    <Form.Check.Label htmlFor="YtoD" className="label-radio">
                      Year to Date
                    </Form.Check.Label>
                  </div>
                  <div className="form-radio">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="radioGroup2"
                      value="monthly"
                      id="monthly_1"
                      checked={selectedRange === "Month" ? true : false}
                      onClick={() => {
                        setSelectedRange("Month");

                        if (
                          new Date(endDateForDash) <= new Date(maxDateForPicker)
                        ) {
                          setDateFilter({
                            start: startDateForDash,
                            end: endDateForDash,
                          });
                        } else {
                          setDateFilter({
                            start: startDateForDash,
                            end: maxDateForPicker,
                          });
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="monthly_1"
                      className="label-radio"
                    >
                      Current Month
                    </Form.Check.Label>
                  </div>
                  <div className="form-radio">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="radioGroup2"
                      value="weekly"
                      id="weekly_1"
                      checked={selectedRange === "Week" ? true : false}
                      onClick={() => {
                        setSelectedRange("Week");
                        const today = new Date();
                        const dayOfWeek = today.getDay();
                        const startOfWeek = new Date(today);
                        startOfWeek.setDate(today.getDate() - dayOfWeek);
                        const endOfWeek = new Date(today);
                        endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));

                        if (new Date(endOfWeek) <= new Date(maxDateForPicker)) {
                          setDateFilter({
                            start: startOfWeek,
                            end: endOfWeek,
                          });
                        } else {
                          setDateFilter({
                            start: startOfWeek,
                            end: maxDateForPicker,
                          });
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="weekly_1"
                      className="label-radio"
                    >
                      Current Week
                    </Form.Check.Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Col xl={12} className="mt-2">
          {loading ? (
            <Loader />
          ) : chartData ? (
            <div style={{ height: "400px" }}>
              <Line data={chartData} options={chartOptions} />
            </div>
          ) : (
            <div>No Data Found</div>
          )}
          <div className="mt-2">
            {loading ? (
              <Loader />
            ) : weekWiseChart ? (
              <div style={{ height: "400px" }}>
                <Line data={weekWiseChart} options={weekChartOptions} />
              </div>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
          {!loading && (
            <div className="d-flex justify-content-end mb-4">
              <p
                className="table-toggle-link"
                onClick={() => {
                  setShowTableData(!showTableData);
                }}
              >
                {`View ${showTableData ? "Less" : "More"}`}
              </p>
            </div>
          )}
        </Col>
      </Col>
      <div className={`${showTableData ? "" : "d-none"}`}>
        <Col xl={12}>
          <div className="widget-container">
            {api !== 0 && (
              <div
                className="gap-1 widget-container-menu"
                style={{ marginRight: "80px" }}
              >
                <Button
                  className="heading-menu-btns"
                  type="button"
                  title="Send Mail"
                >
                  <img
                    src={emailIcon}
                    alt="emailIcon"
                    onClick={() => {
                      commonService.fnSendWidgetMail(
                        tblids.tblForecastPickup,
                        "Forecast using 90 Days Pickup",
                        snapshotId,
                        defaultAsOfDateFromRTK,
                        "",
                        para?.widgetId
                      );
                    }}
                  />
                </Button>
                <Button className="heading-menu-btns" type="button">
                  <img
                    src={infoIcon}
                    alt="info"
                    onClick={() => {
                      widgetDetailRef.current = {
                        widgetdetails: "",
                        usernote: "",
                        compName: compName?.forecastUsing90DayPickup,
                        widgetId: para?.widgetId,
                      };
                      setWidgetModal(true);
                    }}
                  />
                </Button>
                <Button className="heading-menu-btns" type="button">
                  <img
                    src={downloadIcon}
                    alt="menuIcon"
                    import={tblids.tblForecastPickup}
                    onClick={() =>
                      commonService.fnExportInCSV(
                        tblids.tblForecastPickup,
                        // "Forecast using 90 Days Pickup"
                        `${
                          para?.propertyCode
                        }-Forecast using 90 Days Pickup-${moment(
                          new Date()
                        ).format("MMDDYYYY")}`
                      )
                    }
                  />
                </Button>
              </div>
            )}
            <div className="ViewPropertySetupTable mt-2">
              <div className="stripped__table">
                <div className="table-responsive tblDynamicPickupSticky">
                  <Table id={tblids.tblForecastPickup}>
                    <thead className="tbl-header-sticky">
                      <tr className="table-main-header-tr">
                        <th colSpan={94}>
                          <div className="dynamicPickupDropdown">
                            Used Last 90 Days PickUp Data
                            <Form.Select
                              className="width75"
                              aria-label="Default select example"
                              value={selectedCol}
                              onChange={(e) => {
                                setSelectedCol(e.target.value);
                              }}
                            >
                              {selectedColArr?.map((item) => (
                                <option key={item} value={item}>
                                  dba-{item}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </th>
                      </tr>
                      <tr className="table-main-header-tr">
                        <th className="border_right width100">Date</th>
                        <th className="border_right width100">Day Of Week</th>
                        <th className="border_right width50">dba -1</th>

                        {dummyArr?.map((item, index) => {
                          if (index <= selectedCol) {
                            return (
                              <th
                                className="text-center border_right"
                                key={`dba_${index}`}
                              >
                                {`dba ${index}`}
                              </th>
                            );
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : forecastPickupData ? (
                        forecastPickupData?.map((item, index) => {
                          let showBgColor = false;
                          if (
                            moment(new Date(item?.["staydate"]), "l").format(
                              "ddd"
                            ) === "Fri" ||
                            moment(new Date(item?.["staydate"]), "l").format(
                              "ddd"
                            ) === "Sat"
                          ) {
                            showBgColor = true;
                          }

                          return (
                            <>
                              {/* {item?.staydate < 0 && ( */}
                              {new Date(`${item?.staydate} 00:00:00`) <=
                                new Date(dateFilter.end) && (
                                <tr
                                  key={`forecast_${item?.staydate}`}
                                  className={`${
                                    showBgColor ? "backgroundTr" : ""
                                  } shadowOnHover`}
                                >
                                  <td className="border_right">
                                    {/* {commonService.getDateInFormat(
                                      new Date(item?.["staydate"])
                                    )} */}
                                    {moment(item?.staydate).format("L")}
                                  </td>
                                  <td className="border_right text-center">
                                    {moment(item?.staydate).format("dddd")}
                                  </td>
                                  <td className="border_right text-center">
                                    {item?.total_booking}
                                  </td>
                                  {dummyArr?.map((dummyItem, dummyIndex) => {
                                    if (dummyIndex <= selectedCol) {
                                      return (
                                        <td
                                          className="border_right text-center"
                                          key={`forecast_${dummyIndex}`}
                                        >
                                          {item?.[`dba_${dummyIndex}`]}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              )}

                              {item?.day_diff === -1 && (
                                <>
                                  <tr className="shadowOnHover">
                                    <td
                                      className="border_right boldTd"
                                      colSpan={2}
                                    >
                                      Average Occupancy
                                    </td>
                                    {forecastPickupData?.map(
                                      (avgOccItem, avgOccIndex) => {
                                        let finalIndex = avgOccIndex - 90;
                                        if (avgOccItem?.day_diff >= -1) {
                                          if (finalIndex <= selectedCol) {
                                            return (
                                              <td className="border_right boldTd text-center">
                                                {avgOccItem?.dba_avg}
                                              </td>
                                            );
                                          }
                                        }
                                      }
                                    )}
                                  </tr>
                                  <tr className="shadowOnHover">
                                    <td
                                      className="border_right boldTd"
                                      colSpan={2}
                                    >
                                      Average Pick Up
                                    </td>
                                    {forecastPickupData?.map(
                                      (avgPickupItem, avgPickupIndex) => {
                                        let finalIndex = avgPickupIndex - 90;
                                        if (avgPickupItem?.day_diff >= -1) {
                                          if (finalIndex <= selectedCol) {
                                            return (
                                              <td className="border_right boldTd text-center">
                                                {avgPickupItem?.pu_avg}
                                              </td>
                                            );
                                          }
                                        }
                                      }
                                    )}
                                  </tr>
                                  <tr className="shadowOnHover">
                                    <td
                                      className="border_right boldTd"
                                      colSpan={2}
                                    ></td>
                                    <td className="border_right boldTd text-center">
                                      PU -1
                                    </td>
                                    {dummyArr.map((pickupItem, pickupIndex) => {
                                      if (pickupIndex <= selectedCol) {
                                        return (
                                          <td className="border_right boldTd text-center">
                                            {`PU ${pickupIndex}`}
                                          </td>
                                        );
                                      }
                                    })}
                                  </tr>
                                </>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <div className="widget-container-1">
            {api !== 0 && (
              <div className="gap-1 widget-container-menu">
                <Button
                  className="heading-menu-btns"
                  type="button"
                  title="Send Mail"
                >
                  <img
                    src={emailIcon}
                    alt="emailIcon"
                    onClick={() => {
                      commonService.fnSendWidgetMail(
                        tblids.tblWeekwiseData,
                        "Forecast using 90 Days Pickup",
                        snapshotId,
                        defaultAsOfDateFromRTK,
                        "",
                        para?.widgetId
                      );
                    }}
                  />
                </Button>
                <Button className="heading-menu-btns" type="button">
                  <img
                    src={infoIcon}
                    alt="info"
                    onClick={() => {
                      widgetDetailRef.current = {
                        widgetdetails: "",
                        usernote: "",
                        compName: compName?.forecastUsing90DayPickup,
                        widgetId: para?.widgetId,
                      };
                      setWidgetModal(true);
                    }}
                  />
                </Button>
                <Button className="heading-menu-btns" type="button">
                  <img
                    src={downloadIcon}
                    alt="menuIcon"
                    import={tblids.tblWeekwiseData}
                    onClick={() =>
                      commonService.fnExportInCSV(
                        tblids.tblWeekwiseData,
                        // "Forecast using 90 Days Pickup"
                        `${
                          para?.propertyCode
                        }-Forecast using 90 Days Pickup-${moment(
                          new Date()
                        ).format("MMDDYYYY")}`
                      )
                    }
                  />
                </Button>
              </div>
            )}
            <div className="ViewPropertySetupTable mt-2">
              <div className="stripped__table">
                <div className="table-responsive tblDynamicPickupSticky">
                  <Table id={tblids.tblWeekwiseData}>
                    <thead>
                      <tr>
                        <th className="border_right">Day</th>
                        <th className="border_right">dba avg</th>
                        {/* <th className="border_right">OTB</th> */}
                        {dummyArr?.map((item, index) => {
                          if (index <= selectedCol) {
                            return (
                              <th
                                className="text-center border_right"
                                key={`dba_${index}`}
                              >
                                {`dba ${index}`}
                              </th>
                            );
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : weekWiseTbl ? (
                        Object.entries(weekWiseTbl)?.map(
                          ([key, value], index) => {
                            let avgCount =
                              weekWiseTblAvgRef.current[key].total &&
                              weekWiseTblAvgRef.current[key].count
                                ? Math.round(
                                    weekWiseTblAvgRef.current[key].total /
                                      weekWiseTblAvgRef.current[key].count
                                  )
                                : 0;
                            return (
                              <tr className="shadowOnHover" key={key}>
                                <td className="border_right">{key}</td>
                                <td className="text-center border_right">
                                  {avgCount}
                                </td>
                                {/* <td className="border_right">
                              {Math.round(value?.otb / value?.count)}
                            </td> */}
                                {dummyArr?.map((item, index) => {
                                  if (index <= selectedCol) {
                                    let avg =
                                      value?.[`dba_${index}`] && value?.count
                                        ? Math.round(
                                            value?.[`dba_${index}`] /
                                              value?.count
                                          )
                                        : 0;
                                    return (
                                      <td
                                        className="text-center border_right"
                                        key={`dba_${index}`}
                                      >
                                        {/* {value[`dba_${index}`]} */}
                                        {avg}
                                      </td>
                                    );
                                  }
                                })}
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Row className="mt-4">
          <Col xl={12}>
            <div className="widget-container-2">
              {api !== 0 && (
                <div className="gap-1 widget-container-menu">
                  <Button
                    className="heading-menu-btns"
                    type="button"
                    title="Send Mail"
                  >
                    <img
                      src={emailIcon}
                      alt="emailIcon"
                      onClick={() => {
                        commonService.fnSendWidgetMail(
                          tblids.tblNextForecastPickup,
                          "Forecast using 90 Days Pickup",
                          snapshotId,
                          defaultAsOfDateFromRTK,
                          "",
                          para?.widgetId
                        );
                      }}
                    />
                  </Button>
                  <Button className="heading-menu-btns" type="button">
                    <img
                      src={infoIcon}
                      alt="info"
                      onClick={() => {
                        widgetDetailRef.current = {
                          widgetdetails: "",
                          usernote: "",
                          compName: compName?.forecastUsing90DayPickup,
                          widgetId: para?.widgetId,
                        };
                        setWidgetModal(true);
                      }}
                    />
                  </Button>
                  <Button className="heading-menu-btns" type="button">
                    <img
                      src={downloadIcon}
                      alt="menuIcon"
                      import={tblids.tblNextForecastPickup}
                      onClick={() =>
                        commonService.fnExportInCSV(
                          tblids.tblNextForecastPickup,
                          // "Forecast using 90 Days Pickup"
                          `${
                            para?.propertyCode
                          }-Forecast using 90 Days Pickup-${moment(
                            new Date()
                          ).format("MMDDYYYY")}`
                        )
                      }
                    />
                  </Button>
                </div>
              )}
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <div className="table-responsive tbl-forcast-dynamic-pickup">
                    <Table id={tblids.tblNextForecastPickup}>
                      <thead className="tbl-header-sticky">
                        <tr>
                          <th className="text-center" colSpan={12}>
                            {`Forecast For Next ${selectedCol} Days`}
                            {/* Forecast For Next 90 Days */}
                          </th>
                        </tr>
                        <tr>
                          <th className="border_right">Date</th>
                          <th className="border_right">Day</th>
                          <th className="border_right">Day Diff</th>
                          <th className="border_right">OTB</th>
                          <th className="border_right">Forecast</th>
                          <th className="border_right">RSA</th>
                          <th className="border_right">Diff(RSA-OTB)</th>
                          <th className="border_right">Ly OTB</th>
                          <th className="border_right">ML Forecast</th>
                          <th className="border_right text-end">
                            ML Price Predication
                          </th>
                          <th className="border_right text-end">
                            Compset Weighted Avg
                          </th>
                          <th className="text-end">Recomended Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr style={{ height: "80px", position: "relative" }}>
                            <Loader />
                          </tr>
                        ) : forecastPickupData ? (
                          forecastPickupData?.map((item, index) => {
                            let finalIndex = index - 90;
                            let isSelectedDateFromFuture = false;
                            let date = moment(selectedDate);
                            let checkWithDate = moment(para?.asOfDate);
                            if (date.isAfter(checkWithDate)) {
                              isSelectedDateFromFuture = true;
                            }
                            if (isSelectedDateFromFuture) {
                              let stayDate = moment(item?.staydate);
                              if (
                                stayDate.isSameOrAfter(checkWithDate) &&
                                item?.day_diff <= 90
                              ) {
                                if (finalIndex <= selectedCol) {
                                  let showBgColor = false;
                                  if (
                                    moment(
                                      new Date(item?.["staydate"]),
                                      "l"
                                    ).format("ddd") === "Fri" ||
                                    moment(
                                      new Date(item?.["staydate"]),
                                      "l"
                                    ).format("ddd") === "Sat"
                                  ) {
                                    showBgColor = true;
                                  }

                                  let rsaOtbDiff =
                                    (item?.dba_avg ? item?.dba_avg : 0) -
                                    (item?.total_booking
                                      ? item?.total_booking
                                      : 0);

                                  let findMLData =
                                    rateIqDataRef.current?.filter(
                                      (x) =>
                                        commonService?.getDateInFormat(
                                          x?.Dates
                                        ) ===
                                        commonService?.getDateInFormat(
                                          item?.["staydate"]
                                        )
                                    );

                                  let checkEvent = eventListRef.current?.filter(
                                    (x) => {
                                      let checkingDate = moment(
                                        item?.["staydate"]
                                      );
                                      let startingDate = moment(x?.startDate);
                                      let endingDate = moment(x?.endDate);
                                      if (
                                        checkingDate.isSameOrAfter(
                                          startingDate
                                        ) &&
                                        checkingDate.isSameOrBefore(endingDate)
                                      ) {
                                        return x;
                                      }
                                    }
                                  );

                                  return (
                                    <React.Fragment
                                      key={`forecast_data${index}`}
                                    >
                                      <tr
                                        className={`${
                                          showBgColor ? "backgroundTr" : ""
                                        } shadowOnHover`}
                                      >
                                        <td className="border_right">
                                          <div className="d-flex gap-2">
                                            {commonService.getDateInFormat(
                                              new Date(item?.["staydate"])
                                            )}
                                            <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                              {checkEvent?.length > 0 && (
                                                <img
                                                  style={{
                                                    height: "11px",
                                                    width: "11px",
                                                  }}
                                                  className="iconcolor"
                                                  src={ticket}
                                                  alt="ticket"
                                                  onClick={() => {
                                                    selectedEventDateRef.current =
                                                      item;
                                                    eventListPropsRef.current =
                                                      checkEvent;
                                                    setShowTicketModal(true);
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border_right">
                                          {item?.Weekday}
                                        </td>
                                        <td className="border_right">
                                          {item?.day_diff}
                                        </td>
                                        <td className="border_right">
                                          {item?.total_booking}
                                        </td>
                                        <td className="border_right">
                                          {item?.forcastroom}
                                        </td>
                                        <td className="border_right">
                                          {item?.dba_avg}
                                        </td>
                                        <td className="border_right">
                                          {rsaOtbDiff}
                                        </td>
                                        <td className="border_right">
                                          {item?.ly_booking}
                                        </td>
                                        <td className="border_right">
                                          {findMLData?.[0]?.ml_room
                                            ? findMLData?.[0]?.ml_room
                                            : 0}
                                        </td>
                                        <td className="border_right text-end">
                                          {findMLData?.[0]?.ml_rate
                                            ? commonService.formateAmount(
                                                findMLData?.[0]?.ml_rate
                                              )
                                            : commonService.formateAmount(0)}
                                        </td>
                                        <td className="border_right text-end">
                                          {findMLData?.[0]?.[
                                            "COMP. Weighted Average"
                                          ]
                                            ? commonService.formateAmount(
                                                findMLData?.[0]?.[
                                                  "COMP. Weighted Average"
                                                ]
                                              )
                                            : commonService.formateAmount(0)}
                                        </td>
                                        <td className="text-end">
                                          {findMLData?.[0]?.["Recomened Price"]
                                            ? commonService.formateAmount(
                                                findMLData?.[0]?.[
                                                  "Recomened Price"
                                                ]
                                              )
                                            : commonService.formateAmount(0)}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              }
                            } else {
                              if (
                                item?.day_diff >= -1 &&
                                item?.day_diff <= 90
                              ) {
                                if (finalIndex <= selectedCol) {
                                  let showBgColor = false;
                                  if (
                                    moment(
                                      new Date(item?.["staydate"]),
                                      "l"
                                    ).format("ddd") === "Fri" ||
                                    moment(
                                      new Date(item?.["staydate"]),
                                      "l"
                                    ).format("ddd") === "Sat"
                                  ) {
                                    showBgColor = true;
                                  }

                                  let rsaOtbDiff =
                                    (item?.dba_avg ? item?.dba_avg : 0) -
                                    (item?.total_booking
                                      ? item?.total_booking
                                      : 0);

                                  let findMLData =
                                    rateIqDataRef.current?.filter(
                                      (x) =>
                                        commonService?.getDateInFormat(
                                          x?.Dates
                                        ) ===
                                        commonService?.getDateInFormat(
                                          item?.["staydate"]
                                        )
                                    );

                                  return (
                                    <React.Fragment
                                      key={`forecast_data${index}`}
                                    >
                                      <tr
                                        className={`${
                                          showBgColor ? "backgroundTr" : ""
                                        } shadowOnHover`}
                                      >
                                        <td className="border_right">
                                          {/* {commonService.getDateInFormat(
                                            new Date(item?.["staydate"])
                                          )} */}
                                          {moment(item?.["staydate"]).format(
                                            "L"
                                          )}
                                        </td>
                                        <td className="border_right">
                                          {item?.Weekday}
                                        </td>
                                        <td className="border_right">
                                          {item?.day_diff}
                                        </td>
                                        <td className="border_right">
                                          {item?.total_booking}
                                        </td>
                                        <td className="border_right">
                                          {item?.forcastroom}
                                        </td>
                                        <td className="border_right">
                                          {item?.dba_avg}
                                        </td>
                                        <td className="border_right">
                                          {rsaOtbDiff}
                                        </td>
                                        <td className="border_right">
                                          {item?.ly_booking}
                                        </td>
                                        <td className="border_right">
                                          {findMLData?.[0]?.ml_room
                                            ? findMLData?.[0]?.ml_room
                                            : 0}
                                        </td>
                                        <td className="border_right text-end">
                                          {findMLData?.[0]?.ml_rate
                                            ? commonService.formateAmount(
                                                findMLData?.[0]?.ml_rate
                                              )
                                            : commonService.formateAmount(0)}
                                        </td>
                                        <td className="border_right text-end">
                                          {findMLData?.[0]?.[
                                            "COMP. Weighted Average"
                                          ]
                                            ? commonService.formateAmount(
                                                findMLData?.[0]?.[
                                                  "COMP. Weighted Average"
                                                ]
                                              )
                                            : commonService.formateAmount(0)}
                                        </td>
                                        <td className="text-end">
                                          {findMLData?.[0]?.["Recomened Price"]
                                            ? commonService.formateAmount(
                                                findMLData?.[0]?.[
                                                  "Recomened Price"
                                                ]
                                              )
                                            : commonService.formateAmount(0)}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              }
                            }
                          })
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventList}
        />
      )}
      {api !== 0 && (
        <Comments
          widgetDetails={para}
          editorId={"ForecastUsingNinetyDayPickupNew"}
        />
      )}
    </>
  );
};

export default ForecastUsingNinetyDayPickupNew;
