import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import Loader from "../../../Loader/Loader";
import { commonService } from "../../../../utils/commonService";
import { Button, Form, Table } from "react-bootstrap";
import { compName, month } from "../../../../utils/constant";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import emailIcon from "../../../../Assets/Images/Email.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import moment from "moment";
import { apiResponse } from "../../../../utils/apiResponse";
import WidgetDetailModal from "../../WidgetDetailModal";
import Comments from "../Comments/Comment";

const MonthlyBookingWindow = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [loading, setLoading] = useState(false);
  const [bookingPaceData, setBookingPaceData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  let totalRms = 0,
    totalAdr = 0,
    totalRev = 0,
    zeroDayRms = 0,
    zeroDayAdr = 0,
    zeroDayRev = 0,
    zeroDayAdrCount = 0,
    oneDayRms = 0,
    oneDayAdr = 0,
    oneDayRev = 0,
    oneDayAdrCount = 0,
    two_7DayRms = 0,
    two_7DayAdr = 0,
    two_7DayRev = 0,
    two_7DayAdrCount = 0,
    eight_15DayRms = 0,
    eight_15DayAdr = 0,
    eight_15DayRev = 0,
    eight_15DayAdrCount = 0,
    sixteen_30DayRms = 0,
    sixteen_30DayAdr = 0,
    sixteen_30DayRev = 0,
    sixteen_30DayAdrCount = 0,
    thirtyone_60DayRms = 0,
    thirtyone_60DayAdr = 0,
    thirtyone_60DayRev = 0,
    thirtyone_60DayAdrCount = 0,
    sixtyone_90DayRms = 0,
    sixtyone_90DayAdr = 0,
    sixtyone_90DayRev = 0,
    sixtyone_90DayAdrCount = 0,
    ninentyPlusDayRms = 0,
    ninentyPlusDayAdr = 0,
    ninentyPlusDayRev = 0,
    ninentyPlusDayAdrCount = 0;

  const getMonthlyBookingPaceData = async () => {
    setLoading(true);
    let bookingPaceDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.BOOKING_WINDOW.MONTHLY_BOOKING_PACE,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
          year: selectedYear,
        },
      },
      false
    );

    let response = apiResponse(false, bookingPaceDataResponse);

    if (response?.isValidate) {
      setBookingPaceData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setBookingPaceData(null);
      setLoading(null);
    }
  };

  useEffect(() => {
    if (api !== 0) {
      if (para?.asOfDate) {
        getMonthlyBookingPaceData();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  const [tblids, settblids] = useState({
    monthlyBookingWindow: commonService.GenerateGUID(),
  });

  useEffect(() => {
    const findTable = document.getElementById(`${tblids.monthlyBookingWindow}`);
    let rmsArr = {},
      adrArr = {},
      revArr = {},
      index = 0,
      colorApplyIndex = 0;

    if (findTable && bookingPaceData) {
      const findTblBody = findTable?.querySelectorAll("tbody tr");
      findTblBody?.forEach((tblBodyItem, tblBodyIndex) => {
        if (tblBodyIndex !== 12) {
          let allTd = tblBodyItem.querySelectorAll("td");

          allTd?.forEach((tdItem, tdIndex) => {
            if (tdIndex >= 4) {
              if (index === 0) {
                let value = tdItem?.innerText;
                let finalValue = value?.replace(",", "");
                if (rmsArr[tblBodyIndex]) {
                  rmsArr[tblBodyIndex] = [
                    ...rmsArr[tblBodyIndex],
                    parseInt(finalValue),
                  ];
                } else {
                  rmsArr[tblBodyIndex] = [parseInt(finalValue)];
                }
              } else if (index === 1) {
                let value = tdItem?.innerText;
                let withoutSymbolValue = value?.replace("$", "");
                let finalValue = withoutSymbolValue?.replace(",", "");
                if (adrArr[tblBodyIndex]) {
                  adrArr[tblBodyIndex] = [
                    ...adrArr[tblBodyIndex],
                    parseInt(finalValue),
                  ];
                } else {
                  adrArr[tblBodyIndex] = [parseInt(finalValue)];
                }
              } else {
                let value = tdItem?.innerText;
                let withoutSymbolValue = value?.replace("$", "");
                let finalValue = withoutSymbolValue?.replace(",", "");
                if (revArr[tblBodyIndex]) {
                  revArr[tblBodyIndex] = [
                    ...revArr[tblBodyIndex],
                    parseInt(finalValue),
                  ];
                } else {
                  revArr[tblBodyIndex] = [parseInt(finalValue)];
                }
              }
              index = index + 1;
              if (index > 2) {
                index = 0;
              }
            }
          });
        }
      });

      findTblBody?.forEach((tblBodyItem, tblBodyIndex) => {
        if (tblBodyIndex !== 12) {
          let rmsArrValue = [...rmsArr[tblBodyIndex]];
          let adrArrValue = [...adrArr[tblBodyIndex]];
          let revArrValue = [...revArr[tblBodyIndex]];

          rmsArrValue.sort(function (a, b) {
            return a - b;
          });
          adrArrValue.sort(function (a, b) {
            return a - b;
          });
          revArrValue.sort(function (a, b) {
            return a - b;
          });
          let allTd = tblBodyItem.querySelectorAll("td");

          allTd?.forEach((tdItem, tdIndex) => {
            if (tdIndex >= 4) {
              if (colorApplyIndex === 0) {
                let value = tdItem?.innerText;
                let finalValue = value?.replace(",", "");
                let findIndex = rmsArrValue?.findIndex(
                  (x) => x == parseInt(finalValue)
                );
                let finalValueForRgba = `0.${findIndex}`;
                let total = 0.3 + parseFloat(finalValueForRgba);
                tdItem.style.backgroundColor = `rgba(132, 210, 246, ${total})`;
              } else if (colorApplyIndex === 1) {
                let value = tdItem?.innerText;
                let withoutSymbolValue = value?.replace("$", "");
                let finalValue = withoutSymbolValue?.replace(",", "");
                let findIndex = adrArrValue?.findIndex(
                  (x) => x == parseInt(finalValue)
                );
                let finalValueForRgba = `0.${findIndex}`;
                let total = 0.3 + parseFloat(finalValueForRgba);
                tdItem.style.backgroundColor = `rgba(255, 136, 0, ${total})`;
              } else {
                let value = tdItem?.innerText;
                let withoutSymbolValue = value?.replace("$", "");
                let finalValue = withoutSymbolValue?.replace(",", "");
                let findIndex = revArrValue?.findIndex(
                  (x) => x == parseInt(finalValue)
                );
                let finalValueForRgba = `0.${findIndex}`;
                let total = 0.3 + parseFloat(finalValueForRgba);
                tdItem.style.backgroundColor = `rgba(117, 182, 118, ${total})`;
              }
              colorApplyIndex = colorApplyIndex + 1;
              if (colorApplyIndex > 2) {
                colorApplyIndex = 0;
              }
            }
          });
        }
      });
    }
  }, [bookingPaceData]);

  const calculateTotal = (findMonthData) => {
    totalRms =
      totalRms +
      (findMonthData?.[0]?.Total_RMS
        ? parseInt(findMonthData?.[0]?.Total_RMS)
        : 0);
    totalRev =
      totalRev +
      (findMonthData?.[0]?.Total_REV
        ? parseInt(findMonthData?.[0]?.Total_REV)
        : 0);

    zeroDayRms =
      zeroDayRms +
      (findMonthData?.[0]?.BW0_RMS ? parseInt(findMonthData?.[0]?.BW0_RMS) : 0);
    zeroDayRev =
      zeroDayRev +
      (findMonthData?.[0]?.BW0_REV ? parseInt(findMonthData?.[0]?.BW0_REV) : 0);

    oneDayRms =
      oneDayRms +
      (findMonthData?.[0]?.BW1_RMS ? parseInt(findMonthData?.[0]?.BW1_RMS) : 0);
    oneDayRev =
      oneDayRev +
      (findMonthData?.[0]?.BW1_REV ? parseInt(findMonthData?.[0]?.BW1_REV) : 0);

    two_7DayRms =
      two_7DayRms +
      (findMonthData?.[0]?.BW2TO7_RMS
        ? parseInt(findMonthData?.[0]?.BW2TO7_RMS)
        : 0);
    two_7DayRev =
      two_7DayRev +
      (findMonthData?.[0]?.BW2TO7_REV
        ? parseInt(findMonthData?.[0]?.BW2TO7_REV)
        : 0);

    eight_15DayRms =
      eight_15DayRms +
      (findMonthData?.[0]?.BW8TO15_RMS
        ? parseInt(findMonthData?.[0]?.BW8TO15_RMS)
        : 0);
    eight_15DayRev =
      eight_15DayRev +
      (findMonthData?.[0]?.BW8TO15_REV
        ? parseInt(findMonthData?.[0]?.BW8TO15_REV)
        : 0);

    sixteen_30DayRms =
      sixteen_30DayRms +
      (findMonthData?.[0]?.BW16TO30_RMS
        ? parseInt(findMonthData?.[0]?.BW16TO30_RMS)
        : 0);
    sixteen_30DayRev =
      sixteen_30DayRev +
      (findMonthData?.[0]?.BW16TO30_REV
        ? parseInt(findMonthData?.[0]?.BW16TO30_REV)
        : 0);

    thirtyone_60DayRms =
      thirtyone_60DayRms +
      (findMonthData?.[0]?.BW31TO60_RMS
        ? parseInt(findMonthData?.[0]?.BW31TO60_RMS)
        : 0);
    thirtyone_60DayRev =
      thirtyone_60DayRev +
      (findMonthData?.[0]?.BW31TO60_REV
        ? parseInt(findMonthData?.[0]?.BW31TO60_REV)
        : 0);

    sixtyone_90DayRms =
      sixtyone_90DayRms +
      (findMonthData?.[0]?.BW61TO90_RMS
        ? parseInt(findMonthData?.[0]?.BW61TO90_RMS)
        : 0);
    sixtyone_90DayRev =
      sixtyone_90DayRev +
      (findMonthData?.[0]?.BW61TO90_REV
        ? parseInt(findMonthData?.[0]?.BW61TO90_REV)
        : 0);

    ninentyPlusDayRms =
      ninentyPlusDayRms +
      (findMonthData?.[0]?.BW91TOUP_RMS
        ? parseInt(findMonthData?.[0]?.BW91TOUP_RMS)
        : 0);
    ninentyPlusDayRev =
      ninentyPlusDayRev +
      (findMonthData?.[0]?.BW91TOUP_REV
        ? parseInt(findMonthData?.[0]?.BW91TOUP_REV)
        : 0);
  };

  const calculateValue = (monthData, value) => {
    let max = 0,
      keyName = `BW0_${value}`;
    if (
      (monthData?.[0]?.[`BW0_${value}`]
        ? parseInt(monthData?.[0]?.[`BW0_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW0_${value}`]);
      keyName = `BW0_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW1_${value}`]
        ? parseInt(monthData?.[0]?.[`BW1_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW1_${value}`]);
      keyName = `BW1_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW2TO7_${value}`]
        ? parseInt(monthData?.[0]?.[`BW2TO7_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW2TO7_${value}`]);
      keyName = `BW2TO7_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW8TO15_${value}`]
        ? parseInt(monthData?.[0]?.[`BW8TO15_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW8TO15_${value}`]);
      keyName = `BW8TO15_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW16TO30_${value}`]
        ? parseInt(monthData?.[0]?.[`BW16TO30_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW16TO30_${value}`]);
      keyName = `BW16TO30_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW31TO60_${value}`]
        ? parseInt(monthData?.[0]?.[`BW31TO60_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW31TO60_${value}`]);
      keyName = `BW31TO60_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW61TO90_${value}`]
        ? parseInt(monthData?.[0]?.[`BW61TO90_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW61TO90_${value}`]);
      keyName = `BW61TO90_${value}`;
    }
    if (
      (monthData?.[0]?.[`BW91TOUP_${value}`]
        ? parseInt(monthData?.[0]?.[`BW91TOUP_${value}`])
        : 0) > max
    ) {
      max = parseInt(monthData?.[0]?.[`BW91TOUP_${value}`]);
      keyName = `BW91TOUP_${value}`;
    }
    return keyName;
  };

  const calculateHighestValue = (monthData) => {
    let obj = {
      rms: "",
      adr: "",
      rev: "",
    };
    obj.rms = calculateValue(monthData, "RMS");
    obj.adr = calculateValue(monthData, "ADR");
    obj.rev = calculateValue(monthData, "REV");
    return obj;
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.monthlyBookingWindow,
                  "Monthly Booking Window",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.monthlyBookingWindow,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.monthlyBookingWindow}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.monthlyBookingWindow,
                  `${para?.propertyCode}-Monthly Booking Window-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.monthlyBookingWindow} responsive>
            <thead>
              <tr>
                <th className="text-center" colSpan={28}>
                  Monthly Booking Window
                </th>
              </tr>
              <tr>
                <th className="border_right width75">
                  <div>
                    <Form.Select
                      value={selectedYear}
                      onChange={(e) => {
                        setSelectedYear(e.target.value);
                      }}
                    >
                      {yearsArr?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <span style={{ display: "none" }}>{selectedYear}</span>
                  </div>
                </th>
                <th colSpan={3} className="text-center border_right">
                  Total
                </th>
                <th colSpan={3} className="text-center border_right">
                  0 Day
                </th>
                <th colSpan={3} className="text-center border_right">
                  1 Day
                </th>
                <th colSpan={3} className="text-center border_right">
                  2 to 7 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  8 to 15 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  16 to 30 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  31 to 60 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  61 to 90 Days
                </th>
                <th colSpan={3} className="text-center">
                  90+ Days
                </th>
              </tr>
              <tr>
                <th className="border_right">Month</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">REV</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end">REV</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                month?.map((item, index) => {
                  let findMonthData = bookingPaceData?.filter(
                    (x) =>
                      x?.MonthName?.trim()?.toLowerCase() ===
                      item?.toLowerCase()
                  );

                  calculateTotal(findMonthData);

                  let highestValueOfMonth = "";
                  if (findMonthData?.length > 0) {
                    highestValueOfMonth = calculateHighestValue(findMonthData);
                  }

                  return (
                    <React.Fragment key={index}>
                      <tr className="bookingWindowHover">
                        <td className="border_right">{item}</td>
                        <td className="text-center">
                          {findMonthData?.[0]?.Total_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.Total_RMS
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.Total_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.Total_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.Total_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.Total_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW0_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW0_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW0_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW0_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW0_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW0_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end border_right ${
                            highestValueOfMonth?.rev === "BW0_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW0_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW0_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW1_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW1_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW1_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW1_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW1_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW1_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end border_right ${
                            highestValueOfMonth?.rev === "BW1_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW1_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW1_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW2TO7_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW2TO7_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW2TO7_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW2TO7_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW2TO7_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW2TO7_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end border_right ${
                            highestValueOfMonth?.rev === "BW2TO7_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW2TO7_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW2TO7_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW8TO15_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW8TO15_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW8TO15_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW8TO15_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW8TO15_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW8TO15_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end border_right ${
                            highestValueOfMonth?.rev === "BW8TO15_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW8TO15_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW8TO15_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW16TO30_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW16TO30_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW16TO30_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW16TO30_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW16TO30_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW16TO30_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end border_right ${
                            highestValueOfMonth?.rev === "BW16TO30_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW16TO30_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW16TO30_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW31TO60_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW31TO60_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW31TO60_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW31TO60_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW31TO60_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW31TO60_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end border_right ${
                            highestValueOfMonth?.rev === "BW31TO60_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW31TO60_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW31TO60_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW61TO90_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW61TO90_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW61TO90_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW61TO90_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW61TO90_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW61TO90_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end border_right ${
                            highestValueOfMonth?.rev === "BW61TO90_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW61TO90_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW61TO90_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-center ${
                            highestValueOfMonth?.rms === "BW91TOUP_RMS"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW91TOUP_RMS
                            ? commonService.formateRoom(
                                findMonthData?.[0]?.BW91TOUP_RMS
                              )
                            : 0}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.adr === "BW91TOUP_ADR"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW91TOUP_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW91TOUP_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className={`text-end ${
                            highestValueOfMonth?.rev === "BW91TOUP_REV"
                              ? "tdBorder"
                              : ""
                          }`}
                        >
                          {findMonthData?.[0]?.BW91TOUP_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.BW91TOUP_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                      {index === 11 && (
                        <tr>
                          <td className="boldTd border_right">Total</td>
                          <td className="boldTd text-center">
                            {commonService.formateRoom(totalRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(totalRev / totalRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(totalRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(zeroDayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(zeroDayRev / zeroDayRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(zeroDayRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(oneDayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(oneDayRev / oneDayRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(oneDayRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(two_7DayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(two_7DayRev / two_7DayRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(two_7DayRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(eight_15DayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(eight_15DayRev / eight_15DayRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(eight_15DayRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(sixteen_30DayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(sixteen_30DayRev / sixteen_30DayRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(sixteen_30DayRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(thirtyone_60DayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(
                                thirtyone_60DayRev / thirtyone_60DayRms
                              )
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(thirtyone_60DayRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(sixtyone_90DayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(sixtyone_90DayRev / sixtyone_90DayRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(sixtyone_90DayRev)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(ninentyPlusDayRms)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(
                              Math.round(ninentyPlusDayRev / ninentyPlusDayRms)
                            )}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(ninentyPlusDayRev)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"MonthlyBookingWindow"} />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default MonthlyBookingWindow;
